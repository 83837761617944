import cn from 'classnames'

type Props = {
  className?: string
  children?: any
}

export function Card({ children, className }: Props) {
  return (
    <div className={cn(`mx-auto rounded bg-white md:shadow-lg`, className)}>
      <div className="w-full md:block">
        <div className="pt-4 md:p-4">
          <div className="mt-2 text-gray-500">{children}</div>
        </div>
      </div>
    </div>
  )
}
