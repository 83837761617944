import { useEffect, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router'
import FooterNav from './FooterNav'
import Step1 from './steps/Step1'
import Step2 from './steps/Step2'
import Step3 from './steps/Step3'
import Step4 from './steps/Step4'
import Step5 from './steps/Step5'
import Step6 from './steps/Step6'
import Step7 from './steps/Step7'

import WizardSteps from './WizardSteps'

const steps = [
  'Org. nummer​',
  'Organisasjon​',
  'Produkt',
  'Faktura',
  'Anlegg​',
  'Sammendrag',
  'Signering',
]

interface EnrollProps {}

interface EnrollState {
  activeTab: string
}

const Enroll = (props: EnrollProps) => {
  const step = useParams<'step'>().step as string

  const initialState = {
    activeTab: step,
  }

  const navigate = useNavigate()
  const location = useLocation()

  const [state, setState] = useState<EnrollState>(initialState)

  useEffect(() => {
    const path = `/App/Enroll/${state.activeTab}`
    navigate(path)
  }, [state.activeTab, navigate])

  useEffect(() => {
    const path = `/App/Enroll/${location.pathname.slice(-1)}`
    setState((prevState) => ({
      ...prevState,
      activeTab: location.pathname.slice(-1),
    }))
    navigate(path)
  }, [location.pathname, navigate])

  const renderStep = () => {
    switch (state.activeTab) {
      case '1':
        return (
          <Step1
            currentStep={state.activeTab}
            nextStep={(next: any) => nextStep(next)}
          >
            <FooterNav
              currentStep={state.activeTab}
              prevStep={(prev: any) => prevStep(prev)}
            />
          </Step1>
        )
      case '2':
        return (
          <Step2
            currentStep={state.activeTab}
            nextStep={(next: any) => nextStep(next)}
          >
            <FooterNav
              currentStep={state.activeTab}
              prevStep={(prev: any) => prevStep(prev)}
            />
          </Step2>
        )
      case '3':
        return (
          <Step3
            currentStep={state.activeTab}
            nextStep={(next: any) => nextStep(next)}
          >
            <FooterNav
              currentStep={state.activeTab}
              prevStep={(prev: any) => prevStep(prev)}
            />
          </Step3>
        )
      case '4':
        return (
          <Step4
            currentStep={state.activeTab}
            nextStep={(next: any) => nextStep(next)}
          >
            <FooterNav
              currentStep={state.activeTab}
              prevStep={(prev: any) => prevStep(prev)}
            />
          </Step4>
        )
      case '5':
        return (
          <Step5
            currentStep={state.activeTab}
            nextStep={(next: any) => nextStep(next)}
          >
            <FooterNav
              currentStep={state.activeTab}
              prevStep={(prev: any) => prevStep(prev)}
            />
          </Step5>
        )
      case '6':
        return (
          <Step6
            currentStep={state.activeTab}
            nextStep={(next: any) => nextStep(next)}
          >
            <FooterNav
              currentStep={state.activeTab}
              prevStep={(prev: any) => prevStep(prev)}
            />
          </Step6>
        )
      case '7':
        return (
          <Step7
            currentStep={state.activeTab}
            prevStep={(step) => prevStep(step)}
          />
        )
      // default:
      //     return (
      //         <Step1
      //             currentStep="1"
      //             nextStep={(next: any) => nextStep(next)}
      //         >
      //             <FooterNav
      //                 currentStep="1"
      //                 prevStep={(prev: any) => prevStep(prev)}
      //             />
      //         </Step1>
      //     );
    }
  }

  const selectedStep = (currentStep: any) => {
    setState((prevState) => ({
      ...prevState,
      activeTab: currentStep.toString(),
    }))
  }

  const prevStep = (prev: any) => {
    setState((prevState) => ({
      ...prevState,
      activeTab: prev.toString(),
    }))
  }

  const nextStep = (next: any) => {
    setState((prevState) => ({
      ...prevState,
      activeTab: next.toString(),
    }))
  }

  return (
    <>
      <div className="container text-center">
        <WizardSteps
          steps={steps}
          selectedStep={(currentStep: any) => selectedStep(currentStep)}
          activeStep={state.activeTab}
        />
        {renderStep()}
      </div>
    </>
  )
}

export default Enroll
