import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Invoice } from './types'
import Form from './Form'
import NettToggleComponent from '../NettToggleComponent'
import { CloseButton } from 'src/components/action'

const InvoiceModal = ({
  title,
  isOpen,
  onClose,
  invoice,
}: {
  title: string
  isOpen: boolean
  onClose: () => void
  invoice: Invoice
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onClose}
        open={isOpen}
      >
        <div className="min-h-screen px-4 text-center">
          {/* <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          > */}
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          {/* </Transition.Child> */}

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="my-8 inline-block w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <div className="w-full py-4">
                <div className="flex items-center justify-between">
                  <div>
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                  </div>
                  <div className="flex items-center space-x-4">
                    <NettToggleComponent />
                    <CloseButton onClick={onClose} />
                  </div>
                </div>

                <Form invoice={invoice} handleClose={onClose} />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default InvoiceModal
