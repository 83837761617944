import home from './home.json'
import global from './global.json'
import objects from './objects.json'
import consumption from './consumption.json'
import grid from './grid.json'
import deviations from './deviations.json'
import powerFactor from './powerFactor.json'
import et from './et.json'

const no = {
  consumption,
  global,
  home,
  objects,
  grid,
  deviations,
  powerFactor,
  et,
}

export default no
