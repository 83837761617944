/**
 * Calculate the average grid
 */

import { Invoice, NettGross } from '../types'
import { formatNumberInput } from '../utils'

const calculateAverageGridNett = (invoice: Invoice) => {
  if (!invoice.quantity || !invoice.gridInvoice) return 0

  return formatNumberInput(invoice.gridInvoice) / invoice.quantity
}

const calculateAverageGridGross = (invoice: Invoice) => {
  if (!invoice.quantity || !invoice.gridInvoiceGross) return 0

  return formatNumberInput(invoice.gridInvoiceGross) / invoice.quantity
}

export const averageGrid = (invoice: Invoice): NettGross => ({
  nett: calculateAverageGridNett(invoice),
  gross: calculateAverageGridGross(invoice),
})
