import { useMemo } from 'react'
import { Card } from 'src/components/Card'
import { Table } from 'src/components/Table'

interface Step3Props {
  currentStep: string
  children: any
  nextStep: (next: any) => void
}

// interface Step3State {}

const Step3 = (props: Step3Props) => {
  // const initialState = {}

  // const [state, setState] = useState<Step3State>(initialState)

  const columns = useMemo(
    () => [
      {
        Header: 'Product ID',
        accessor: 'ProductID',
        sortType: 'basic',
      },
      {
        Header: 'Product Name',
        accessor: 'ProductName',
        sortType: 'basic',
      },
      {
        Header: 'Description',
        accessor: 'Description',
        sortType: 'basic',
      },
      {
        Header: 'Product Image Url',
        accessor: 'ProductImageUrl',
        sortType: 'basic',
      },
    ],
    []
  )

  const submitFormData = () => {
    props.nextStep(parseInt(props.currentStep) + 1)
  }

  return (
    <>
      <div className="">
        <div className="m-4">
          <h1 className="text-3xl font-semibold">Produkt</h1>
        </div>
        <Card>
          <form onSubmit={() => submitFormData()}>
            <fieldset className="form-group">
              <div className="form-group row">
                <Table
                  data={[]}
                  columns={columns}
                  onRowClick={() => {
                    return
                  }}
                />
              </div>
            </fieldset>

            <fieldset className="form-group m-4">
              <legend className="col-form-label">
                Velg hvor langt tilbake du vil at strømradar skal hente data fra
              </legend>
              <div className="flex w-full justify-center">
                <div className="form-group flex w-2/5 justify-around">
                  <div className="">
                    <input type="radio" name="RetrieveDataFrom" required />
                    <label className="ml-2 text-sm" htmlFor="exampleRadios1">
                      Inneværende måned
                    </label>
                  </div>
                  <div className="">
                    <input type="radio" name="RetrieveDataFrom" required />
                    <label className="ml-2 text-sm" htmlFor="exampleRadios1">
                      Fjoråret
                    </label>
                  </div>
                  <div className="">
                    <input type="radio" name="RetrieveDataFrom" required />
                    <label className="ml-2 text-sm" htmlFor="exampleRadios1">
                      Inneværende år
                    </label>
                  </div>
                  <div className=" form-inline">
                    <input type="radio" name="RetrieveDataFrom" required />
                    <label className="ml-2 text-sm" htmlFor="exampleRadios1">
                      Annet
                    </label>
                  </div>
                </div>
              </div>
              {props.children}
            </fieldset>
          </form>
        </Card>
      </div>
    </>
  )
}

export default Step3
