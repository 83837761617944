import L from 'leaflet'
import { PropsWithChildren, useEffect } from 'react'
import { useMap } from 'react-leaflet'
import { useTranslation } from 'react-i18next'
import { renderToStaticMarkup } from 'react-dom/server'
import { LocationMarkerIcon } from '@heroicons/react/outline'
import {
  MeteringPointDetails,
  MeteringPointWeatherStation,
} from 'src/generated/client'

type LegendProps = {
  weatherStations: MeteringPointWeatherStation[]
  meter: MeteringPointDetails
}

const Legend = ({ weatherStations, meter }: LegendProps) => {
  const map = useMap()

  useEffect(() => {
    const legend = new L.Control({ position: 'topright' })

    legend.onAdd = () => {
      const div = L.DomUtil.create('div', 'legend')

      div.innerHTML = renderToStaticMarkup(
        <CardList weatherStations={weatherStations} />
      )

      return div
    }

    legend.addTo(map)

    return () => {
      legend.remove()
    }
  }, [map, meter, weatherStations])
  return null
}

export default Legend

type WeatherStationCardProps = {
  station: MeteringPointWeatherStation
}

const WeatherStationCard = ({ station }: WeatherStationCardProps) => {
  const { t } = useTranslation()
  return (
    <div className="flex w-full flex-col pt-2">
      <h3 className="whitespace-nowrap pt-1 text-lg font-semibold text-green-900">
        {station.stationName}
      </h3>

      <div className="grid grid-cols-2 gap-x-10 gap-y-4 pt-2">
        <CardDataItem label={t(`objects:weatherStationsView.distance`)}>
          {String(station.distance)}
        </CardDataItem>
        <CardDataItem label={t(`objects:weatherStationsView.station`)}>
          {String(station.stationId)}
        </CardDataItem>
      </div>
    </div>
  )
}

const CardDataItem = ({
  label,
  children,
}: {
  label: string
  children: string
}) => (
  <div className="flex flex-col">
    <span className="text-xs uppercase tracking-wide text-gray-600">
      {label}
    </span>
    <span className="text-base font-bold text-gray-900">{children}</span>
  </div>
)

type CardListProps = {
  weatherStations: MeteringPointWeatherStation[]
}

const CardList = ({ weatherStations }: CardListProps) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col overflow-hidden rounded-lg bg-white pt-4 pb-6 shadow-lg">
      {/* <CardListHeading Icon={LightBulbIcon}>
        {t(`objects:weatherStationsView.meter`)}
      </CardListHeading>
      <MeterCard meter={meter} />
      <div className="w-full mt-2 mb-3 border-t" /> */}
      <CardListHeading Icon={LocationMarkerIcon}>
        {t(`objects:weatherStationsView.weatherStations`)}
      </CardListHeading>
      <div className="flex flex-col space-y-4 divide-y px-5">
        {weatherStations.map(
          (station: MeteringPointWeatherStation, i: number) => (
            <WeatherStationCard station={station} key={i} />
          )
        )}
      </div>
    </div>
  )
}

const CardListHeading = ({
  Icon,
  children,
}: PropsWithChildren<{
  Icon: (props: React.ComponentProps<'svg'>) => JSX.Element
}>) => (
  <div className="flex items-center space-x-1 px-5">
    <Icon className="h-4 w-auto" />
    <span className="text-sm font-medium">{children}</span>
  </div>
)
