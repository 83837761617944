import React from 'react'
import ReactDOM from 'react-dom'
import { RouterProvider } from 'react-router-dom'

import reportWebVitals from './reportWebVitals'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import './index.css'
import en from './locales/en'
import no from './locales/no'

import { AppStateContextProvider } from './context/appstate'
import { FullScreenLoading } from './components/Loading'
import { router } from './Router'

import { Toaster } from 'react-hot-toast'

let lang = localStorage.getItem('lang')

const AppVersion = '1.1.2'
console.log('AppVersion', AppVersion)

if (!lang) {
  lang = 'no'
  localStorage.setItem('lang', lang)
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: en,
      no: no,
    },
    lng: lang,
    fallbackLng: 'no',

    interpolation: {
      escapeValue: false,
    },
  })

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <Toaster />
    <QueryClientProvider client={queryClient}>
      <AppStateContextProvider>
        <RouterProvider
          router={router}
          fallbackElement={<FullScreenLoading />}
        />
      </AppStateContextProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
