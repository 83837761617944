import { useState, type PropsWithChildren } from 'react'
import {
  CheckIcon,
  PencilIcon,
  XIcon,
  RefreshIcon,
} from '@heroicons/react/solid'

import { QuestionMarkCircleIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
import Section from 'src/components/DetailComponents/Section'
import { useMeter } from 'src/context/meter'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import APIClient from 'src/utils/apiClient'
import Spinner from 'src/components/Spinner'
import { Loading } from 'src/components/Loading'
import {
  MeteringPointDetails,
  MeteringPointOverview,
  MeteringPointCustomer,
  MeteringPointCustomerAddress,
  MeteringPointCustomerCommunication,
} from 'src/generated/client'
import HelpModal from 'src/components/HelpModal/HelpModal'
import { ActionButton } from 'src/components/action'

const tagKeys = ['objectId', 'meteringPointId', 'ean'] as const

const meteringKeys = [
  'meteringPointId',
  'meteringPointType',
  // 'accessType', // Access type is rendered independently on <HeaderSection />
  'priority',
  'capacityValue',
  'estimatedConsumption',
  'meterIdentification',
  'numberOfDigits',
  'constant',
  'meterLocation',
] as const

const propertyKeys = [
  'streetName',
  'buildingNumber',
  'postCode',
  'cityName',
  'description',
] as const

const detailKeys = [
  'balanceAreaId',
  'vat',
  'naceDevisionCode',
  'elCertShare',
  'settlementMethod',
  'netOwnerName',
] as const

const otherKeys = ['companyName'] as const

const customerAddressesKeys = [
  // 'plantCustomerAddressID',
  // 'addressType',
  'streetName',
  'streetCode',
  'buildingNumber',
  'floorID',
  'roomID',
  'postCode',
  'city',
  'citySubDivisionName',
  'municipalityCode',
  'country',
  'addressFreeForm',
  'postOfficeBox',
  'careOf',
  'attentionOf',
  'onBehalfOf',
] as const

const customerCommunicationsKeys = [
  'communicationChannel',
  'identification',
  'description',
] as const

type ChangeName = (name: string) => void

type MeteringPointProps = {
  meteringPoint?: MeteringPointDetails
}

type PlantCustomerProps = {
  customer?: MeteringPointCustomer
}

const Details = () => {
  const objectId = useParams<'objectId'>().objectId as string
  const meter = useMeter()

  const {
    isLoading: isMeterLoading,
    error: meterError,
    data: meteringPoint,
    refetch: reloadObject,
  } = useQuery(['plant', objectId], () => APIClient.getPlant(objectId))

  const {
    isLoading: isCustomerLoading,
    error: customerError,
    data: customer,
  } = useQuery(
    ['customer', objectId],
    async () => {
      return APIClient.getMeterCustomer(objectId)
    },
    { enabled: !!objectId }
  )

  const onChangeName: ChangeName = async (name: string) => {
    try {
      await APIClient.setObjectName(objectId, name)
      reloadObject()
    } catch (err) {
      console.log('Something went wrong', err)
    }
  }

  if (isCustomerLoading || !customer || isMeterLoading || !meteringPoint) {
    return <Loading />
  }

  if (customerError) {
    console.error(customerError)
    return <p>Error. Check console.</p>
  }

  if (meterError) {
    console.error(meterError)
    return <p>Error. Check console.</p>
  }

  return (
    <div className="h-full w-full divide-solid md:px-6">
      <HeaderSection
        meter={meter}
        onChangeName={onChangeName}
        meteringPoint={meteringPoint}
      />
      <MeteringSection meteringPoint={meteringPoint} />

      <PropertySection meteringPoint={meteringPoint} />
      <DetailsSection meteringPoint={meteringPoint} />

      <CustomerDetailsSection customer={customer} />
      <CustomerAddressesSection customer={customer} />
      <CustomerCommunicationsSection customer={customer} />
    </div>
  )
}

export default Details

type EditableTextProps = {
  text: string
  onSubmit: (text: string) => void
}

const EditableText = ({ text, onSubmit }: EditableTextProps) => {
  const [value, setValue] = useState(text)
  const [editing, setEditing] = useState(false)

  const startEditing = () => setEditing(true)
  const finishEditing = () => setEditing(false)

  const handleSubmit = () => {
    onSubmit(value)
    setEditing(false)
  }

  return editing ? (
    <div className="flex flex-row">
      <input
        autoFocus
        className="focus:outline-none mr-2 rounded-md border border-gray-300 px-2 focus:border-blue-300"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <EditableButtonWrapper onClick={finishEditing}>
        <XIcon className="mt-1.5 h-5 w-5 text-red-500" />
      </EditableButtonWrapper>
      <EditableButtonWrapper onClick={handleSubmit}>
        <CheckIcon className="mt-1.5 h-5 w-5 text-green-500" />
      </EditableButtonWrapper>
    </div>
  ) : (
    <div className="flex flex-row">
      <span className="lime px-2 ">{text}</span>
      <EditableButtonWrapper onClick={startEditing}>
        <PencilIcon className="mt-1.5 h-5 w-5 text-gray-500" />
      </EditableButtonWrapper>
    </div>
  )
}

const EditableButtonWrapper = ({
  children,
  onClick,
}: PropsWithChildren<{ onClick?: () => void }>) => {
  return (
    <span
      className="cursor-pointer rounded-md px-2 hover:bg-gray-200"
      onClick={onClick}
    >
      {children}
    </span>
  )
}

const HeaderSection = ({
  meter,
  onChangeName,
  meteringPoint,
}: {
  meter?: MeteringPointOverview
  onChangeName: ChangeName
  meteringPoint?: MeteringPointDetails
}) => {
  const { t } = useTranslation()

  const [showHelpModal, setShowHelpModal] = useState(false)

  return (
    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
      <div className="mt-2 flex flex-col space-y-1">
        <p className="text-sm font-medium uppercase text-gray-600">
          {t('objects:detailView.facility')}
        </p>
        <div className="flex flex-col space-y-3">
          <span className="-ml-2 text-2xl font-semibold ">
            <EditableText
              text={String(meter?.objectName)}
              onSubmit={onChangeName}
            />
          </span>
          {meter && (
            <div className="flex flex-wrap">
              {tagKeys.map((item) => (
                <Section.Tag
                  key={item}
                  name={t(`objects:detailView.${item}`)}
                  value={String(meter[item])}
                />
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="mt-2 flex w-full items-center justify-between space-x-4 sm:mt-auto sm:w-auto">
        {meteringPoint && (
          <div className="flex flex-col space-y-0">
            <span className="text-xs font-medium text-gray-500">
              {t('objects:detailView.accessType')}
            </span>
            <span className="text-base font-medium text-gray-900">
              {meteringPoint.accessType}
            </span>
          </div>
        )}

        <ActionButton
          type="button"
          onClick={() => setShowHelpModal(true)}
          Icon={QuestionMarkCircleIcon}
        >
          {t('global:help')}
        </ActionButton>
      </div>

      <HelpModal
        isOpen={showHelpModal}
        onClose={() => setShowHelpModal(false)}
        helpVideoUrl={t('objects:detailView.helpVideoUrl')}
        helpText={t('objects:detailView.helpText')}
      />
    </div>
  )
}

const SyncDetailsButton = () => {
  const { t } = useTranslation()
  const objectId = useParams<'objectId'>().objectId as string

  const { mutate, isLoading, isSuccess } = useMutation(() => {
    const promises = [
      () => APIClient.syncPlant(objectId),
      () => APIClient.syncCustomer(objectId),
    ]

    return Promise.all(promises.map((p) => Promise.resolve(p())))
  })

  const handleClick = async () => await mutate()

  if (isLoading) return <Spinner />

  return (
    <ActionButton type="button" onClick={handleClick} Icon={RefreshIcon}>
      {isSuccess ? (
        <>{t('objects:detailView.synced')}</>
      ) : (
        <>{t('objects:detailView.sync')}</>
      )}
    </ActionButton>
  )
}

const MeteringSection = ({ meteringPoint }: MeteringPointProps) => {
  const { t } = useTranslation()

  return (
    <Section.Container>
      <Section.Heading
        button={<SyncDetailsButton />}
        updatedTime={String(meteringPoint?.updatedTime)}
      >
        {t('objects:detailView.elhubDetails')}
      </Section.Heading>
      {meteringPoint && (
        <Section.Grid>
          {meteringKeys.map((item) => (
            <Section.Item
              key={item}
              name={t(`objects:detailView.${item}`)}
              value={String(meteringPoint[item])}
            />
          ))}
        </Section.Grid>
      )}
    </Section.Container>
  )
}

const PropertySection = ({ meteringPoint }: MeteringPointProps) => {
  const { t } = useTranslation()
  return (
    <Section.Container>
      {/* <Section.Heading> Property details</Section.Heading> */}
      {meteringPoint && (
        <Section.Grid>
          {propertyKeys.map((item) => (
            <Section.Item
              key={item}
              name={t(`objects:detailView.${item}`)}
              value={String(meteringPoint[item])}
            />
          ))}
        </Section.Grid>
      )}
    </Section.Container>
  )
}
const DetailsSection = ({ meteringPoint }: MeteringPointProps) => {
  const { t } = useTranslation()
  return (
    <Section.Container>
      {/* <Section.Heading>Other details</Section.Heading> */}
      {meteringPoint && (
        <Section.Grid>
          {detailKeys.map((item) => (
            <Section.Item
              key={item}
              name={t(`objects:detailView.${item}`)}
              value={String(meteringPoint[item])}
            />
          ))}
        </Section.Grid>
      )}
    </Section.Container>
  )
}

const CustomerDetailsSection = ({ customer }: PlantCustomerProps) => {
  const { t } = useTranslation()

  return (
    <Section.Container>
      <Section.Heading
        // button={<SyncCustomerButton />}
        updatedTime={String(customer?.updatedTime)}
      >
        {t('objects:customerView.customerDetails')}
      </Section.Heading>
      {customer && (
        <Section.Grid>
          {otherKeys.map((item) => (
            <Section.Item
              key={item}
              name={t(`objects:customerView.${item}`)}
              value={String(customer[item])}
            />
          ))}
          <Section.Item
            name={t('objects:customerView.extendedStorage')}
            value={
              customer?.extendedStorage
                ? t('objects:customerView.extendedStorageLabels.true')
                : t('objects:customerView.extendedStorageLabels.false')
            }
          />
        </Section.Grid>
      )}
    </Section.Container>
  )
}

const CustomerCommunicationsSection = ({ customer }: PlantCustomerProps) => {
  const { t } = useTranslation()
  const communications: MeteringPointCustomerCommunication[] =
    customer?.customerCommunications ?? []
  return (
    <>
      {communications.length !== 0 && (
        <Section.Container>
          <Section.Heading>
            {t(
              'objects:customerView.customerCommunications.customerCommunications'
            )}
          </Section.Heading>
          {communications.map(
            (comm: MeteringPointCustomerCommunication, index) => (
              <div className="" key={index}>
                <Section.Grid>
                  {customerCommunicationsKeys.map((item) => (
                    <Section.Item
                      key={item}
                      name={''}
                      value={String(comm[item])}
                    />
                  ))}
                </Section.Grid>
              </div>
            )
          )}
        </Section.Container>
      )}
    </>
  )
}

const CustomerAddressesSection = ({ customer }: PlantCustomerProps) => {
  const { t } = useTranslation()

  const addresses: MeteringPointCustomerAddress[] =
    customer?.customerAddresses ?? []

  return (
    <>
      {addresses.length !== 0 && (
        <Section.Container>
          <Section.Heading>
            {t('objects:customerView.customerAddresses.customerAddresses')}
          </Section.Heading>
          {addresses.map((addr: MeteringPointCustomerAddress, i) => (
            <Section.Grid key={i}>
              <Section.Item
                name={t('objects:customerView.customerAddresses.addressType')}
                value={
                  ['invoiceadr', 'postaladr'].includes(String(addr.addressType))
                    ? t(
                        `objects:customerView.customerAddresses.addressTypeLabel.${addr.addressType}`
                      )
                    : ' '
                }
              />
              {customerAddressesKeys.map((item) => (
                <Section.Item
                  key={item}
                  name={t(`objects:customerView.customerAddresses.${item}`)}
                  value={String(addr[item])}
                />
              ))}
            </Section.Grid>
          ))}
        </Section.Container>
      )}
    </>
  )
}
