import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import type { FreqType } from '../../types'
import {
  Label,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
  ComposedChart,
  Line,
  ReferenceLine,
  TooltipProps,
} from 'recharts'
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'
import { pad } from '../Consumption/utils'
import { MeteringPointPeak } from 'src/generated/client'

const round = (value: any, decimals: number) =>
  Math.round(value * 10 ** decimals) / 10 ** decimals

export const PeakGraph = ({
  data,
  freq,
  onGraphClick,
}: {
  data: MeteringPointPeak[]
  freq: FreqType
  showNett: boolean
  onGraphClick: (v: string) => void
}) => {
  const { t } = useTranslation()

  const graphData = useMemo(() => {
    return pad(data, freq)?.map((item: MeteringPointPeak, i: number) => ({
      timestamp: item.timestamp,
      measured: item.isPayable ? 0 : item.isPeak ? 0 : item.measured,
      peak: item.isPeak ? item.measured : 0,
      measuredLastYear: item.measuredLastYear,
      payableAverage: item.payableAverage,
      payable: item.isPayable ? item.measured : 0,
      limitDown: item.limitDown,
      limitUp: item.limitUp,
    }))
  }, [data, freq])

  const yLabel1 = t(`objects:peak.peakTableHeaders.peakYAxis`)
  const xLabel = t(`consumption:graph.${freq}`)

  return (
    <div className="mx-auto p-8 md:w-3/4 md:max-w-6xl">
      <ResponsiveContainer width="100%" aspect={3}>
        <ComposedChart
          onClick={(data: any) => onGraphClick(data?.activeLabel)}
          data={graphData}
          margin={{ top: 0, right: 50, left: 50, bottom: 30 }}
          stackOffset="sign"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            height={graphData != null ? undefined : 0}
            dataKey="timestamp"
            tickFormatter={(value) => xAxisTickFormatter(value, freq, t)}
          >
            <Label value={xLabel} offset={10} position="bottom" />
          </XAxis>

          <YAxis
            yAxisId="left"
            tickFormatter={(value) => value.toLocaleString('NO')}
            orientation="left"
          >
            <Label
              value={yLabel1}
              offset={20}
              angle={270}
              position="left"
              style={{ textAnchor: 'middle' }}
            />
          </YAxis>
          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            content={<PeakTooltip freq={freq} />}
            wrapperStyle={{ outline: 'none' }}
          />
          <ReferenceLine y={0} yAxisId="left" stroke="#000000" />
          <Bar
            dataKey="measured"
            yAxisId="left"
            stackId="stack"
            fill="#53C023"
          />
          {/* <Bar dataKey="measuredLastYear" yAxisId="left" fill="#939598" /> */}
          <Bar
            dataKey="payable"
            yAxisId="left"
            stackId="stack"
            fill="#000000"
          />
          <Bar dataKey="peak" yAxisId="left" stackId="stack" fill="#939598" />
          <Line
            type="monotone"
            animateNewValues={false}
            dataKey="payableAverage"
            yAxisId="left"
            strokeWidth={2}
            stroke="#f09521"
            dot={false}
          />
          <Line
            type="monotone"
            animateNewValues={false}
            dataKey="limitDown"
            yAxisId="left"
            strokeWidth={2}
            stroke="#6AC4FA"
            strokeDasharray="5 5"
            dot={false}
          />
          <Line
            type="monotone"
            animateNewValues={false}
            dataKey="limitUp"
            yAxisId="left"
            strokeWidth={2}
            stroke="#E84430"
            strokeDasharray="5 5"
            dot={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
      <PeakLegend freq={freq} />
    </div>
  )
}

const PeakTooltip = ({
  active,
  payload,
  freq,
  label,
}: TooltipProps<ValueType, NameType> & { freq: FreqType }) => {
  const { t } = useTranslation()

  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip rounded-md border border-gray-400 bg-white p-4 text-sm text-gray-800">
        {freq === 'year' ? (
          <p>{dayjs(payload[0].payload.timestamp).format('YYYY')}</p>
        ) : freq === 'month' ? (
          <p>
            {`${t(
              `global:shortMonths.${dayjs(payload[0].payload.timestamp)
                .format('MMM')
                .toLowerCase()}`
            )} ${dayjs(payload[0].payload.timestamp).format('YYYY')}`}
          </p>
        ) : freq === 'day' ? (
          <p>{dayjs(payload[0].payload.timestamp).format('DD.MM.YYYY')}</p>
        ) : freq === 'hour' ? (
          <p>
            {dayjs(payload[0].payload.timestamp).format('HH:mm DD.MM.YYYY')}
          </p>
        ) : null}

        <hr className="mt-1" />
        <div className="mt-1">
          {payload.map(
            (entry, index) =>
              entry.value !== 0 && (
                <p key={index} className="">
                  {t(`objects:peak.peakTableHeaders.${entry.name}`)}:{' '}
                  {round(entry.value, 2).toLocaleString('NO')}
                  {' kW'}
                </p>
              )
          )}
        </div>
      </div>
    )
  }
  return null
}

const xAxisTickFormatter = (value: string, freq: FreqType, t: any) => {
  if (freq === 'month') {
    return t(`global:shortMonths.${dayjs(value).format('MMM').toLowerCase()}`)
  } else if (freq === 'day') {
    return dayjs(value).format('D')
  } else if (freq === 'hour') {
    return dayjs(value).format('H')
  } else {
    return ''
  }
}

const PeakLegend = ({ freq }: { freq: FreqType }) => {
  const { t } = useTranslation()

  return (
    <div className="mt-4 flex flex-row justify-center space-x-8">
      <div className="flex flex-row items-center">
        <div className="mr-2 h-3 w-3 rounded-full bg-graphSeries" />
        <p>{t(`objects:peak.peakTableHeaders:measured`)}</p>
      </div>
      {/* {freq === 'month' && (
        <div className="flex flex-row items-center">
          <div className="mr-2 h-3 w-3 rounded-full bg-graphSeries-three" />
          <p>{t(`objects:peak.peakTableHeaders:measuredLastYear`)}</p>
        </div>
      )} */}
      <div className="flex flex-row items-center">
        <div className="mr-2 h-3 w-3 rounded-full bg-graphSeries-two" />
        <p>{t(`objects:peak.peakTableHeaders:payable`)}</p>
      </div>
      {freq !== 'month' && (
        <div className="flex flex-row items-center">
          <div className="mr-2 h-3 w-3 rounded-full bg-graphSeries-three" />
          <p>{t(`objects:peak.peakTableHeaders:peak`)}</p>
        </div>
      )}
      <div className="flex flex-row items-center">
        <div className="mr-2 h-3 w-3 rounded-full bg-graphSeries-four" />
        <p>{t(`objects:peak.peakTableHeaders:payableAverage`)}</p>
      </div>

      {freq !== 'hour' && (
        <div className="flex flex-row items-center">
          <div className="mr-2 h-3 w-3 rounded-full bg-graphSeries-nine" />
          <p>{t(`objects:peak.peakTableHeaders:limitDown`)}</p>
        </div>
      )}
      {freq !== 'hour' && (
        <div className="flex flex-row items-center">
          <div className="mr-2 h-3 w-3 rounded-full bg-graphSeries-ten" />
          <p>{t(`objects:peak.peakTableHeaders:limitUp`)}</p>
        </div>
      )}
    </div>
  )
}
