export function processColumns(columns: Array<any>, data: Array<any>) {
  let columnIndex = 0
  for (let td in data[0]) {
    if (typeof data[0][td] === 'number' && !('Cell' in columns[columnIndex])) {
      columns[columnIndex]['Cell'] = (props: any) => (
        <>{props.value.toLocaleString('en-IN')}</>
      )
    }
    columnIndex++
  }
  return columns
}

export function getInitials(value?: string) {
  if (value != null) {
    return value
      .split(' ')
      .map((s) => (s.trim().length > 0 ? s.trim()[0].toUpperCase() : null))
      .join('')
  }
  return null
}
