export const isValidYear = (year: string): boolean => {
  const startYear = 2019

  const nextYear = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1)
  ).getFullYear()

  const inputYear = parseInt(year)

  if (!/^-?\d+$/.test(year) || inputYear < startYear || inputYear > nextYear) {
    return false
  }

  return true
}

export const isValidMonth = (month: string): boolean => {
  const inputMonth = parseInt(month)

  if (!/^-?\d+$/.test(month) || inputMonth < 1 || inputMonth > 12) {
    return false
  }

  return true
}
