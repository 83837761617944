import dayjs from 'dayjs'
import { useQuery } from '@tanstack/react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import ScatterPlot from 'src/components/Consumption/ScatterPlot'
import FrequencyChanger from 'src/components/FrequencyChanger'
import Spinner from 'src/components/Spinner'
import { FreqType } from 'src/types'
import apiClient from 'src/utils/apiClient'
import isoWeek from 'dayjs/plugin/isoWeek'
import useDateRange from 'src/utils/hooks/useDateRange'
import DeviationsGraph from 'src/components/Consumption/DeviationsGraph'
import { GraphPlaceholder } from 'src/components/GraphPlaceholder'
import { Dropdown } from 'src/components/Dropdown'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '../action'
import { QuestionMarkCircleIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import HelpModal from '../HelpModal/HelpModal'

dayjs.extend(isoWeek)

const freqOptions = ['month', 'week', 'day']

type ETProps = {
  felt: boolean
}

export const dataPeriods = [
  'lastMonth',
  'lastThreeMonths',
  'lastSixMonths',
  'lastYear',
  'allData',
] as const

export type DataPeriod = typeof dataPeriods[number]

export const trendLines = ['trendWeekday', 'trendWeekend', 'trend'] as const

export type TrendLine = typeof trendLines[number]

const ETComponent = (props: ETProps) => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  const objectId = useParams<'objectId'>().objectId as any
  // let freq: FreqType = (searchParams.get('freq') as FreqType) || 'day'
  // let to: string = searchParams.get('to') || dayjs().format('YYYY-MM-DD')
  // let felt: boolean =
  //   searchParams.get('felt') === 'true' ? true : false || false
  const felt = props.felt

  const defaultFreq: FreqType = 'day'
  const defaultFrom: string = dayjs().subtract(1, 'year').format('YYYY-MM-DD')
  const defaultTo: string = dayjs().format('YYYY-MM-DD')
  const defaultPeriod: DataPeriod = 'lastYear'

  const { from, freq, to, period, setDateRange } = useDateRange({
    defaultFreq: defaultFreq,
    defaultFrom: defaultFrom,
    defaultTo: defaultTo,
    defaultPeriod: defaultPeriod,
  })

  const [showHelpModal, setShowHelpModal] = useState<boolean>(false)

  const { isLoading, data, error } = useQuery(
    [`ETChartFeltTemperature${felt}`, objectId, freq, from, to, felt],
    async () => {
      return apiClient.getMeteringPointETChart(objectId, from, to, freq, felt)
    }
  )

  const {
    isLoading: isWeatherLoading,
    error: weatherError,
    data: weatherStations,
  } = useQuery(
    ['weatherStations', objectId],
    async () => {
      return apiClient.getWeatherStations(objectId)
    },
    { enabled: !!objectId }
  )

  const freqChangeHandler = (freq: FreqType) => {
    if (period) {
      setSearchParams({
        freq,
        from,
        to,
        period,
      })
    } else {
      setSearchParams({
        freq,
        from,
        to,
      })
    }
  }

  if (error || weatherError) {
    console.error(error)
    console.error(weatherError)
    return <p>Error. Check console.</p>
  }

  const unparsedTrendLine = searchParams.get('trend')

  let trendLine: TrendLine = 'trend'

  if (trendLines.includes(unparsedTrendLine as TrendLine)) {
    trendLine = unparsedTrendLine as TrendLine
  }

  const trendLineSetter = (trend: TrendLine = 'trend') => {
    searchParams.set('trend', trend)
    setSearchParams(searchParams)
  }

  const windowChangeHandler = (period: DataPeriod) => {
    if (period === 'allData') {
      setDateRange({
        freq,
        from: dayjs().subtract(10, 'year').format('YYYY-MM-DD'),
        to: dayjs().format('YYYY-MM-DD'),
        period: period,
      })
    } else if (period === 'lastYear') {
      setDateRange({
        freq,
        from: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
        to: dayjs().format('YYYY-MM-DD'),
        period: period,
      })
    } else if (period === 'lastSixMonths') {
      setDateRange({
        freq,
        from: dayjs().subtract(6, 'month').format('YYYY-MM-DD'),
        to: dayjs().format('YYYY-MM-DD'),
        period: period,
      })
    } else if (period === 'lastThreeMonths') {
      setDateRange({
        freq,
        from: dayjs().subtract(3, 'month').format('YYYY-MM-DD'),
        to: dayjs().format('YYYY-MM-DD'),
        period: period,
      })
    } else if (period === 'lastMonth') {
      setDateRange({
        freq,
        from: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        to: dayjs().format('YYYY-MM-DD'),
        period: period,
      })
    }
  }

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-3">
        <div></div>
        <FrequencyChanger
          freq={freq}
          freqChangeHandler={freqChangeHandler}
          freqOptions={freqOptions}
        />

        <div className="hidden items-center justify-end sm:flex">
          <div className="block">
            <ActionButton
              type="button"
              onClick={() => setShowHelpModal(true)}
              Icon={QuestionMarkCircleIcon}
            >
              {t('global:help')}
            </ActionButton>
          </div>
        </div>
      </div>
      <div className="hidden sm:block">
        {isLoading || isWeatherLoading ? (
          <div className="flex h-80 w-full items-center justify-center">
            <Spinner />
          </div>
        ) : data && weatherStations ? (
          <ScatterPlot
            data={data}
            freq={freq}
            weatherStations={weatherStations}
          />
        ) : (
          <GraphPlaceholder dataType="Weather Stations" />
        )}
      </div>
      <div className="my-4 flex justify-center space-x-2">
        <Dropdown
          className="w-36"
          onChange={(value: DataPeriod) => {
            windowChangeHandler(value)
          }}
          value={period ? period : defaultPeriod}
          options={
            [
              {
                value: 'lastMonth',
                label: t(`global:lastMonth`),
              },
              {
                value: 'lastThreeMonths',
                label: t(`global:lastThreeMonths`),
              },
              {
                value: 'lastSixMonths',
                label: t(`global:lastSixMonths`),
              },
              {
                value: 'lastYear',
                label: t(`global:lastYear`),
              },
              {
                value: 'allData',
                label: t(`global:allData`),
              },
            ] as { value: DataPeriod; label: string }[]
          }
        />
        {freq === 'day' && (
          <Dropdown
            className="w-44"
            onChange={(value: string) => {
              trendLineSetter(value as TrendLine)
            }}
            value={trendLine}
            options={
              [
                {
                  value: 'trendWeekday',
                  label: t(`global:trendWeekday`),
                },
                {
                  value: 'trendWeekend',
                  label: t(`global:trendWeekend`),
                },
                {
                  value: 'trend',
                  label: t(`global:trendAll`),
                },
              ] as { value: TrendLine; label: string }[]
            }
          />
        )}
      </div>
      <div className="hidden sm:block">
        {isLoading || isWeatherLoading ? (
          <div className="flex h-80 w-full items-center justify-center">
            <Spinner />
          </div>
        ) : data && weatherStations ? (
          // <div className="">Awe</div>
          <DeviationsGraph
            data={data}
            freq={freq}
            periodParent={period}
            trendLine={trendLine}
            onGraphClick={() => null}
          />
        ) : (
          <GraphPlaceholder dataType="Deviations" />
        )}
      </div>
      <HelpModal
        isOpen={showHelpModal}
        onClose={() => setShowHelpModal(false)}
        helpText={t('et:help.helpText')}
      />
    </>
  )
}

export default ETComponent
