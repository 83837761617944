import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import { ClockIcon } from '@heroicons/react/outline'
import cn from 'classnames'

import APIClient from 'src/utils/apiClient'
import { getFooterForColumn } from 'src/utils/helpers/helpers'
import { useAppState } from 'src/context/appstate'

import type { MeteringPointConsumptionCost } from 'src/generated/client'
import type { FreqType, GraphSeriesType } from 'src/types'

import useDateRange from 'src/utils/hooks/useDateRange'
import useDateChanger from 'src/utils/hooks/useDateChanger'

import { Table } from 'src/components/Table'
import { TextPlaceholder } from 'src/components/TextPlaceholder'
import FrequencyChanger from 'src/components/FrequencyChanger'
import SeriesChanger from 'src/components/SeriesChanger'
import FormatNumber from 'src/components/FormatNumber'
import DateChanger from 'src/components/DateChanger'
import Spinner from 'src/components/Spinner'

import GridRentModal from './GridRentModal'
import { ConsumptionGraph } from './Graphs'
import { GraphPlaceholder } from 'src/components/GraphPlaceholder'
import PowerAgreementModal from './PowerAgreementModal'

require('dayjs/locale/nb')

type ConsumptionProps = {
  objectId?: string
  customerId?: string
  queryType: 'customer' | 'meter' // so we know which query to run in the GridRentModal
}

type fieldQualityType = 'consumption' | 'powerAgreement'

const freqOptions = ['year', 'month', 'day', 'hour']

const seriesOptions = [
  'consumption',
  'spotAmount',
  'powerAgreement',
  'totalCost',
]

const altSeriesOptions = ['consumption', 'spotAmount']

export function Consumption(props: ConsumptionProps) {
  const { t } = useTranslation()
  const { showNett } = useAppState()
  const { objectId, customerId, queryType } = props

  const defaultFreq: FreqType = 'month'
  const defaultFrom: string = dayjs().startOf('year').format('YYYY-MM-DD')
  const defaultTo: string = dayjs()
    .add(1, 'year')
    .startOf('year')
    .format('YYYY-MM-DD')

  const { freq, from, to, setDateRange } = useDateRange({
    defaultFreq: defaultFreq,
    defaultFrom: defaultFrom,
    defaultTo: defaultTo,
  })

  const [showGridRentModal, setShowGridRentModal] = useState<boolean>(false)
  const [gridRentFromDate, setGridRentFromDate] = useState<string>('')
  const [gridRentToDate, setGridRentToDate] = useState<string>('')

  const [showPowerAgreementModal, setShowPowerAgreementModal] =
    useState<boolean>(false)
  const [powerAgreementFromDate, setPowerAgreementFromDate] =
    useState<string>('')
  const [powerAgreementToDate, setPowerAgreementToDate] = useState<string>('')

  const [consumptionType, setConsumptionType] =
    useState<string>('splitConsumption')

  const { isLoading, data } = useQuery(
    ['consumption', objectId, customerId, freq, from, to],
    async () => {
      if (customerId) {
        return APIClient.getCustomerCost(customerId, from, to, freq)
      } else if (objectId) {
        return APIClient.getConsumption(objectId, from, to, freq)
      }
    }
  )

  const [graphSeries, setGraphSeries] = useState<GraphSeriesType>('consumption')

  const { dateDisplay, disablePrev, disableNext } = useDateChanger()

  const freqChangeHandler = (value: FreqType) => {
    let tmpFrom = from
    let tmpTo = to
    let tmpFreq = value

    if (value === 'year') {
      tmpFrom = dayjs(0).format('YYYY-MM-DD')
      tmpTo = dayjs().format('YYYY-MM-DD')
    } else if (value === 'month') {
      tmpFrom = dayjs().startOf('year').format('YYYY-MM-DD')
      tmpTo = dayjs().add(1, 'year').startOf('year').format('YYYY-MM-DD')
    } else if (value === 'day') {
      if (dayjs().date() === 1) {
        tmpFrom = dayjs()
          .startOf('month')
          .subtract(1, 'month')
          .format('YYYY-MM-DD')
        tmpTo = dayjs(tmpFrom)
          .add(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD')
      } else {
        tmpFrom = dayjs().startOf('month').format('YYYY-MM-DD')
        tmpTo = dayjs(tmpFrom)
          .add(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD')
      }
    } else if (value === 'hour') {
      tmpFrom = dayjs().subtract(1, 'day').startOf('day').format('YYYY-MM-DD')
      tmpTo = dayjs(tmpFrom).add(1, 'day').startOf('day').format('YYYY-MM-DD')
    }

    setDateRange({
      freq: tmpFreq,
      from: tmpFrom,
      to: tmpTo,
    })

    if (graphSeries === 'totalCost' && value !== 'month') {
      setGraphSeries('spotAmount')
    }
  }

  const consumptionTypeChangeHandler = (value: string) => {
    setConsumptionType(value)
  }

  const dateChangeHandler = (value: string) => {
    let tmpFrom = from
    let tmpTo = to

    let changeByFreq: FreqType = 'month'
    if (freq === 'month') {
      changeByFreq = 'year'
    } else if (freq === 'day') {
      changeByFreq = 'month'
    } else if (freq === 'hour') {
      changeByFreq = 'day'
    }
    if (value === 'previous') {
      tmpFrom = dayjs(tmpFrom).subtract(1, changeByFreq).format('YYYY-MM-DD')
      tmpTo = dayjs(tmpTo)
        .subtract(1, changeByFreq)
        .startOf(changeByFreq)
        .format('YYYY-MM-DD')
    }
    if (value === 'next') {
      tmpFrom = dayjs(tmpFrom).add(1, changeByFreq).format('YYYY-MM-DD')
      tmpTo = dayjs(tmpTo).add(1, changeByFreq).format('YYYY-MM-DD')
    }
    setDateRange({
      freq,
      from: tmpFrom,
      to: tmpTo,
    })
  }

  const onRowClickHanler = useCallback(
    (v: string) => {
      let tmpFrom = from
      let tmpTo = to
      let tmpFreq = freq

      let value = dayjs(v)
      if (dayjs(value).isBefore(dayjs())) {
        if (freq === 'year') {
          tmpFreq = 'month'
          tmpFrom = dayjs(value).startOf('year').format('YYYY-MM-DD')
          tmpTo = dayjs(value)
            .add(1, 'year')
            .startOf('year')
            .format('YYYY-MM-DD')
        } else if (freq === 'month') {
          tmpFreq = 'day'
          tmpFrom = dayjs(value).startOf('month').format('YYYY-MM-DD')
          tmpTo = dayjs(value)
            .add(1, 'month')
            .startOf('month')
            .format('YYYY-MM-DD')
        } else if (freq === 'day') {
          tmpFreq = 'hour'
          tmpFrom = dayjs(value).startOf('day').format('YYYY-MM-DD')
          tmpTo = dayjs(value).add(1, 'day').startOf('day').format('YYYY-MM-DD')
        }

        setDateRange({ freq: tmpFreq, from: tmpFrom, to: tmpTo })

        if (graphSeries === 'totalCost' && freq !== 'month') {
          setGraphSeries('spotAmount')
        }
      }
    },
    [freq, to, from, graphSeries, setDateRange]
  )

  const hasProduction = data?.some((row: any) => row.production < 0)

  const initialTableState = useMemo(
    () => ({
      sortBy: [
        {
          id: 'timestamp',
          desc: freq === 'month',
        },
      ],
    }),
    [freq]
  )

  const gridRentClickHandler = (v: string) => {
    setShowGridRentModal(true)
    setGridRentFromDate(dayjs(v).startOf('month').format('YYYY-MM-DDT00:00:00'))
    if (freq === 'month') {
      setGridRentToDate(
        dayjs(v).add(1, 'month').startOf('month').format('YYYY-MM-DDT00:00:00')
      )
    } else if (freq === 'year') {
      setGridRentToDate(
        dayjs(v).add(1, 'year').startOf('year').format('YYYY-MM-DDT00:00:00')
      )
    }
  }
  const powerAgreementClickHandler = (v: string) => {
    setShowPowerAgreementModal(true)
    setPowerAgreementFromDate(
      dayjs(v).startOf('month').format('YYYY-MM-DDT00:00:00')
    )
    if (freq === 'month') {
      setPowerAgreementToDate(
        dayjs(v).add(1, 'month').startOf('month').format('YYYY-MM-DDT00:00:00')
      )
    } else if (freq === 'year') {
      setPowerAgreementToDate(
        dayjs(v).add(1, 'year').startOf('year').format('YYYY-MM-DDT00:00:00')
      )
    }
  }

  const consumptionColumns = useMemo(() => {
    let startCols = [
      // Time
      {
        Header: t('objects:tableModalHeaders.time'),
        accessor: 'timestamp',
        Loader: (props: any) => <TextPlaceholder min={12} max={22} />,
        Cell: (props: any) => {
          const row = props.row.original as MeteringPointConsumptionCost

          return (
            <div className="flex items-center">
              <span
                onClick={() => onRowClickHanler(String(row.timestamp))}
                className={cn(
                  freq === 'hour'
                    ? 'tracking-wider'
                    : 'cursor-pointer tracking-wider hover:underline'
                )}
              >
                {freq === 'year'
                  ? dayjs(props.cell.value).format('YYYY')
                  : freq === 'month'
                  ? dayjs(props.cell.value).format('MM.YYYY')
                  : freq === 'day'
                  ? dayjs(props.cell.value).format('DD.MM.YYYY')
                  : dayjs(props.cell.value).format('DD.MM.YYYY HH:mm')}
              </span>
            </div>
          )
        },
      },
      // Consumption (kWh)
      {
        Header: `${t('objects:tableModalHeaders.consumption')}`,
        accessor: 'consumption',
        className: 'text-right',
        Loader: (props: any) => <TextPlaceholder min={3} max={10} />,
        Cell: (props: any) => {
          let field = props.cell.value
          const row = props.row.original as MeteringPointConsumptionCost
          return (
            <div className="flex justify-end space-x-2">
              <span
                onClick={() => onRowClickHanler(props.row.original.timestamp)}
                className={`flex flex-row space-x-2 text-right tracking-wider ${
                  freq !== 'hour' && 'cursor-pointer hover:underline'
                } ${freq === 'day' && 'cursor-pointer hover:underline'}`}
              >
                <FormatNumber number={field} decimals={field < 1 ? 2 : 1} />
              </span>
              <span className="flex flex-row space-x-2">
                <IsNotComplete field={row.isConsumptionComplete} />
                {row.consumptionQuality && (
                  <FieldQuality
                    quality={row.consumptionQuality}
                    type="consumption"
                  />
                )}
              </span>
            </div>
          )
        },
        Footer: ({ data }: any) => {
          const selector = 'consumption'
          var num = getFooterForColumn('sum', data, selector)
          return (
            <>
              <p className="mt-4 px-2 text-right text-base font-bold tracking-widest">
                <FormatNumber number={num} decimals={2} />
              </p>
            </>
          )
        },
      },

      //Production (kWh)
      {
        Header: `${t('objects:tableModalHeaders.production')}`,
        accessor: 'production',
        className: 'text-right',
        Loader: (props: any) => <TextPlaceholder min={3} max={10} />,
        Cell: (props: any) => {
          let field = props.cell.value
          return (
            <p
              onClick={() => onRowClickHanler(props.row.original.timestamp)}
              className={`text-right tracking-wider ${
                freq !== 'hour' && 'cursor-pointer hover:underline'
              } ${freq === 'day' && 'cursor-pointer hover:underline'}`}
            >
              <FormatNumber number={field} decimals={1} />
            </p>
          )
        },
        Footer: ({ data }: any) => {
          const selector = 'production'
          var num = getFooterForColumn('sum', data, selector)
          return (
            <>
              <p className="mt-4 px-2 text-right text-base font-bold tracking-widest">
                <FormatNumber number={num} decimals={1} />
              </p>
            </>
          )
        },
      },
    ]

    let cols = [
      // Spot Price (kr/kWh)
      {
        Header: t('objects:tableModalHeaders.spotPrice'),
        accessor: showNett ? 'spotCostPrice' : 'spotCostGrossPrice',
        className: 'text-right',
        Loader: (props: any) => <TextPlaceholder min={3} max={10} />,
        Cell: (props: any) => {
          let field = props.cell.value
          return (
            <p
              onClick={() => onRowClickHanler(props.row.original.timestamp)}
              className={
                freq === 'hour'
                  ? 'text-right tracking-wider'
                  : 'cursor-pointer text-right tracking-wider hover:underline'
              }
            >
              <FormatNumber number={field} decimals={field >= 1 ? 2 : 4} />
            </p>
          )
        },
      },

      // Spot Amount (NOK)
      {
        Header: t('objects:tableModalHeaders.spotAmount'),
        accessor: showNett ? 'spotCost' : 'spotGrossCost',
        sortType: 'basic',
        className: 'text-right',
        Loader: (props: any) => <TextPlaceholder min={3} max={10} />,
        Cell: (props: any) => {
          return (
            <p
              onClick={() => onRowClickHanler(props.row.original.timestamp)}
              className={
                freq === 'hour'
                  ? 'text-right tracking-wider'
                  : 'cursor-pointer text-right tracking-wider hover:underline'
              }
            >
              <FormatNumber number={props.cell.value} decimals={2} />
            </p>
          )
        },
        Footer: ({ data }: any) => {
          let selector = showNett ? 'spotCost' : 'spotGrossCost'
          var num = getFooterForColumn('sum', data, selector)
          return (
            <p className="mt-4 px-2 text-right text-base font-bold tracking-widest">
              <FormatNumber number={num} decimals={2} />
            </p>
          )
        },
      },
    ]

    // Max Consumption for period
    const maxConsumptionCol = {
      Header: `${t('objects:tableModalHeaders.maxConsumption')}`,
      accessor: 'maxConsumption',
      className: 'text-right',
      Loader: (props: any) => <TextPlaceholder min={3} max={10} />,
      Cell: (props: any) => {
        let field = props.cell.value
        return field ? (
          <p
            onClick={() => onRowClickHanler(props.row.original.timestamp)}
            className={
              freq === 'hour'
                ? 'text-right tracking-wider'
                : 'cursor-pointer text-right tracking-wider hover:underline'
            }
          >
            <FormatNumber number={field} decimals={field < 1 ? 2 : 1} />
          </p>
        ) : null
      },
    }

    const monthlyCols = [
      // Grid Rent
      {
        Header: t('objects:gridCost'),
        accessor: 'objectId',
        sortType: 'basic',
        className: 'text-right',
        Loader: (props: any) => <TextPlaceholder min={3} max={10} />,
        Cell: (props: any) => {
          const field = showNett
            ? props.row.original.gridCost
            : props.row.original.gridGrossCost
          return (
            <p
              onClick={() => gridRentClickHandler(props.row.original.timestamp)}
              className={
                freq === 'hour'
                  ? 'text-right tracking-wider'
                  : 'cursor-pointer text-right tracking-wider hover:underline'
              }
            >
              <FormatNumber number={field} decimals={2} />
            </p>
          )
        },
        Footer: ({ data }: any) => {
          let selector = showNett ? 'gridCost' : 'gridGrossCost'
          var num = getFooterForColumn('sum', data, selector)
          return (
            <>
              <p className="mt-4 px-2 text-right text-base font-bold tracking-widest">
                <FormatNumber number={num} decimals={2} />
              </p>
            </>
          )
        },
      },
      // Total Cost
      {
        Header: t('objects:totalCost'),
        accessor: 'totalCost',
        sortType: 'basic',
        className: 'text-right',
        Loader: (props: any) => <TextPlaceholder min={3} max={10} />,
        Cell: (props: any) => {
          const field = showNett
            ? props.row.original.totalCost
            : props.row.original.totalGrossCost
          return (
            <p className="text-right tracking-wider">
              <FormatNumber number={field} decimals={2} />
            </p>
          )
        },
        Footer: ({ data }: any) => {
          let selector = showNett ? 'totalCost' : 'totalGrossCost'
          var num = getFooterForColumn('sum', data, selector)
          return (
            <>
              <p className="mt-4 px-2 text-right text-base font-bold tracking-widest">
                <FormatNumber number={num} decimals={2} />
              </p>
            </>
          )
        },
      },
    ]

    const powerAgreementCol = {
      Header: `${t('objects:tableModalHeaders.powerAgreement')}`,
      accessor: 'powerAgreement',
      sortType: 'basic',
      className: 'text-right',
      Loader: (props: any) => <TextPlaceholder min={3} max={10} />,
      Cell: (props: any) => {
        const row = props.row.original as MeteringPointConsumptionCost
        const field = showNett
          ? props.row.original.powerAgreementCost
          : props.row.original.powerAgreementGrossCost
        return (
          <div className="flex justify-end space-x-2">
            <span
              onClick={() =>
                powerAgreementClickHandler(props.row.original.timestamp)
              }
              className="cursor-pointer text-right tracking-wider hover:underline"
            >
              <FormatNumber number={field} decimals={2} />
            </span>
            <span className="flex flex-row space-x-2">
              <IsNotComplete field={row.isPowerAgreementComplete} />
              {row.powerAgreementQuality && (
                <FieldQuality
                  quality={row.powerAgreementQuality}
                  type={'powerAgreement'}
                />
              )}
            </span>
          </div>
        )
      },
      Footer: ({ data }: any) => {
        let selector = showNett
          ? 'powerAgreementCost'
          : 'powerAgreementGrossCost'
        var num = getFooterForColumn('sum', data, selector)
        return (
          <>
            <p className="mt-4 px-2 text-right text-base font-bold tracking-widest">
              <FormatNumber number={num} decimals={2} />
            </p>
          </>
        )
      },
    }

    if (freq === 'month') {
      return [
        ...startCols,
        maxConsumptionCol,
        ...cols,
        powerAgreementCol,
        ...monthlyCols,
      ]
    } else if (freq === 'year') {
      return [
        ...startCols,
        maxConsumptionCol,
        ...cols,
        powerAgreementCol,
        ...monthlyCols,
      ]
    } else if (freq === 'day') {
      return [...startCols, maxConsumptionCol, ...cols]
    } else {
      return [...startCols, ...cols]
    }
  }, [
    freq,
    showNett,
    t,
    onRowClickHanler,
    powerAgreementClickHandler,
    gridRentClickHandler,
    hasProduction,
    consumptionType,
    data,
    isLoading,
    graphSeries,
  ])

  return (
    <>
      <FrequencyChanger
        freqOptions={freqOptions}
        freqChangeHandler={freqChangeHandler}
        freq={freq}
      />
      {hasProduction && graphSeries === 'consumption' && (
        <ConsumptionTypeChanger
          consumptionType={consumptionType}
          consumptionTypeChangeHandler={consumptionTypeChangeHandler}
        />
      )}

      <div className="hidden sm:block">
        {isLoading ? (
          <div className="flex h-80 w-full items-center justify-center">
            <Spinner />
          </div>
        ) : data ? (
          <ConsumptionGraph
            data={data}
            series={graphSeries}
            consumptionType={consumptionType}
            freq={freq}
            showNett={showNett}
            onGraphClick={onRowClickHanler}
          />
        ) : (
          <GraphPlaceholder dataType="Consumption" />
        )}
      </div>

      <SeriesChanger
        graphSeries={graphSeries}
        freq={freq}
        seriesOptions={seriesOptions}
        altSeriesOptions={altSeriesOptions}
        seriesChangeHandler={(value: GraphSeriesType) => setGraphSeries(value)}
      />

      <DateChanger
        dateChangeHandler={dateChangeHandler}
        dateDisplay={dateDisplay}
        disablePrev={disablePrev}
        disableNext={disableNext}
      />

      <Table
        loading={isLoading}
        loadingPlaceholderRows={3}
        columns={consumptionColumns}
        initialState={initialTableState}
        data={data}
        enableSorting={true}
        onRowClick={(rowData: any) => null} //onRowClickHanler(rowData['timestamp'])}
        tableStyleOverride="ml-6 md:ml-0 table-fixed md:table-auto"
        thStyleOverride="text-base text-gray-500 font-medium"
        tdStyleOverride="px-3 first:w-10 first:max-w-lg first:w-96"
      />

      <GridRentModal
        queryType={queryType}
        isOpen={showGridRentModal}
        objectId={objectId}
        customerId={customerId}
        onClose={() => setShowGridRentModal(false)}
        start={gridRentFromDate}
        end={gridRentToDate}
        aggregationType={freq}
      />

      <PowerAgreementModal
        queryType={queryType}
        isOpen={showPowerAgreementModal}
        objectId={objectId}
        customerId={customerId}
        onClose={() => setShowPowerAgreementModal(false)}
        start={powerAgreementFromDate}
        end={powerAgreementToDate}
        aggregationType={freq}
      />
    </>
  )
}

export const ConsumptionTypeChanger = ({
  consumptionTypeChangeHandler,
  consumptionType,
}: {
  consumptionTypeChangeHandler: Function
  consumptionType: any
}) => {
  const { t } = useTranslation()

  const options = ['netConsumption', 'splitConsumption']

  return (
    <div className="flex justify-center pb-4">
      <div className="mx-auto flex flex-row rounded-xl border border-gray-600 text-gray-800">
        {options.map((value: any, index: any) => (
          <div
            role="button"
            onClick={() => consumptionTypeChangeHandler(value)}
            key={index}
            className={cn(
              'px-4 py-2 hover:bg-gray-100',
              consumptionType === value &&
                'border-l border-r border-gray-600 bg-gray-100',
              index === 0 && 'rounded-l-xl border-l-0',
              index === options.length - 1 && 'rounded-r-xl border-r-0'
            )}
          >
            {t(`objects:${value}`)}
          </div>
        ))}
      </div>
    </div>
  )
}

export const IsNotComplete = ({
  field,
}: {
  field: boolean | null | undefined
}) => {
  const { t } = useTranslation()
  if (field === false) {
    return (
      <div className="group">
        <span className="invisible absolute z-50 -mt-8 rounded bg-black p-1 px-2 text-xs font-medium text-white shadow-lg group-hover:visible">
          {t('objects:notComplete')}
        </span>
        <ClockIcon className="h-4" />
      </div>
    )
  } else return null
}

export const FieldQuality = ({
  quality,
  type,
}: {
  quality: number
  type: fieldQualityType
}) => {
  const { t } = useTranslation()
  let color = 'text-green-600'
  let tooltip =
    type === 'consumption'
      ? t('objects:consumptionQuality.measured')
      : t('objects:powerAgreementQuality.verified')

  let Icon = CheckCircleIcon

  switch (quality) {
    case 2:
      color = 'text-yellow-600'
      tooltip =
        type === 'consumption'
          ? t('objects:consumptionQuality.estimated')
          : t('objects:powerAgreementQuality.confirmed')
      break
    case 3:
      color = 'text-red-600'
      tooltip =
        type === 'consumption'
          ? t('objects:consumptionQuality.temporary')
          : t('objects:powerAgreementQuality.notConfirmed')
      Icon = ExclamationCircleIcon
      break
  }
  return (
    <div className="group">
      <span className="invisible absolute z-50 -mt-8 rounded bg-black p-1 px-2 text-xs font-medium text-white shadow-lg group-hover:visible">
        {tooltip}
      </span>
      <Icon className={cn('h-4', color)} />
    </div>
  )
}
