/**
 * Calculate the Electricity Difference
 *
 * * averageTotal: !quantity ? 0 : totalCost / quantity,
 * * averageTotalGross: !quantity ? 0 : totalCostGross / quantity,
 */

import { Invoice, NettGross } from '../types'
import { formatNumberInput } from '../utils'

const calculateAverageTotalNett = (invoice: Invoice) => {
  if (!invoice.quantity) return 0

  const totalCost =
    (formatNumberInput(invoice.elecInvoice) || 0) +
    (formatNumberInput(invoice.gridInvoice) || 0)

  return totalCost / invoice.quantity
}

const calculateAverageTotalGross = (invoice: Invoice) => {
  if (!invoice.quantity) return 0

  const totalCostGross =
    (formatNumberInput(invoice.elecInvoiceGross) || 0) +
    (formatNumberInput(invoice.gridInvoiceGross) || 0)

  return totalCostGross / invoice.quantity
}

export const averageTotal = (invoice: Invoice): NettGross => ({
  nett: calculateAverageTotalNett(invoice),
  gross: calculateAverageTotalGross(invoice),
})
