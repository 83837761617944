import { useMemo } from 'react'

type Props = {
  min: number
  max: number
  className?: string
}

export function TextPlaceholder(props: Props) {
  const text = useMemo(() => {
    return 'a'.repeat(props.min + Math.random() * (props.max - props.min))
  }, [props])

  return (
    <span
      className={`w-auto animate-pulse rounded bg-gray-100 text-transparent ${props.className}`}
    >
      {text}
    </span>
  )
}
