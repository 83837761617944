import { NettGross } from './types'
import { useAppState } from 'src/context/appstate'
import { DocumentDuplicateIcon } from '@heroicons/react/outline'

export const TableData = ({
  value,
  ng,

  formatter = (v) => '' + v,
}: {
  value?: number
  ng?: NettGross

  formatter?: (v: number) => string
}) => {
  const { showNett } = useAppState()

  return (
    <span className="cursor-pointer whitespace-nowrap text-sm tracking-wider">
      <div>{formatter(ng ? (showNett ? ng.nett : ng.gross) : value || 0)} </div>
    </span>
  )
}

export const FilesIndicicator = ({ fileCount }: { fileCount: number }) => (
  <div className="flex items-center space-x-0 whitespace-nowrap text-center text-sm">
    {fileCount > 0 && (
      <>
        <span className="text-xs font-medium text-gray-700">{fileCount}</span>
        <DocumentDuplicateIcon className="h-4 w-4 text-gray-700" />
      </>
    )}
  </div>
)

export const TableLabel = ({ label }: { label: string }) => {
  return (
    <span className="cursor-pointer tracking-wider hover:underline">
      {label}
    </span>
  )
}
