import cn from 'classnames'

type Props = {
  options: Array<any>
  label?: string
  value: string
  onChange: any
  className?: string
}

export function Dropdown(props: Props) {
  return (
    <div className="flex">
      <div className="portal-dropdown__container w-full">
        <select
          value={props.value}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => props.onChange(e.target.value)}
          className={cn(
            'portal-dropdown w-full appearance-none rounded-md border-2 border-gray-400 bg-gray-100 px-2 text-center text-sm font-semibold',
            props.className
          )}
        >
          {props.options &&
            props.options.map((option, index) => {
              if (typeof option == 'object') {
                return (
                  <option key={index} value={option.value}>
                    {option?.label}
                  </option>
                )
              } else {
                return <option key={index}>{option}</option>
              }
            })}
        </select>
      </div>
    </div>
  )
}
