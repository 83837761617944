import cn from 'classnames'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'

const disabledClasses = 'cursor-not-allowed text-gray-400'
const enabledClasses = 'hover:bg-gray-100 text-gray-900'

export default function DateChanger({
  dateChangeHandler,
  dateDisplay,
  disablePrev,
  disableNext,
}: {
  dateChangeHandler: Function
  dateDisplay: string
  disablePrev: boolean
  disableNext: boolean
}) {
  return (
    <div className="my-4 flex justify-center">
      <div className="flex w-full flex-row text-gray-800 sm:w-auto">
        <Button
          side="left"
          isDisabled={disablePrev}
          onClick={() => dateChangeHandler('previous')}
        />

        <div
          style={{ textTransform: 'capitalize' }}
          className="w-full border-t border-b border-gray-600 px-4 py-2 text-center sm:w-auto"
        >
          {dateDisplay}
        </div>

        <Button
          side="right"
          isDisabled={disableNext}
          onClick={() => dateChangeHandler('next')}
        />
      </div>
    </div>
  )
}

const Button = ({
  isDisabled,
  onClick,
  side,
}: {
  isDisabled: boolean
  onClick: Function
  side: 'left' | 'right'
}) => (
  <div
    role="button"
    onClick={() => {
      if (isDisabled) return

      onClick()
    }}
    className={cn(
      'border border-gray-600 px-2 py-2',
      side === 'left' ? 'rounded-l-xl' : 'rounded-r-xl',
      isDisabled ? disabledClasses : enabledClasses
    )}
  >
    {side === 'left' ? (
      <ChevronLeftIcon className="h-full w-5" />
    ) : (
      <ChevronRightIcon className="h-full w-5" />
    )}
  </div>
)
