/**
 * Calculate the Grid Difference
 *
 * * gridDiff: gridInvoice ? gridInvoice - rent : 0
 * * gridDiffGross: gridInvoiceGross - rentGross
 */

import { Invoice, NettGross } from '../types'
import { formatNumberInput } from '../utils'

const calculateGridDifferenceNett = (invoice: Invoice) => {
  if (invoice.gridInvoice) {
    if (!invoice.rent) return formatNumberInput(invoice.gridInvoice)

    return (
      formatNumberInput(invoice.gridInvoice) - formatNumberInput(invoice.rent)
    )
  }

  return 0
}

const calculateGridDifferenceGross = (invoice: Invoice) => {
  if (invoice.gridInvoiceGross) {
    if (!invoice.rentGross) return formatNumberInput(invoice.gridInvoiceGross)

    return (
      formatNumberInput(invoice.gridInvoiceGross) -
      formatNumberInput(invoice.rentGross)
    )
  }

  return 0
}

export const gridDifference = (invoice: Invoice): NettGross => ({
  nett: calculateGridDifferenceNett(invoice),
  gross: calculateGridDifferenceGross(invoice),
})
