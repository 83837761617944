import { useMemo, useState } from 'react'
import { Card } from 'src/components/Card'
import { Table } from 'src/components/Table'

interface Step1Props {
  currentStep: string
  nextStep: (step: any) => void
  children: any
}
interface Step1State {
  results: Array<any>
  searchValue: string
  searchPerformed: boolean
}

const Step1 = (props: Step1Props) => {
  const initialState = {
    results: [],
    searchValue: '',
    searchPerformed: false,
  }

  const [state, setState] = useState<Step1State>(initialState)

  const searchValue = (searchValue: string) => {
    setState((prevState) => ({
      ...prevState,
      searchValue,
    }))
  }

  const sumbitFormData = (e: any) => {
    e.preventDefault()
    props.nextStep(parseInt(props.currentStep) + 1)
  }

  const companySearch = () => {
    setState((prevState) => ({
      ...prevState,
      // results: mockData,
      searchPerformed: true,
    }))
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Navn',
        accessor: 'navn',
        sortType: 'basic',
      },
      {
        Header: 'Forretningsadresse',
        accessor: 'forretningsadresse',
        sortType: 'basic',
      },
      {
        Header: 'Postadresse',
        accessor: 'postadresse',
        sortType: 'basic',
      },
    ],
    []
  )

  return (
    <>
      <div id="organisations">
        <p className="py-4 pl-3 text-center text-sm">
          Vi henter ut bedriftsinformasjon automatisk fra enhetsregisteret
          basert på org. nummer. Informasjon kan du endre senere i prosessen
        </p>

        <br />
        <Card>
          <form onSubmit={(e) => sumbitFormData(e)}>
            <div className="row">
              <div className="flex justify-center">
                <p>{state.searchValue ? `"${state.searchValue}"` : 'Søk...'}</p>
              </div>
              <div className="flex justify-center">
                <div
                  className="flex items-center"
                  onClick={() => companySearch()}
                >
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className="bi bi-search"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                    />
                  </svg>
                </div>
                <div className="m-2 w-52">
                  <input
                    type="text"
                    className="w-full rounded-lg border p-2 text-sm"
                    placeholder=""
                    onChange={(e) => searchValue(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {props.children}
          </form>

          {state.results.length > 0 && (
            <div id="results" className="w-full">
              <p className="ingress" id="resultHeader">
                Vi fant følgende firma i enhetsregisteret​:
              </p>
              <br />
            </div>
          )}
          {state.results.length > 0 && (
            <div className="flex w-full justify-center">
              <Table
                tableStyleOverride="md:table-fixed"
                data={[]}
                columns={columns}
                onRowClick={() => {
                  return
                }}
              />
            </div>
          )}

          {state.results.length === 0 && state.searchPerformed && (
            <div id="results" className="mt-4 block">
              <p className="ingress" id="resultHeader">
                Ingen treff
              </p>
            </div>
          )}
        </Card>
      </div>
    </>
  )
}

export default Step1
