import dayjs from 'dayjs'
import { FC, HTMLProps } from 'react'
import { useTranslation } from 'react-i18next'

const Heading: FC<{
  button?: HTMLProps<HTMLButtonElement>
  updatedTime?: string
}> = ({ children, button, updatedTime }) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col border-b pb-2 sm:h-10 sm:flex-row sm:items-center sm:justify-between">
      <h3 className="text-sm font-medium uppercase tracking-wider text-gray-600 ">
        {children}
      </h3>
      {button ? (
        <div className="flex flex-row items-center text-sm text-gray-600">
          <p>
            {t('objects:detailView.lastUpdated')}:{' '}
            {dayjs(updatedTime).format('DD.MM.YYYY HH:mm')}
          </p>
          <span className="ml-4">{button}</span>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

const Grid: FC = ({ children }) => (
  <div className="grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-5">
    {children}
  </div>
)

const Container: FC = ({ children }) => (
  <div className="mt-12 flex w-full flex-col space-y-5">{children}</div>
)

const Item = ({ name, value }: { name: string; value: string }) => (
  <div className="mb-4 flex flex-col space-y-1">
    <span className="text-sm font-medium text-gray-500">{name}</span>
    <span className="text-lg font-medium text-gray-900">
      {value ? value : ' '}
    </span>
  </div>
)

const Tag = ({ name, value }: { name: string; value: string }) => (
  <div className="mr-2 mb-1 whitespace-nowrap rounded-md bg-gray-200 px-2 py-1 text-xs font-medium uppercase text-gray-800 ">
    {name} #{value}
  </div>
)

const Section = { Heading, Grid, Container, Item, Tag }

export default Section
