import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import APIClient from 'src/utils/apiClient'
import { useAppState } from 'src/context/appstate'
import useDateRange from 'src/utils/hooks/useDateRange'
import { SpotPriceGraph } from 'src/components/Consumption/Graphs'
import type { FreqType } from 'src/types'
import Spinner from 'src/components/Spinner'
import DateChanger from 'src/components/DateChanger'
import FrequencyChanger from 'src/components/FrequencyChanger'
import SeriesChanger from 'src/components/SeriesChanger'

require('dayjs/locale/nb')

const freqOptions = ['year', 'month', 'day', 'hour']

const seriesOptions = ['NO1', 'NO2', 'NO3', 'NO4', 'NO5']

const SpotPrice = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { showNett } = useAppState()
  const objectId = useParams<'objectId'>().objectId as string

  const defaultFreq: FreqType = 'month'
  const defaultFrom: string = dayjs().startOf('year').format('YYYY-MM-DD')
  const defaultTo: string = dayjs()
    .add(1, 'year')
    .startOf('year')
    .format('YYYY-MM-DD')

  const { freq, from, to, setDateRange } = useDateRange({
    defaultFreq: defaultFreq,
    defaultFrom: defaultFrom,
    defaultTo: defaultTo,
  })
  const { data, isLoading } = useQuery(
    ['spotprice', objectId, freq, from, to],
    () => APIClient.getMeteringPointSpotPrice(objectId, from, to, freq)
  )

  const [graphSeries, setGraphSeries] = useState()

  useEffect(() => {
    setGraphSeries(data?.balanceAreaId)
  }, [data?.balanceAreaId])

  const { dateDisplay, disablePrev, disableNext } = useMemo(() => {
    const loc = language === 'no' ? 'nb' : 'en'

    if (freq === 'year') {
      return {
        dateDisplay: 'All',
        disablePrev: true,
        disableNext: true,
      }
    } else if (freq === 'month') {
      return {
        dateDisplay: dayjs(from).format('YYYY'),
        disablePrev: dayjs(from)
          .subtract(1, 'year')
          .isBefore(dayjs('2020-01-01')),
        disableNext: dayjs(to).add(1, 'day').isAfter(dayjs()),
      }
    } else if (freq === 'day') {
      return {
        dateDisplay: dayjs(from).locale(loc).format('MMMM YYYY'),
        disablePrev: dayjs(from)
          .subtract(1, 'month')
          .isBefore(dayjs('2020-01-01')),
        disableNext: dayjs(to).add(1, 'day').isAfter(dayjs()),
      }
    } else {
      const fmt = loc === 'nb' ? 'DD.MM.YYYY' : 'YYYY-MM-DD'

      const dateDisplay = dayjs(from).format(fmt)
      const disablePrev = dayjs(from)
        .subtract(1, 'day')
        .isBefore(dayjs('2020-01-01'))
      let disableNext = false
      if (dayjs().isAfter(dayjs().set('hour', 13), 'hour')) {
        disableNext = dayjs(to).subtract(1, 'day').isAfter(dayjs())
      } else {
        disableNext = dayjs(to).isAfter(dayjs())
      }
      return {
        dateDisplay,
        disablePrev,
        disableNext,
      }
    }
  }, [freq, from, to, language])

  const freqChangeHandler = (value: FreqType) => {
    let tmpFrom = from
    let tmpTo = to
    let tmpFreq = value

    if (value === 'year') {
      tmpFrom = dayjs(0).format('YYYY-MM-DD')
      tmpTo = dayjs().format('YYYY-MM-DD')
    } else if (value === 'month') {
      tmpFrom = dayjs().startOf('year').format('YYYY-MM-DD')
      tmpTo = dayjs().add(1, 'year').startOf('year').format('YYYY-MM-DD')
    } else if (value === 'day') {
      if (dayjs().date() === 1) {
        tmpFrom = dayjs()
          .startOf('month')
          .subtract(1, 'month')
          .format('YYYY-MM-DD')
        tmpTo = dayjs(tmpFrom)
          .add(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD')
      } else {
        tmpFrom = dayjs().startOf('month').format('YYYY-MM-DD')
        tmpTo = dayjs(tmpFrom)
          .add(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD')
      }
    } else if (value === 'hour') {
      tmpFrom = dayjs().subtract(1, 'day').startOf('day').format('YYYY-MM-DD')
      tmpTo = dayjs(tmpFrom).add(1, 'day').startOf('day').format('YYYY-MM-DD')
    }

    setDateRange({ freq: tmpFreq, from: tmpFrom, to: tmpTo })
  }

  const dateChangeHandler = (value: string) => {
    let tmpFrom = from
    let tmpTo = to

    let changeByFreq: FreqType = 'month'
    if (freq === 'month') {
      changeByFreq = 'year'
    } else if (freq === 'day') {
      changeByFreq = 'month'
    } else if (freq === 'hour') {
      changeByFreq = 'day'
    }
    if (value === 'previous') {
      tmpFrom = dayjs(tmpFrom).subtract(1, changeByFreq).format('YYYY-MM-DD')
      tmpTo = dayjs(tmpTo)
        .subtract(1, changeByFreq)
        .startOf(changeByFreq)
        .format('YYYY-MM-DD')
    }
    if (value === 'next') {
      tmpFrom = dayjs(tmpFrom).add(1, changeByFreq).format('YYYY-MM-DD')
      tmpTo = dayjs(tmpTo).add(1, changeByFreq).format('YYYY-MM-DD')
    }

    setDateRange({ freq, from: tmpFrom, to: tmpTo })
  }

  const onRowClickHanler = useCallback(
    (v: string) => {
      let tmpFrom = from
      let tmpTo = to
      let tmpFreq = freq

      let value = dayjs(v)

      if (
        (dayjs().isAfter(dayjs().set('hour', 13), 'hour') &&
          dayjs(value).isBefore(dayjs().add(1, 'day'))) ||
        dayjs(value).isBefore(dayjs())
      ) {
        if (freq === 'year') {
          tmpFreq = 'month'
          tmpFrom = dayjs(value).startOf('year').format('YYYY-MM-DD')
          tmpTo = dayjs(value)
            .add(1, 'year')
            .startOf('year')
            .format('YYYY-MM-DD')
        } else if (freq === 'month') {
          tmpFreq = 'day'
          tmpFrom = dayjs(value).startOf('month').format('YYYY-MM-DD')
          tmpTo = dayjs(value)
            .add(1, 'month')
            .startOf('month')
            .format('YYYY-MM-DD')
        } else if (freq === 'day') {
          tmpFreq = 'hour'
          tmpFrom = dayjs(value).startOf('day').format('YYYY-MM-DD')
          tmpTo = dayjs(value).add(1, 'day').startOf('day').format('YYYY-MM-DD')
        }

        setDateRange({ freq: tmpFreq, from: tmpFrom, to: tmpTo })
      }
    },
    [freq, from, to, setDateRange]
  )

  return (
    <>
      {data && (
        <div className="text-gray-600">
          {t('objects:meteringPointAreaSentence')} {data?.balanceAreaId}
        </div>
      )}
      <FrequencyChanger
        freqOptions={freqOptions}
        freqChangeHandler={freqChangeHandler}
        freq={freq}
      />

      <div className="hidden sm:block">
        {isLoading ? (
          <div className="flex h-80 w-full items-center justify-center">
            <Spinner />
          </div>
        ) : data?.spotPrices?.length !== 0 ? (
          <SpotPriceGraph
            data={data}
            series={graphSeries}
            freq={freq}
            showNett={showNett}
            onGraphClick={onRowClickHanler}
          />
        ) : (
          <div className="flex justify-center pb-4">
            {t('global:noDataToDisplay')}
          </div>
        )}
      </div>

      <SeriesChanger
        graphSeries={graphSeries}
        seriesOptions={seriesOptions}
        seriesChangeHandler={(value: any) => setGraphSeries(value)}
      />

      <DateChanger
        dateChangeHandler={dateChangeHandler}
        dateDisplay={dateDisplay}
        disablePrev={disablePrev}
        disableNext={disableNext}
      />
    </>
  )
}

export default SpotPrice
