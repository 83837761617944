import { useEffect } from 'react'

interface WizardStepsProps {
  steps: Array<string>
  activeStep: string
  selectedStep: (currentStep: number) => void
}
const WizardSteps = ({ steps, activeStep, selectedStep }: WizardStepsProps) => {
  useEffect(() => {
    console.log(activeStep)
  }, [activeStep])

  return (
    <>
      <div className="w-screen overflow-x-auto pl-20 md:container md:pl-0">
        <div className="flex justify-center">
          {steps.map((step, index) => {
            return (
              <div
                onClick={() => selectedStep(index + 1)}
                key={index}
                id={`step-box-${index + 1}`}
                className={`ml-4 rounded-3xl border px-4 py-2 font-semibold md:py-3 ${
                  parseInt(activeStep) === index + 1
                    ? 'bg-green-400'
                    : 'bg-gray-400'
                }`}
              >
                <h6 className="text-white">
                  <span className="badge badge-primary badge-pill badge-color">
                    {index + 1}
                  </span>
                  <span className="hidden pl-1 md:inline">{step}</span>
                </h6>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default WizardSteps
