import { useParams } from 'react-router-dom'
import { Consumption } from 'src/components/Consumption'

const SumCompanyPage = () => {
  const customerId = useParams<'customerId'>().customerId as string

  return <Consumption queryType="customer" customerId={customerId} />
}

export default SumCompanyPage
