import { useNavigate } from 'react-router'

interface FooterNavProps {
  currentStep: string
  prevStep: any
}

const FooterNav = ({ currentStep, prevStep }: FooterNavProps) => {
  const OnClickPrevStep = () => {
    console.log('clickeD? ')
    prevStep(parseInt(currentStep) - 1)
  }

  const navigate = useNavigate()

  const OnClickNextStep = () => {
    // nextStep(parseInt(currentStep) + 1);
  }

  const saveAndContinueLater = () => {}

  const redirctToHome = () => {
    const alertConfirm = window.confirm('Er du sikker på at du vil avbryte?')

    if (alertConfirm) {
      navigate(`/App/index`)
    } else return
  }

  return (
    <>
      {' '}
      <div className="m-2 grid justify-center md:m-6 md:flex">
        <div
          className="mt-2 rounded-3xl border bg-red-400 px-4 py-3 font-semibold text-white md:ml-4"
          onClick={() => redirctToHome()}
        >
          Avbryt
        </div>
        {currentStep !== '1' && (
          <button
            className="mt-2 rounded-3xl border bg-blue-400 px-4 py-3 font-semibold text-white md:ml-4"
            onClick={() => OnClickPrevStep()}
          >
            Tilbake
          </button>
        )}
        {currentStep !== '7' && (
          <button
            type="submit"
            value="next"
            name="btnSubmit"
            className="mt-2 rounded-3xl border bg-blue-400 px-4 py-3 font-semibold text-white md:ml-4"
            onClick={() => OnClickNextStep()}
          >
            Fortsett
          </button>
        )}
        <div
          className="mt-2 rounded-3xl border bg-blue-400 px-4 py-3 font-semibold text-white md:ml-4"
          onClick={() => saveAndContinueLater()}
        >
          Lagre og fortsett senere
        </div>
        {currentStep === '1' && (
          <>
            <input
              type="submit"
              name="BtnNext"
              value="Finish"
              className="mt-2 bg-transparent px-4 py-3 font-semibold md:ml-4"
            />
            *
          </>
        )}
      </div>
    </>
  )
}

export default FooterNav
