/**
 * Calculate the average profit
 */

import { Invoice, NettGross } from '../types'
import { formatNumberInput } from '../utils'

const calculateAverageProfitNett = (invoice: Invoice) => {
  if (invoice.quantity && invoice.elecInvoice) {
    return (
      (formatNumberInput(invoice.elecInvoice) -
        formatNumberInput(invoice.amount)) /
      invoice.quantity
    )
  }

  return 0
}

const calculateAverageProfitGross = (invoice: Invoice) => {
  if (invoice.quantity && invoice.elecInvoiceGross) {
    return (
      (formatNumberInput(invoice.elecInvoiceGross) -
        formatNumberInput(invoice.amountGross)) /
      invoice.quantity
    )
  }

  return 0
}

export const averageProfit = (invoice: Invoice): NettGross => ({
  nett: calculateAverageProfitNett(invoice),
  gross: calculateAverageProfitGross(invoice),
})
