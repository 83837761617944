import { Invoice } from '../types'
import { average } from './average'
import { averageGrid } from './averageGrid'
import { averageProfit } from './averageProfit'
import { averageTotal } from './averageTotal'
import { elecDifference } from './elecDifference'
import { gridDifference } from './gridDifference'

const useInvoiceCalculator = (invoice: Invoice) => ({
  average: () => average(invoice),
  gridDifference: () => gridDifference(invoice),
  elecDifference: () => elecDifference(invoice),
  averageProfit: () => averageProfit(invoice),
  averageGrid: () => averageGrid(invoice),
  averageTotal: () => averageTotal(invoice),
})

export default useInvoiceCalculator
