export const format = (value: number, decimals: number, langage: string) =>
  value.toLocaleString(['no', 'nb'].includes(langage) ? 'NO' : 'ZA', {
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  })

export const formatForInput = (
  value: number | string | null,
  langage: string,
  decimals: number = 2
) => {
  const region = ['no', 'nb'].includes(langage) ? 'NO' : 'ZA'
  if (value === null) {
    let tempValue = 0
    return tempValue.toLocaleString(region, {
      maximumFractionDigits: decimals,
      minimumFractionDigits: decimals,
    })
  }

  return value.toLocaleString(region, {
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  })
}
