import { useCallback, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { QuestionMarkCircleIcon } from '@heroicons/react/outline'
import apiClient from 'src/utils/apiClient'
import useDateChanger from 'src/utils/hooks/useDateChanger'
import useDateRange from 'src/utils/hooks/useDateRange'
import FrequencyChanger from 'src/components/FrequencyChanger'
import Spinner from 'src/components/Spinner'
import DateChanger from 'src/components/DateChanger'
import { FreqType } from 'src/types'
import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import PowerFactorGraph from 'src/components/powerFactorComponents/powerFactorGraphNew'
import { GraphPlaceholder } from 'src/components/GraphPlaceholder'
import { ActionButton } from 'src/components/action'
import HelpModal from 'src/components/HelpModal/HelpModal'

dayjs.extend(isoWeek)

const freqOptions = ['day', 'hour']

const PowerFactor = () => {
  const { t } = useTranslation()
  // let freq: FreqType = (searchParams.get('freq') as FreqType) || 'day'

  const objectId = useParams<'objectId'>().objectId as string

  const defaultFreq: FreqType = 'day'

  const defaultFrom: string = dayjs()
    .startOf('month')
    .subtract(1, 'month')
    .format('YYYY-MM-DD')

  const defaultTo: string = dayjs(defaultFrom)
    .add(1, 'month')
    .startOf('month')
    .format('YYYY-MM-DD')

  const { from, freq, to, setDateRange } = useDateRange({
    defaultFreq: defaultFreq,
    defaultFrom: defaultFrom,
    defaultTo: defaultTo,
  })

  const { dateDisplay, disablePrev, disableNext } = useDateChanger({
    is_power_factor: true,
  })

  const [showHelpModal, setShowHelpModal] = useState(false)

  const { isLoading, data } = useQuery(
    ['PowerFactor', objectId, freq, from, to],
    () => apiClient.getMeteringPointPowerFactor(objectId, from, to, freq),
    {
      enabled: !!objectId,
    }
  )
  const freqChangeHandler = (value: FreqType) => {
    let tmpFrom = from
    let tmpTo = to
    let tmpFreq = value

    if (value === 'month') {
      tmpFrom = dayjs().startOf('year').format('YYYY-MM-DD')
      tmpTo = dayjs().add(1, 'year').startOf('year').format('YYYY-MM-DD')
    } else if (value === 'day') {
      if (dayjs().date() === 1) {
        tmpFrom = dayjs()
          .startOf('month')
          .subtract(1, 'month')
          .format('YYYY-MM-DD')
        tmpTo = dayjs(tmpFrom)
          .add(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD')
      } else {
        tmpFrom = dayjs().startOf('month').format('YYYY-MM-DD')
        tmpTo = dayjs(tmpFrom)
          .add(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD')
      }
    } else if (value === 'hour') {
      tmpFrom = dayjs().subtract(1, 'day').startOf('day').format('YYYY-MM-DD')
      tmpTo = dayjs(tmpFrom).add(1, 'day').startOf('day').format('YYYY-MM-DD')
    }

    setDateRange({
      freq: tmpFreq,
      from: tmpFrom,
      to: tmpTo,
    })
  }

  const dateChangeHandler = (value: string) => {
    let tmpFrom = from
    let tmpTo = to

    let changeByFreq: FreqType = 'month'
    if (freq === 'month') {
      changeByFreq = 'year'
    } else if (freq === 'day') {
      changeByFreq = 'month'
    } else if (freq === 'hour') {
      changeByFreq = 'day'
    }
    if (value === 'previous') {
      tmpFrom = dayjs(tmpFrom).subtract(1, changeByFreq).format('YYYY-MM-DD')
      tmpTo = dayjs(tmpTo)
        .subtract(1, changeByFreq)
        .startOf(changeByFreq)
        .format('YYYY-MM-DD')
    }
    if (value === 'next') {
      tmpFrom = dayjs(tmpFrom).add(1, changeByFreq).format('YYYY-MM-DD')
      tmpTo = dayjs(tmpTo).add(1, changeByFreq).format('YYYY-MM-DD')
    }
    setDateRange({
      freq,
      from: tmpFrom,
      to: tmpTo,
    })
  }

  const onRowClickHanler = useCallback(
    (v: string) => {
      let tmpFrom = from
      let tmpTo = to
      let tmpFreq = freq

      let value = dayjs(v)
      if (dayjs(value).isBefore(dayjs())) {
        if (freq === 'year') {
          tmpFreq = 'month'
          tmpFrom = dayjs(value).startOf('year').format('YYYY-MM-DD')
          tmpTo = dayjs(value)
            .add(1, 'year')
            .startOf('year')
            .format('YYYY-MM-DD')
        } else if (freq === 'month') {
          tmpFreq = 'day'
          tmpFrom = dayjs(value).startOf('month').format('YYYY-MM-DD')
          tmpTo = dayjs(value)
            .add(1, 'month')
            .startOf('month')
            .format('YYYY-MM-DD')
        } else if (freq === 'day') {
          tmpFreq = 'hour'
          tmpFrom = dayjs(value).startOf('day').format('YYYY-MM-DD')
          tmpTo = dayjs(value).add(1, 'day').startOf('day').format('YYYY-MM-DD')
        }

        setDateRange({ freq: tmpFreq, from: tmpFrom, to: tmpTo })
      }
    },
    [freq, to, from, setDateRange]
  )

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-3">
        <div></div>
        <FrequencyChanger
          freq={freq}
          freqChangeHandler={freqChangeHandler}
          freqOptions={freqOptions}
        />

        <div className="hidden items-center justify-end sm:flex">
          <div className="block">
            <ActionButton
              type="button"
              onClick={() => setShowHelpModal(true)}
              Icon={QuestionMarkCircleIcon}
            >
              {t('global:help')}
            </ActionButton>
          </div>
        </div>
      </div>

      <div className="hidden sm:block">
        {isLoading ? (
          <div className="flex h-80 w-full items-center justify-center">
            <Spinner />
          </div>
        ) : data ? (
          <PowerFactorGraph
            data={data}
            freq={freq}
            onGraphClick={onRowClickHanler}
          />
        ) : (
          <GraphPlaceholder dataType="Power Factor" />
        )}
      </div>
      <DateChanger
        dateChangeHandler={dateChangeHandler}
        dateDisplay={dateDisplay}
        disablePrev={disablePrev}
        disableNext={disableNext}
      />

      <HelpModal
        isOpen={showHelpModal}
        onClose={() => setShowHelpModal(false)}
        helpText={t('powerFactor:help.helpText')}
      />
    </>
  )
}

export default PowerFactor
