import { forwardRef } from 'react'
import cn from 'classnames'
import { ActionButton, type ActionButtonProps } from './Button'

export const PrimaryButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  ({ className, ...props }, ref) => {
    return (
      <ActionButton
        ref={ref}
        className={cn(
          'border-lime bg-lime text-white hover:bg-lime hover:shadow-lg focus:ring-white',
          className
        )}
        {...props}
      >
        {props.children}
      </ActionButton>
    )
  }
)
