import { useState } from 'react'
import { GlobeAltIcon } from '@heroicons/react/solid'
import { PrimaryButton } from 'src/components/action'

interface LanguageToggleProps {
  language: string
  translate: (lang: string) => void
}

type language = string

const LanguageToggleComponent = (props: LanguageToggleProps) => {
  const [language, setLanguage] = useState<language>(props.language)

  const onSetLanguage = () => {
    let lang = language
    if (lang === 'en') {
      lang = 'no'
    } else {
      lang = 'en'
    }
    setLanguage(lang)
    props.translate(lang)
  }

  return (
    <PrimaryButton onClick={onSetLanguage} Icon={GlobeAltIcon}>
      <span className="font-semibold">{language.toUpperCase()}</span>
    </PrimaryButton>
  )
}

export default LanguageToggleComponent
