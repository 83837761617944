import { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'
import PreviousFilesList from './PreviousFilesList'
import { toBase64 } from './utils'
import { InvoiceType } from '../types'
import FileTypeSelect from './FileTypeSelect'

type FileType = {
  [key: string]: {
    base64: string
    invoiceType: InvoiceType
    dataUrl: string
  }
}

const FileManager = ({ enabled, selectedFiles, onSubmit }: any) => {
  const { t } = useTranslation()
  const [fileTypes, setFileTypes] = useState<FileType>({})

  const { isDragActive, acceptedFiles, getRootProps, getInputProps } =
    useDropzone({
      accept: '.jpg,.png,.pdf',
      onDrop: () => handleSubmit(),
    })

  const previousFiles = Array.from(selectedFiles)

  const handleSubmit = () => {
    const results = Object.keys(fileTypes).map((f: any) => ({
      fileName: f,
      base64: fileTypes[f].base64,
      invoiceType: fileTypes[f].invoiceType,
    }))

    onSubmit(results)
  }

  useEffect(() => {
    async function go() {
      let tmpFiles: any = {}
      for (let i = 0; i < acceptedFiles.length; i++) {
        let file = acceptedFiles[i]
        if (file != null) {
          tmpFiles[file.name] = {
            dataUrl: URL.createObjectURL(file),
            base64: await toBase64(file),
            invoiceType: '',
          }
        }
      }

      setFileTypes(tmpFiles)
    }

    go()
  }, [setFileTypes, acceptedFiles])

  const onChooseFileType = (filename: any, invoiceType: InvoiceType) => {
    let clone: any = { ...fileTypes }
    clone[filename].invoiceType = invoiceType
    setFileTypes(clone)

    handleSubmit()
  }

  const className = useMemo(
    () =>
      `${
        isDragActive ? 'border-blue-400 bg-blue-50' : 'border-gray-200'
      } border-2 border-dashed rounded-sm flex flex-col justify-center cursor-pointer sm:h-28`,
    [isDragActive]
  )

  return (
    <div className="w-full">
      <div {...getRootProps({ className })}>
        <input {...getInputProps()} />
        <div className="flex flex-col space-y-2 py-2 px-2 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-2">
          {previousFiles.length > 0 && (
            <PreviousFilesList files={previousFiles} />
          )}

          {/* Divider runs on its own here, to only show if we have both old & new files */}
          {previousFiles.length > 0 && Object.keys(fileTypes).length > 0 && (
            <div className="h-full w-1 rounded-sm bg-gray-300" />
          )}

          {Object.keys(fileTypes).length > 0 && (
            <div className="flex flex-row items-center space-x-2">
              {Object.keys(fileTypes).map((filename: string, i) => (
                <div className="w-full sm:col-span-2 sm:w-auto">
                  <FileTypeSelect
                    key={filename}
                    filename={filename}
                    number={i + 1}
                    dataUrl={fileTypes[filename].dataUrl}
                    onChange={(value: InvoiceType) =>
                      onChooseFileType(filename, value)
                    }
                    value={fileTypes[filename].invoiceType.toString()}
                  />
                </div>
              ))}
            </div>
          )}
        </div>

        {previousFiles.length === 0 && Object.keys(fileTypes).length === 0 && (
          <p className="text-center font-semibold text-gray-700">
            <span>
              {t('objects:uploadInvoiceView.dropFiles')}{' '}
              <span className="text-lime">
                {t('objects:uploadInvoiceView.clickToBrowse')}
              </span>
            </span>
            <br />
          </p>
        )}
      </div>
    </div>
  )
}

export default FileManager
