import { useTranslation } from 'react-i18next'
import cn from 'classnames'

export default function FrequencyChanger({
  freqOptions,
  freqChangeHandler,
  freq,
}: {
  freqOptions: string[]
  freqChangeHandler: Function
  freq: string
}) {
  const { t } = useTranslation()
  return (
    <>
      <div className="w-full sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a frequency
        </label>
        <select
          onChange={(e) => {
            freqChangeHandler(e.target.value)
          }}
          id="tabs"
          name="tabs"
          className="focus:outline-none text-basesm:text-sm block w-full rounded-md border-gray-300 py-2 pl-3 pr-10"
          defaultValue={freqOptions.find((f) => f === freq)}
        >
          {freqOptions.map((value: string, index: number) => {
            return (
              <option key={value} value={value}>
                {`consumption:graph.${value}`}
              </option>
            )
          })}
        </select>
      </div>
      <div className="my-4 hidden justify-center sm:flex">
        <div className="mx-auto flex flex-row rounded-xl border border-gray-600 text-gray-800 ">
          {freqOptions.map((value: string, index: number) => (
            <div
              role="button"
              onClick={() => freqChangeHandler(value)}
              key={index}
              className={cn(
                'px-4 py-2 hover:bg-gray-100',
                freq === value &&
                  'border-l border-r border-gray-600 bg-gray-100',
                index === 0 && 'rounded-l-xl border-l-0',
                index === freqOptions.length - 1 && 'rounded-r-xl border-r-0'
              )}
            >
              {t(`consumption:graph.${value}`)}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
