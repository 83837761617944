/**
 * Calculate the Average
 */

import { Invoice, NettGross } from '../types'
import { formatNumberInput } from '../utils'

const calculateAverage = (invoice: Invoice) => {
  if (!invoice.quantity || !invoice.elecInvoice) return 0

  return formatNumberInput(invoice.elecInvoice) / invoice.quantity
}

const calculateAverageGross = (invoice: Invoice) => {
  if (!invoice.quantity || !invoice.elecInvoiceGross) return 0

  return formatNumberInput(invoice.elecInvoiceGross) / invoice.quantity
}

export const average = (invoice: Invoice): NettGross => ({
  nett: calculateAverage(invoice),
  gross: calculateAverageGross(invoice),
})
