import { useMemo, useState } from 'react'
import { Table } from 'src/components/Table'
import { useTranslation, withTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import APIClient from '../utils/apiClient'
import { TextPlaceholder } from 'src/components/TextPlaceholder'
import { useNavigate, NavLink } from 'react-router-dom'
import FormatNumber from '../components/FormatNumber'
import { getFooterForColumn } from '../utils/helpers/helpers'
import dayjs from 'dayjs'
import { useAppState } from 'src/context/appstate'
import NoMeterPlaceholder from 'src/components/NoMeterPlaceholder'
import GridRentModal from 'src/components/Consumption/GridRentModal'
import { useCustomer } from 'src/context/customer'

const Meters = () => {
  const { t } = useTranslation()
  const { showNett } = useAppState()
  const navigate = useNavigate()
  const [gridRentObjectId, setGridRentObjectId] = useState<string>('')
  const [showGridRentModal, setShowGridRentModal] = useState<boolean>(false)
  const [gridRentFromDate, setGridRentFromDate] = useState<string>('')
  const [gridRentToDate, setGridRentToDate] = useState<string>('')
  const customer = useCustomer()

  const { isLoading, data } = useQuery(['customerMeters', customer], () =>
    APIClient.getCustomerMeteringPointOverview(customer?.customerId)
  )

  const gridRentClickHandler = (objectId: string) => {
    setGridRentObjectId(objectId)
    setShowGridRentModal(true)
    setGridRentFromDate(
      dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
    )
    setGridRentToDate(dayjs().startOf('month').format('YYYY-MM-DD'))
  }

  const rowClickHandler = (objectId: string) => {
    navigate(`/App/Meters/${objectId}/Consumption`)
  }

  const columns = useMemo(
    () => [
      {
        Header: t('home:tableHeaders.objectName'),
        accessor: 'objectName',
        sortType: 'basic',
        Loader: (props: any) => <TextPlaceholder min={12} max={30} />,
        Cell: (props: any) => {
          const id = props.row.original.objectId
          return (
            <NavLink
              id={`meter-${id}`}
              className="nav-meter hover:underline"
              to={{
                pathname: `/App/Meters/${id}/Consumption`,
                // state: { meter: props.row.original, query: false },
              }}
            >
              {props.cell.value}
            </NavLink>
          )
        },
        Footer: ({ data }: any) => {
          return (
            <>
              <p className="mt-4 text-base font-bold">
                {`${data.length} ${t('objects:meters')}`}
              </p>
            </>
          )
        },
      },
      {
        Header: t('home:tableHeaders.description'),
        accessor: 'description',
        sortType: 'basic',
        Loader: (props: any) => <TextPlaceholder min={5} max={12} />,
        Cell: (props: any) => {
          const id = props.row.original.objectId
          return (
            <NavLink
              id={`meter-${id}`}
              className="nav-meter hover:underline"
              to={{
                pathname: `/App/Meters/${id}/Consumption`,
                // state: { meter: props.row.original, query: false },
              }}
            >
              {props.cell.value}
            </NavLink>
          )
        },
      },
      {
        Header: t('home:tableHeaders.meteringPointId'),
        accessor: 'meteringPointId',
        sortType: 'basic',
        Loader: (props: any) => <TextPlaceholder min={5} max={12} />,
        Cell: (props: any) => {
          const id = props.row.original.objectId
          return (
            <NavLink
              id={`meter-${id}`}
              className="nav-meter hover:underline"
              to={{
                pathname: `/App/Meters/${id}/Consumption`,
                // state: { meter: props.row.original, query: false },
              }}
            >
              {props.cell.value}
            </NavLink>
          )
        },
      },

      {
        Header: t('home:tableHeaders.address'),
        accessor: 'address',
        Loader: (props: any) => <TextPlaceholder min={5} max={12} />,
        sortType: 'basic',
      },
      {
        Header: `${t('home:tableHeaders.consumption')} (kWh)`,
        accessor: 'consumption',
        sortType: 'basic',
        className: 'text-right',
        Loader: (props: any) => <TextPlaceholder min={5} max={12} />,
        Cell: (props: any) => {
          return (
            <p
              className="cursor-pointer tracking-wider hover:underline"
              onClick={() => rowClickHandler(props.row.original.objectId)}
            >
              <FormatNumber number={props.cell.value} decimals={1} />
            </p>
          )
        },
        Footer: ({ data }: any) => {
          var num = getFooterForColumn('sum', data, 'consumption')
          return (
            <>
              <p className="mt-4 px-2 text-right text-base font-bold tracking-widest">
                <FormatNumber number={num} decimals={1} />
              </p>
            </>
          )
        },
      },
      {
        Header: t('home:tableHeaders.spotCost'),
        accessor: showNett ? 'spotCost' : 'spotGrossCost',
        sortType: 'basic',
        className: 'text-right',
        Loader: (props: any) => <TextPlaceholder min={5} max={12} />,
        Cell: (props: any) => {
          return (
            <p
              className="cursor-pointer tracking-wider hover:underline"
              onClick={() => rowClickHandler(props.row.original.objectId)}
            >
              <FormatNumber number={props.cell.value} decimals={1} />
            </p>
          )
        },
        Footer: ({ data }: any) => {
          const selector = showNett ? 'spotCost' : 'spotGrossCost'
          const num = getFooterForColumn('sum', data, selector)
          return (
            <>
              <p className="mt-4 px-2 text-right text-base font-bold tracking-widest">
                <FormatNumber number={num} decimals={2} />
              </p>
            </>
          )
        },
      },
      {
        Header: t('home:tableHeaders.gridCost'),
        accessor: showNett ? 'gridCost' : 'gridGrossCost',
        sortType: 'basic',
        className: 'text-right',
        Loader: (props: any) => <TextPlaceholder min={5} max={12} />,
        Cell: (props: any) => {
          return (
            <p
              onClick={() => gridRentClickHandler(props.row.original.objectId)}
              className="cursor-pointer text-right tracking-wider hover:underline"
            >
              <FormatNumber number={props.cell.value} decimals={2} />
            </p>
          )
        },
        Footer: ({ data }: any) => {
          const selector = showNett ? 'gridCost' : 'gridGrossCost'
          const num = getFooterForColumn('sum', data, selector)
          return (
            <>
              <p className="mt-4 px-2 text-right text-base font-bold tracking-widest">
                <FormatNumber number={num} decimals={2} />
              </p>
            </>
          )
        },
      },
      {
        Header: t('home:tableHeaders.accessType'),
        accessor: 'accessType',
        className: 'pl-4',
        Loader: (props: any) => <TextPlaceholder min={5} max={12} />,
        sortType: 'basic',
        Cell: (props: any) => {
          return <p>{t(`objects:accessType.${props.cell.value}`)}</p>
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showNett, t]
  )

  return (
    <div className="container flex w-full flex-row justify-center">
      <div className="flex w-full flex-col">
        <div className="max-w-full">
          <div className="px-2 pb-3 text-3xl">
            {t('objects:customerMeters')}
          </div>
          {/* {isLoading && (
              <div className="flex items-center justify-center w-full h-full">
                <Spinner />
              </div>
            )} */}
          {data?.length === 0 ? (
            <NoMeterPlaceholder />
          ) : (
            <Table
              loading={isLoading}
              loadingPlaceholderRows={5}
              columns={columns}
              data={data}
              enableSorting={true}
              onRowClick={(rowData: any) => null}
              tableStyleOverride="table-fixed md:table-auto block md:table absolute md:relative"
              tdStyleOverride="px-3 first:max-w-lg first:min-h-full border-gray-100 border-t-2 border-b-2 text-gray-600"
              thStyleOverride="text-base text-gray-500 font-medium"
            />
          )}
          <GridRentModal
            queryType="meter"
            objectId={gridRentObjectId}
            isOpen={showGridRentModal}
            onClose={() => setShowGridRentModal(false)}
            start={gridRentFromDate}
            end={gridRentToDate}
            aggregationType="month"
          />
        </div>
      </div>
    </div>
  )
}

export default withTranslation('no')(Meters)
