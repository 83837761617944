import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { FreqType } from 'src/types'
import useDateRange from './useDateRange'
require('dayjs/locale/nb')

type Props = {
  freq?: FreqType
  from?: string
  to?: string
  is_power_factor?: boolean
}

export default function useDateChanger(props?: Props) {
  const {
    i18n: { language },
  } = useTranslation()

  const defaultFromDay: string = dayjs()
    .startOf('month')
    .subtract(1, 'month')
    .format('YYYY-MM-DD')
  const defaultToDay: string = dayjs(defaultFromDay)
    .add(1, 'month')
    .startOf('month')
    .format('YYYY-MM-DD')

  const defaultFromMonth: string = dayjs().startOf('year').format('YYYY-MM-DD')
  const defaultToMonth: string = dayjs()
    .add(1, 'year')
    .startOf('year')
    .format('YYYY-MM-DD')

  const dateRange = useDateRange({
    defaultFreq: props?.freq || props?.is_power_factor ? 'day' : 'month',
    defaultFrom:
      props?.from || props?.is_power_factor ? defaultFromDay : defaultFromMonth,
    defaultTo:
      props?.to || props?.is_power_factor ? defaultToDay : defaultToMonth,
  })

  const freq = props?.freq ?? dateRange.freq
  const from = props?.from ?? dateRange.from
  const to = props?.to ?? dateRange.to

  const { dateDisplay, disablePrev, disableNext } = useMemo(() => {
    const loc = language === 'no' ? 'nb' : 'en'

    if (freq === 'year') {
      return {
        dateDisplay: 'All',
        disablePrev: true,
        disableNext: true,
      }
    } else if (freq === 'month') {
      return {
        dateDisplay: dayjs(from).format('YYYY'),
        disablePrev: dayjs(from)
          .subtract(1, 'year')
          .isBefore(dayjs('2020-01-01')),
        disableNext: dayjs(to).add(1, 'day').isAfter(dayjs()),
      }
    } else if (freq === 'day') {
      return {
        dateDisplay: dayjs(from).locale(loc).format('MMMM YYYY'),
        disablePrev: dayjs(from)
          .subtract(1, 'month')
          .isBefore(dayjs('2020-01-01')),
        disableNext: dayjs(to).add(1, 'day').isAfter(dayjs()),
      }
    } else {
      const fmt = loc === 'nb' ? 'DD.MM.YYYY' : 'YYYY-MM-DD'
      return {
        dateDisplay: dayjs(from).format(fmt),
        disablePrev: dayjs(from)
          .subtract(1, 'day')
          .isBefore(dayjs('2020-01-01')),
        disableNext: dayjs(to).add(1, 'day').isAfter(dayjs()),
      }
    }
  }, [freq, from, to, language])

  return {
    dateDisplay,
    disablePrev,
    disableNext,
  }
}
