import { PropsWithChildren, useMemo } from 'react'
import { Column, useExpanded, useSortBy, useTable } from 'react-table'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'
import cn from 'classnames'

export type TableProps = {
  onRowClick?: any
  data: Array<any> | undefined
  columns: Array<Column>
  enableSorting?: boolean
  hideHeaders?: boolean
  initialState?: any
  loading?: boolean
  loadingPlaceholderRows?: number
  trStyleOverride?: string | undefined
  tdStyleOverride?: string | undefined
  thStyleOverride?: string | undefined
  tableStyleOverride?: string | undefined
}

export function Table(props: TableProps) {
  const data = useMemo(() => props.data || [1, 2, 3], [props.data])
  const columns = useMemo(() => props.columns, [props.columns])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    footerGroups,
    prepareRow,
  } = useTable(
    // @ts-ignore
    {
      columns,
      data,
      disableSortBy: !props.enableSorting,
      initialState: props.initialState,
    },
    useSortBy,
    useExpanded
  )

  return (
    <TableWrapper>
      <table
        // className={`min-w-full divide-y divide-gray-300 ${props.tableStyleOverride}`}
        className={`min-w-full divide-y divide-gray-200`}
        {...getTableProps()}
      >
        {/* <thead>
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
            >
              Name
            </th>
            <th
              scope="col"
              className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
            >
              Title
            </th>
            <th
              scope="col"
              className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
            >
              Email
            </th>
            <th
              scope="col"
              className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
            >
              Role
            </th>
            <th
              scope="col"
              className="relative py-3.5 pl-3 pr-4 sm:pr-6 md:pr-0"
            >
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead> */}
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column, i) => (
                    // Apply the header cell props
                    <th // @ts-ignore
                      // className={`py-4 px-4 pl-0 text-left first:px-0 ${props.thStyleOverride} ${column.className}`}
                      className={cn(
                        'whitespace-nowrap py-3.5 text-left text-sm font-semibold',
                        i === 0 ? 'pl-4 pr-3 sm:pl-6 md:pl-0' : 'px-3',
                        props.thStyleOverride,
                        // @ts-ignore
                        column.className
                      )}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {
                        // Render the header
                        column.render('Header')
                      }{' '}
                      <span>
                        {' '}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ChevronUpIcon className="-mt-0.5 inline h-6 w-6 text-gray-700" />
                          ) : (
                            <ChevronDownIcon className="-mt-0.5 inline h-6 w-6 text-gray-700" />
                          )
                        ) : (
                          ''
                        )}{' '}
                      </span>
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}

        <tbody className="divide-y divide-gray-200" {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row)
              return (
                // Apply the row props
                <tr className={props.trStyleOverride} {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell, i) => (
                      <td
                        onClick={() =>
                          !props.loading ? props.onRowClick(row.original) : null
                        } // @ts-ignore
                        // className={`whitespace-nowrap border-t-2 border-b-2 border-gray-100 py-2 pl-0 text-left first:min-h-full first:max-w-lg ${props.tdStyleOverride} ${cell.column.className}`}
                        className={cn(
                          'whitespace-nowrap py-2 text-sm',
                          i === 0
                            ? 'pl-4 pr-3 font-medium text-gray-900 sm:pl-6 md:pl-0'
                            : 'px-3 text-gray-800',
                          props.tdStyleOverride,
                          // @ts-ignore
                          cell.column.className
                        )}
                        {...cell.getCellProps()}
                      >
                        {
                          // Render the cell contents
                          props.loading
                            ? cell.render('Loader')
                            : cell.render('Cell')
                        }
                      </td>
                    ))
                  }
                </tr>
              )
            })
          }
        </tbody>

        <tfoot>
          {footerGroups.map((group) => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map((column, i) => (
                <td
                  className={cn(
                    'whitespace-nowrap py-2 text-sm',
                    i === 0
                      ? 'pl-4 pr-3 font-medium text-gray-500 sm:pl-6 md:pl-0'
                      : 'px-3 text-gray-500'
                    // props.tdStyleOverride
                    // @ts-ignore
                    // cell.column.className
                  )}
                  {...column.getFooterProps()}
                >
                  {!props.loading && column.render('Footer')}
                </td>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </TableWrapper>
  )
}

function TableWrapper({ children }: PropsWithChildren<unknown>) {
  return (
    <div className="px-4 sm:px-6">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          {children}
        </div>
      </div>
    </div>
  )
}
