import { useMemo, useState } from 'react'
import { Card } from 'src/components/Card'
import { Table } from 'src/components/Table'

interface Step6Props {
  currentStep: string
  nextStep: (next: any) => void
  children: any
}

interface Step6State {
  otherContactAddress: boolean
}

const Step6 = (props: Step6Props) => {
  const initialState = {
    otherContactAddress: false,
  }
  const [state, setState] = useState<Step6State>(initialState)

  const setCheckBox = () => {
    setState((prevState) => ({
      ...prevState,
      otherContactAddress: !state.otherContactAddress,
    }))
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Navn',
        accessor: 'Navn',
        sortType: 'basic',
      },
      {
        Header: 'Forretningsadresse',
        accessor: 'Forretningsadresse',
        sortType: 'basic',
      },
      {
        Header: 'Postadresse',
        accessor: 'Postadresse',
        sortType: 'basic',
      },
    ],
    []
  )

  const meterColumns = useMemo(
    () => [
      {
        Header: 'Type',
        accessor: 'Type',
      },
      {
        Header: 'Poststed',
        accessor: 'Poststed',
      },
      {
        Header: 'Postadresse',
        accessor: 'Postadresse',
      },
      {
        Header: 'Netteier',
        accessor: 'Netteier',
      },
    ],
    []
  )

  const onFormSubmit = () => {}

  return (
    <Card className="m-4">
      <form onSubmit={() => onFormSubmit()}>
        <div className="flex">
          <div className=" m-4 w-full justify-center">
            <div className="block w-full">
              <h1 className="text-center text-3xl font-semibold">Enroll</h1>
            </div>
            <div className="block">
              <div className="form-group my-4 w-full">
                <div className="my-4 text-base font-semibold">Org.nummer</div>
              </div>
              <div className="flex w-full justify-center">
                <div className="col-4 my-4 w-full md:w-1/5">
                  <div className="m-2 w-52 w-full">
                    <input
                      type="text"
                      id="textInputMeteringPointDesc"
                      className="w-full rounded-lg border p-2  text-sm"
                      placeholder="Valgfri beskrivelse (Adresse, navn, firma)"
                    />
                  </div>
                  <div className="m-2 w-52 w-full">
                    <input
                      type="text"
                      id="textInputMeteringPointDesc"
                      className="w-full rounded-lg border p-2 text-sm"
                      placeholder="Org nr/Navn"
                    />
                  </div>
                </div>
              </div>
              <div className="my-4 flex w-full justify-center">
                <Table
                  columns={columns}
                  data={[]}
                  onRowClick={() => {
                    return
                  }}
                />
              </div>
              <div className="flex w-full justify-center">
                <div className="form-group">
                  <div className="my-4 text-base font-semibold">
                    Organisasjon
                  </div>
                  <fieldset>
                    <fieldset className="form-group">
                      <div className="my-4 text-base font-semibold">
                        Firmaopplysninger
                      </div>
                      <div className="form-group row">
                        <div className="col">
                          <div className="m-2 w-52 w-full">
                            <input
                              type="text"
                              id="textInputMeteringPointDesc"
                              className="w-full rounded-lg border p-2 text-sm"
                              placeholder="Firmanavn"
                            />
                          </div>
                        </div>

                        <div className="m-2 w-52 w-full">
                          <input
                            type="text"
                            id="textInputMeteringPointDesc"
                            className="w-full rounded-lg border p-2 text-sm"
                            placeholder="Organisasjonsnummer"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="m-2 w-52 w-full">
                          <input
                            type="text"
                            id="textInputMeteringPointDesc"
                            className="w-full rounded-lg border p-2 text-sm"
                            placeholder="Hoved telefonnummer"
                          />
                        </div>

                        <div className="m-2 w-52 w-full">
                          <input
                            type="text"
                            id="textInputMeteringPointDesc"
                            className="w-full rounded-lg border p-2 text-sm"
                            placeholder="Firmaets e-postadresse"
                          />
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className="form-group">
                      <div className="my-4 text-base font-semibold">
                        Brukerinformasjon
                      </div>
                      <div className="form-group row">
                        <div className="col">
                          <div className="m-2 w-52 w-full">
                            <input
                              type="text"
                              id="textInputMeteringPointDesc"
                              className="w-full rounded-lg border p-2 text-sm"
                              placeholder="Fornavn"
                            />
                          </div>
                        </div>
                        <div className="m-2 w-52 w-full">
                          <input
                            type="text"
                            id="textInputMeteringPointDesc"
                            className="w-full rounded-lg border p-2 text-sm"
                            placeholder="Etternavn"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="m-2 w-52 w-full">
                          <input
                            type="text"
                            id="textInputMeteringPointDesc"
                            className="w-full rounded-lg border p-2 text-sm"
                            placeholder="Mobiltelefonnummer"
                          />
                        </div>
                        <div className="m-2 w-52 w-full">
                          <input
                            type="text"
                            id="textInputMeteringPointDesc"
                            className="w-full rounded-lg border p-2 text-sm"
                            placeholder="E-postadresse"
                          />
                        </div>
                      </div>
                    </fieldset>
                    <fieldset id="contact-person-fieldset" className="">
                      <div className="m-2-form-label pt-0">
                        <div className="row">
                          <div className="my-4 text-base font-semibold">
                            Annen kontaktperson
                          </div>
                          <div className="form-check m-2">
                            <input
                              type="checkbox"
                              onChange={() => setCheckBox()}
                              className="ml-1"
                            />
                            <label className="ml-2">
                              Kryss av her for å legge til en annen
                              kontaktperson
                            </label>
                          </div>
                        </div>
                      </div>

                      {state.otherContactAddress && (
                        <>
                          <div className="form-group flex">
                            <div className="m-2 w-52">
                              <input
                                type="text"
                                className="w-full rounded-lg border border p-2 text-sm"
                                placeholder="Fornavn"
                                required
                              />
                            </div>
                            <div className="m-2 w-52">
                              <input
                                type="text"
                                className="w-full rounded-lg border border p-2 text-sm"
                                placeholder="Etternavn"
                                required
                              />
                            </div>
                          </div>
                          <div className="form-group flex">
                            <div className="m-2 w-52">
                              <input
                                type="number"
                                className="w-full rounded-lg border border p-2 text-sm"
                                placeholder="Mobiltelefonnummer"
                                required
                              />
                            </div>
                            <div className="m-2 w-52">
                              <input
                                type="text"
                                className="w-full rounded-lg border border p-2 text-sm"
                                placeholder="E-postadresse"
                                required
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </fieldset>
                    <fieldset className="form-group">
                      <div className="row">
                        <div className="col">
                          <fieldset>
                            <div className="my-4 text-base font-semibold">
                              Postadresse
                            </div>
                            <div className="">
                              <div className="m-2 w-52 w-full">
                                <input
                                  type="text"
                                  className="w-full rounded-lg border border p-2 text-sm"
                                  placeholder="C/O"
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="m-2 w-52 w-full">
                                <input
                                  type="text"
                                  className="w-full rounded-lg border border p-2 text-sm"
                                  placeholder="Adresse"
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="m-2 w-52 w-full">
                                <input
                                  type="text"
                                  className="w-full rounded-lg border border p-2 text-sm"
                                  placeholder="Postnummer"
                                  required
                                />
                              </div>
                              <div className="m-2 w-52 w-full">
                                <input
                                  type="text"
                                  className="w-full rounded-lg border border p-2 text-sm"
                                  placeholder="Poststed"
                                  required
                                />
                              </div>
                            </div>
                          </fieldset>
                        </div>
                        <div className="col">
                          <fieldset className="form-group">
                            <div className="my-4 text-base font-semibold">
                              Besøkadresse
                            </div>

                            <div className="m-2 w-52 w-full">
                              <input
                                type="text"
                                className="w-full rounded-lg border border p-2 text-sm"
                                placeholder="C/O"
                                required
                              />
                            </div>
                            <div className="m-2 w-52 w-full">
                              <input
                                type="text"
                                className="w-full rounded-lg border border p-2 text-sm"
                                placeholder="Adresse"
                                required
                              />
                            </div>

                            <div className="form-group row">
                              <div className="m-2 w-52 w-full">
                                <input
                                  type="text"
                                  className="w-full rounded-lg border border p-2 text-sm"
                                  placeholder="Postnummer"
                                  required
                                />
                              </div>
                              <div className="m-2 w-52 w-full">
                                <input
                                  type="text"
                                  className="w-full rounded-lg border border p-2 text-sm"
                                  placeholder="Poststed"
                                  required
                                />
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                      <div className="form-group row">
                        <Table
                          data={[]}
                          columns={columns}
                          onRowClick={() => {
                            return
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <fieldset className="form-group">
                          <div className="my-4 text-2xl font-semibold">
                            Faktura
                          </div>
                          <fieldset className="form-group">
                            <div className="my-4 text-base font-semibold">
                              Fakturainformasjon
                            </div>
                            <div className="m-2 w-52 w-full">
                              <input
                                type="email"
                                className="w-full rounded-lg border border p-2 text-sm"
                                placeholder="E-postadresse for faktura"
                                required
                              />
                            </div>
                            <div className="m-2 w-52 w-full">
                              <input
                                type="text"
                                className="w-full rounded-lg border border p-2 text-sm"
                                placeholder="Fakturaadresse"
                                required
                              />
                            </div>
                            <div className="form-group flex">
                              <div className="m-2 w-52 w-full">
                                <input
                                  type="number"
                                  className="w-full rounded-lg border border p-2 text-sm"
                                  placeholder="Faktura postnummer"
                                  required
                                />
                              </div>
                              <div className="m-2 w-52 w-full">
                                <input
                                  type="number"
                                  className="w-full rounded-lg border border p-2 text-sm"
                                  placeholder="Faktura poststed"
                                  required
                                />
                              </div>
                            </div>
                            <div className="m-2 w-52 w-full">
                              <input
                                type="number"
                                className="w-full rounded-lg border border p-2 text-sm"
                                placeholder="PO / Bestillingsnummer"
                                required
                              />
                            </div>
                          </fieldset>
                          <div className="form-group row">
                            <div className="col">
                              <fieldset className="form-group">
                                <div className="my-4 text-base font-semibold">
                                  Fakturatype
                                </div>
                                <div className="form-group row">
                                  <div className="col">
                                    <input type="radio" name="" required />
                                    <label
                                      className="ml-2 text-sm"
                                      htmlFor="exampleRadios1"
                                    >
                                      Invoice Type
                                    </label>
                                  </div>
                                </div>
                              </fieldset>
                            </div>
                            <div className="col">
                              <fieldset className="form-group">
                                <div className="my-4 text-base font-semibold">
                                  Fakturahyppighet
                                </div>
                                <div className="form-group row">
                                  <div className="col">
                                    <input type="radio" name="" required />
                                    <label
                                      className="ml-2 text-sm"
                                      htmlFor="exampleRadios1"
                                    >
                                      InvoiceFrequency
                                    </label>
                                  </div>
                                </div>
                              </fieldset>
                            </div>
                            <div className="my-4 flex w-full">
                              <Table
                                columns={meterColumns}
                                data={[]}
                                onRowClick={() => {
                                  return
                                }}
                              />
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </fieldset>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div>
        {props.children}
      </form>
    </Card>
  )
}

export default Step6
