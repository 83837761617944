export const reformArray = (array: Array<any>) => {
  var newArray: Array<any> = []

  let group = array.reduce((r: any, a: any) => {
    r[a.label] = [...(r[a.label] || []), a]
    return r
  }, {})

  const values: any = Object.values(group)

  for (let i = 0; i < values.length; i++) {
    var object: any = {}
    for (let j = 0; j < values[i].length; j++) {
      object[values[i][j].key] = values[i][j].value
    }
    object.timestamp = values[i][0].label

    var clean = object.timestamp.toLowerCase().trim()
    if (clean !== 'timestamp' && clean.length > 0) {
      newArray.push(object)
    }
  }

  return newArray
}

export const formatTimestamp = (time: any) => {
  var timeToFormat = time
  timeToFormat = timeToFormat.split('00-00-00')[0]

  return timeToFormat
}

export const getFooterForColumn: any = (
  formula: string,
  data: Array<any>,
  key: string
) => {
  var num: number = 0

  if (data.length === 1) return data[0][key]

  switch (formula.toLowerCase()) {
    case 'average':
      var sum = 0

      for (let i = 0; i < data.length; i++) {
        var numberCheck =
          typeof data[i][key] === 'number'
            ? data[i][key]
            : parseFloat(data[i][key])
        sum = sum + numberCheck
      }
      num = sum / data.length
      break

    case 'sum':
      num = 0
      data.forEach((a: any) => {
        if (!isNaN(parseFloat(a[key]))) {
          num += +parseFloat(a[key])
        }
      })
      break
  }

  return num
}
