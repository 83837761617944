import { useQuery } from '@tanstack/react-query'
import { Outlet } from 'react-router-dom'
import APIClient from 'src/utils/apiClient'
import { Navbar, UserContainer } from 'src/components/Navbar'
import { getInitials } from 'src/utils'

export default function Root() {
  const { data, isLoading, error } = useQuery(
    ['account'],
    async (): Promise<UserContainer | undefined> => {
      return await getUserOrLogin()
    }
  )

  if (error) return <p>Error loading user. Check console.</p>

  return (
    <>
      <Navbar user={data} isLoading={isLoading} />
      <div className="flex flex-row justify-center">
        <Outlet />
      </div>
    </>
  )
}

const getUserOrLogin = async (): Promise<any> => {
  try {
    let user = await APIClient.getAccount()

    return {
      ...user,
      initials: getInitials(user.name),
    }
  } catch {
    window.location.href = `/Login?redirect_to=${encodeURIComponent(
      window.location.pathname
    )}`
  }
}
