import { useState } from 'react'
import { Card } from 'src/components/Card'

interface Step7Props {
  currentStep: string
  prevStep: (prevStep: any) => void
}

interface Step7State {
  termsAgree: boolean
}

const Step7 = (props: Step7Props) => {
  const initialState = {
    termsAgree: false,
  }
  const [state, setState] = useState<Step7State>(initialState)

  const onWizardSubmission = () => {}

  const onClickPrevStep = (step: any) => {
    props.prevStep(parseInt(props.currentStep) - 1)
  }

  const onSetTerms = () => {
    setState((prevState) => ({
      ...prevState,
      termsAgree: !state.termsAgree,
    }))
  }

  return (
    <>
      <div id="organisations">
        <Card className="m-4">
          <form onSubmit={() => onWizardSubmission()}>
            <div className="m-4">
              <input
                type="checkbox"
                onClick={() => onSetTerms()}
                className="mr-2"
                id="accept"
              />
              <label className="form-check-label" htmlFor="accept">
                Jeg har lest og godtar overnevnte vilkår, og bekrefter at jeg
                samtykker i vilkårene for bruk av tjenestene.
              </label>
            </div>

            <div className="mt-2">
              <button
                value="previous"
                name="btnSubmit"
                className="ml-4 rounded-3xl border bg-red-400 px-4 py-3 font-semibold text-white"
                onClick={(step: any) => onClickPrevStep(step)}
              >
                Avbryt
              </button>
              <button
                value="previous"
                name="btnSubmit"
                className="ml-4 rounded-3xl border bg-blue-400 px-4 py-3 font-semibold text-white"
                onClick={(step: any) => onClickPrevStep(step)}
              >
                Tilbake
              </button>
              {!state.termsAgree && (
                <button
                  id="submitbtn"
                  type="submit"
                  value="next"
                  name="btnSubmit"
                  className="ml-4 rounded-3xl border bg-blue-400 px-4 py-3 font-semibold text-white"
                  disabled={true}
                >
                  Signer og send inn
                </button>
              )}
            </div>
          </form>
        </Card>
      </div>
    </>
  )
}

export default Step7
