import type { PropsWithChildren } from 'react'
import { ArrowLeftIcon } from '@heroicons/react/outline'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import Spinner from 'src/components/Spinner'
import apiClient from 'src/utils/apiClient'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton } from 'src/components/action'
import { useTranslation } from 'react-i18next'
import toast from 'react-hot-toast'

dayjs.extend(relativeTime)
dayjs.extend(utc)

const Settings = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { data: payloadData, isLoading: isPayloadLoading } = useQuery(
    ['settings'],
    () => apiClient.getUserSettings()
  )

  const { data: userData, isLoading: isUserDataLoading } = useQuery(
    ['me'],
    () => apiClient.getMe()
  )

  const copyToClipboard = () => {
    navigator.clipboard.writeText(payloadData.access_token)
    toast.success('Copied to clipboard')
  }

  const [val, setVal] = useState(
    dayjs(dayjs().diff(payloadData?.createdDate)).utc().format('HH:mm:ss')
  )

  useEffect(() => {
    let inter = setInterval(() => {
      setVal(
        dayjs(dayjs().diff(payloadData?.createdDate)).utc().format('HH:mm:ss')
      )
    }, 1000)

    return () => clearInterval(inter)
  }, [payloadData])

  const leftColW = 'md:w-48'

  return (
    <div className="w-full lg:mx-10 xl:mx-16 2xl:mx-20">
      <div className="flex flex-row items-center text-lg text-gray-800">
        <div className="flex cursor-pointer flex-col justify-center hover:text-gray-400">
          <div
            className="flex flex-col justify-center px-3 py-2 sm:px-5 "
            onClick={() => {
              navigate(-1)
            }}
          >
            <ArrowLeftIcon className="h-6" />
          </div>
        </div>
        {/* <div className="text-base" onClick={() => goBack()}>
            {t('home:backCustomer')}
          </div> */}
        <div className="ml-0 text-gray-800 sm:ml-8">
          <span className="text-lg font-medium sm:text-2xl sm:font-normal">
            Settings
          </span>

          {/* <span className="ml-2 text-xl text-gray-600">All settings</span> */}

          <p className="text-sm text-gray-600 sm:text-base">
            All user settings
          </p>

          {/* <TextPlaceholder
              className="rounded-full bg-gray-200"
              min={10}
              max={32}
            /> */}
        </div>
      </div>

      <div className="mt-4 flex flex-wrap py-4 px-3 pb-8 sm:px-5 md:mt-4">
        <div className="w-full flex-wrap break-words rounded-md bg-white py-4 pb-8 text-left md:px-8 md:shadow-lg">
          {isPayloadLoading && isUserDataLoading && (
            <div className="flex h-96 w-full items-center justify-center">
              <Spinner />
            </div>
          )}
          {payloadData && (
            <div className="flex flex-col space-y-3">
              <SettingItem
                label="Auth Authority"
                value={payloadData.provider}
              />
              <SettingItem label="Name" value={userData?.displayName} />

              <SettingItem label="Username" value={userData?.userName} />

              <SettingItem label="Email" value={userData?.eMail} />

              <SettingItem label="Mobile" value={userData?.mobilePhone} />

              <SettingItem label="Tenant" value={userData?.tenantName} />

              <SettingItem label="OID" value={payloadData.oid} />

              <SettingItem label="Sessions Time" value={val} />

              <SettingItem
                label="Expiry Date"
                value={dayjs(payloadData.expiryDate).format(
                  'YYYY-MM-DD HH:mm:ss'
                )}
              />

              <SettingItem label="Token" value={null}>
                <code
                  style={{ overflowWrap: 'anywhere', cursor: 'copy' }}
                  onClick={copyToClipboard}
                  className="w-full select-none rounded border bg-gray-100 p-2 text-sm hover:bg-gray-200"
                >
                  {payloadData.access_token}
                </code>
              </SettingItem>

              <div className="flex items-baseline">
                <p className={`${leftColW} font-semibold`}>O365 API:</p>

                <code className="pr-3">v{payloadData.o365ApiVersion}</code>

                <a href={payloadData.o365Api} target="_blank" rel="noreferrer">
                  {payloadData.o365Api}
                </a>
              </div>

              <div className="flex">
                <p className={`${leftColW} font-semibold`}>Solver API:</p>

                <code className="pr-3">v{payloadData.solverApiVersion}</code>

                <a
                  href={payloadData.solverApi}
                  target="_blank"
                  rel="noreferrer"
                >
                  {payloadData.solverApi}
                </a>
              </div>
            </div>
          )}
          <div className="mt-4">
            <PrimaryButton
              onClick={() => (window.location.pathname = '/api/logout')}
              className="bg-gray-400 text-black"
            >
              {t('global:logout')}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings

type ComponentProps = PropsWithChildren<{ label: string; value: any }>

const SettingItem = ({ label, value, children }: ComponentProps) => {
  return (
    <div className="flex space-x-2">
      <p className="font-semibold md:w-48">{label}</p>
      {value && <p>{value}</p>}
      {children}
    </div>
  )
}
