import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { Card } from 'src/components/Card'
import msSymbol from '../images/ms-symbollockup_signin_light.png'
import vibbsSymbol from '../images/log_in_with_vipps_rect_210_NO.png.png'

const Login = () => {
  const location = useLocation()
  const redirectToLogin = useCallback(
    (loginSource) => {
      window.location.href = `/Login/${loginSource + location.search}`
    },
    [location.search]
  )

  return (
    <div className="flex h-screen flex-row items-center justify-center">
      <Card className="md:rounded-3xl">
        <div className="px-16 py-12 text-center">
          <div className="mx-auto border-b border-gray-300 pb-4">
            <h1 className="text-3xl font-semibold text-gray-600 md:text-4xl">
              Strømradar Portal
            </h1>
          </div>

          <div className="mt-10">
            <button
              id="ms-login"
              className="btn"
              onClick={() => redirectToLogin('MsLogin')}
            >
              <img src={msSymbol} alt="microsoftLogin" />
            </button>
          </div>
          <div className="mt-4">
            <button
              id="vipps-login"
              className="btn"
              onClick={() => redirectToLogin('VippsLogin')}
            >
              <img src={vibbsSymbol} alt="vippsLogin" />
            </button>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default Login
