import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { ArrowLeftIcon } from '@heroicons/react/outline'
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom'
import { TextPlaceholder } from 'src/components/TextPlaceholder'
import { Tabs } from 'src/components/Tabs'
import { MeterContext } from 'src/context/meter'
import APIClient from 'src/utils/apiClient'
import Spinner from 'src/components/Spinner'

const MeterFrame = () => {
  const { t } = useTranslation()
  const objectId = useParams<'objectId'>().objectId
  const navigate = useNavigate()

  const {
    data: meter,
    isLoading,
    error,
  } = useQuery(['meter', objectId], () => APIClient.getMeter(objectId))

  const tabs = [
    { label: t('objects:consumption'), url: `/Consumption` },
    {
      label: t('global:reactive'),
      url: `/Reactive`,
    },
    { label: t('global:ETDiagram'), url: `/ET`, hiddenOnMobile: true },
    {
      label: t('global:ETFelt'),
      url: `/ETFelt`,
      hiddenOnMobile: true,
    },
    { label: t('global:peak'), url: `/Peak` },
    { label: t('objects:details'), url: `/Details` },
    { label: t('objects:budget'), url: `/Budget` },
    {
      label: t('objects:weatherStationsView.weatherStations'),
      url: `/WeatherStations`,
    },
    {
      label: t('objects:uploadInvoice'),
      url: `/UploadInvoice`,
    },
    {
      label: t('objects:spotPrice'),
      url: '/SpotPrice',
      hiddenOnMobile: true,
    },
  ]

  // const goBack = () => navigate(`/App/Customers/${meter?.customerId}`)

  const goBack = () => navigate(-1)

  // Redirect back to /App if objectId is not a number
  if (objectId && isNaN(parseInt(objectId))) {
    return <Navigate to="/App" replace />
  }

  if (error) {
    console.error(error)
    return <p>Error. Check console.</p>
  }

  return (
    <MeterContext.Provider value={meter}>
      <div className="w-full lg:mx-10 xl:mx-16 2xl:mx-20">
        <div className="flex flex-row items-center text-lg text-gray-800">
          <div className="flex cursor-pointer flex-col justify-center hover:text-gray-400">
            <div
              className="flex flex-col justify-center px-3 py-2 sm:px-5 "
              onClick={goBack}
            >
              <ArrowLeftIcon className="h-6" />
            </div>
          </div>
          {/* <div className="text-base" onClick={() => goBack()}>
            {t('home:backCustomer')}
          </div> */}
          <div className="ml-0 text-gray-800 sm:ml-8">
            {meter ? (
              <>
                <span className="text-lg font-medium sm:text-2xl sm:font-normal">
                  {meter.objectName}
                </span>
                {meter.description && (
                  <span className="ml-2 text-xl text-gray-600">
                    ({meter.description})
                  </span>
                )}
                <p className="text-sm text-gray-600 sm:text-base">
                  {t('objects:detailView.meteringPointId')}:{' '}
                  {meter.meteringPointId}
                </p>
              </>
            ) : (
              <TextPlaceholder
                className="rounded-full bg-gray-200"
                min={10}
                max={32}
              />
            )}
          </div>
        </div>

        {/* <div className="flex flex-row text-lg">
          <div className="flex flex-col justify-center" onClick={goBack}>
            <div className="flex flex-col justify-center px-4 py-2 text-gray-800 cursor-pointer hover:text-gray-400">
              <ArrowLeftIcon className="h-6" />
            </div>
          </div> */}

        {isLoading ? (
          <div className="flex h-96 w-full items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="flex w-full flex-wrap px-3 pb-8 sm:px-5">
            <div className="w-full">
              <Tabs tabs={tabs} prefix={`/App/Meters/${objectId}`} />
              <div className="relative break-words rounded-xl rounded-tl-none bg-white text-left md:mt-0 md:shadow-lg">
                <div className="tab-content tab-space flex-auto py-4 sm:px-4">
                  {isLoading && (
                    <div className="flex h-screen w-screen items-center justify-center">
                      <Spinner />
                    </div>
                  )}
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </MeterContext.Provider>
  )
}

export default MeterFrame
