import type { FreqType, GraphSeriesType } from 'src/types'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

export default function SeriesChanger({
  seriesChangeHandler,
  freq,
  seriesOptions,
  altSeriesOptions,
  graphSeries,
}: {
  seriesChangeHandler: Function
  freq?: FreqType
  seriesOptions: any
  altSeriesOptions?: any
  graphSeries: GraphSeriesType
}) {
  const { t } = useTranslation()

  const options =
    altSeriesOptions && (freq === 'day' || freq === 'hour')
      ? altSeriesOptions
      : seriesOptions

  return (
    <div className="hidden justify-center pb-2 sm:flex">
      <div className="mx-auto flex flex-row rounded-xl border border-gray-600 text-gray-800">
        {options.map((value: any, index: any) => (
          <div
            role="button"
            onClick={() => seriesChangeHandler(value)}
            key={index}
            className={cn(
              'px-4 py-2 hover:bg-gray-100',
              graphSeries === value &&
                'border-l border-r border-gray-600 bg-gray-100',
              index === 0 && 'rounded-l-xl border-l-0',
              index === options.length - 1 && 'rounded-r-xl border-r-0'
            )}
          >
            {t(`objects:tableModalHeaders.${value}NoUnit`)}
          </div>
        ))}
      </div>
    </div>
  )
}
