import dayjs from 'dayjs'
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear'
import isoWeek from 'dayjs/plugin/isoWeek'
import isLeapYear from 'dayjs/plugin/isLeapYear'

dayjs.extend(isoWeeksInYear)
dayjs.extend(isoWeek)
dayjs.extend(isLeapYear)

const toObject = (arr: Array<any>) => {
  let result: any = {}
  arr.forEach((a) => (result[a['timestamp']] = a))
  return result
}

const pad = (arr: Array<any>, freq: string) => {
  if (arr == null) return arr
  if (arr.length === 0) return arr

  const obj = toObject(arr)
  let result: any = {}

  if (freq === 'month') {
    if (Object.keys(obj).length === 12) return arr

    const year = dayjs(arr[0]['timestamp']).year()
    for (let i = 0; i < 12; i++) {
      const key = dayjs(`${year}-01-01T00:00:00`)
        .set('month', i)
        .format('YYYY-MM-DDTHH:mm:ss')
      result[key] = {
        timestamp: key,
        ...(obj[key] || {}),
      }
    }
    return [...Object.values(result)]
  } else if (freq === 'week') {
    const year = dayjs(arr[0]['timestamp']).year()
    const max = dayjs(arr[0]['timestamp']).isoWeeksInYear()

    if (Object.keys(obj).length === max) return arr
    for (let i = 1; i <= max; i++) {
      const key = dayjs(`${year}-01-01`)
        .isoWeek(i)
        .format('YYYY-MM-DDTHH:mm:ss')
      result[key] = {
        timestamp: key,
        ...(obj[key] || {}),
      }
    }
    return [...Object.values(result)]
  } else if (freq === 'day') {
    const year = dayjs(arr[0]['timestamp']).year()
    const month = dayjs(arr[0]['timestamp']).month() + 1
    const max = dayjs(arr[0]['timestamp']).daysInMonth()

    if (Object.keys(obj).length === max) return arr
    for (let i = 1; i <= max; i++) {
      const key = dayjs(`${year}-${month}-01T00:00:00`)
        .set('date', i)
        .format('YYYY-MM-DDTHH:mm:ss')
      result[key] = {
        timestamp: key,
        ...(obj[key] || {}),
      }
    }
    return [...Object.values(result)]
  } else if (freq === 'hour') {
    if (Object.keys(obj).length === 24) return arr

    const year = dayjs(arr[0]['timestamp']).year()
    const month = dayjs(arr[0]['timestamp']).month() + 1
    const day = dayjs(arr[0]['timestamp']).date()

    for (let i = 0; i < 24; i++) {
      const key = dayjs(`${year}-${month}-${day}T00:00:00`)
        .set('hour', i)
        .format('YYYY-MM-DDTHH:mm:ss')
      result[key] = {
        timestamp: key,
        ...(obj[key] || {}),
      }
    }
    return [...Object.values(result)]
  } else {
    return arr
  }
}

export { pad }
