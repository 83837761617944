import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Label,
  Line,
  TooltipProps,
  ComposedChart,
  Area,
} from 'recharts'
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'
import { MeteringPointPowerFactor } from 'src/generated/client'
import { FreqType } from 'src/types'
import { pad } from '../Consumption/utils'

const xAxisTickFormatter = (value: string, freq: FreqType, t: any) => {
  if (freq === 'year') {
    return dayjs(value).format('YYYY')
  } else if (freq === 'month') {
    return t(`global:shortMonths.${dayjs(value).format('MMM').toLowerCase()}`)
  } else if (freq === 'day') {
    return dayjs(value).format('D')
  } else if (freq === 'hour') {
    return dayjs(value).format('H')
  } else {
    return ''
  }
}

export const round = (value: any, decimals: number) =>
  Math.round(value * 10 ** decimals) / 10 ** decimals

export const PowerFactorGraph = ({
  data,
  freq,
  onGraphClick,
}: {
  data: MeteringPointPowerFactor[]
  freq: FreqType
  onGraphClick: (v: string) => void
}) => {
  const { t } = useTranslation()

  // const yLabelDeviationsLeft: string = t('powerFactor:powerYLabel')
  // const yLabelDeviationsRight: string = t('powerFactor:cosPhiYLabel')

  // scrap undefined or 0 values off
  // const graphData = data.filter(function (element) {
  //   return element !== undefined
  // })

  const graphData = useMemo(() => {
    return pad(data, freq)?.map(
      (item: MeteringPointPowerFactor, i: number) => ({
        timestamp: item.timestamp,
        plantId: item.objectId,
        // z: [
        //   item.z && item.p && item.q ? (item.p > item.q ? item.p : item.q) : 0,
        //   item.z,
        // ],
        // p: [item.p && item.q ? (item.p > item.q ? item.q : item.p) : 0, item.p],
        // q: item.q,
        z: item.z,
        p: item.p,
        q: item.q,
        cosPhi: item.cosPhi,
      })
    )
  }, [data, freq])

  return (
    <div className="mx-auto flex flex-col p-8 md:w-3/4 md:max-w-6xl ">
      <div className="w-full">
        <ResponsiveContainer width="100%" aspect={3}>
          <ComposedChart
            data={graphData}
            onClick={(graphData: any) => onGraphClick(graphData?.activeLabel)}
            margin={{
              top: 5,
              right: 50,
              left: 50,
              bottom: 30,
            }}
          >
            <defs>
              <linearGradient id="colorZ" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#939598" stopOpacity={0.6} />
                <stop offset="95%" stopColor="#939598" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorP" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#53C023" stopOpacity={0.6} />
                <stop offset="95%" stopColor="#53C023" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorQ" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#f09521" stopOpacity={0.6} />
                <stop offset="95%" stopColor="#f09521" stopOpacity={0} />
              </linearGradient>
            </defs>

            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="timestamp"
              tickFormatter={(value) => xAxisTickFormatter(value, freq, t)}
            ></XAxis>
            <YAxis
              yAxisId="left"
              orientation="left"
              tickFormatter={(value) => value.toLocaleString('NO')}
            >
              <Label
                value={t('powerFactor:powerYLabel').toString()}
                offset={10}
                angle={270}
                position="left"
                style={{ textAnchor: 'middle' }}
              />
            </YAxis>
            <YAxis
              yAxisId="right"
              orientation="right"
              tickFormatter={(value) => value.toLocaleString('NO')}
            >
              <Label
                value={t('powerFactor:cosPhiYLabel').toString()}
                offset={10}
                angle={270}
                position="right"
                style={{ textAnchor: 'middle' }}
              />
            </YAxis>
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              content={<PowerFactorTooltip freq={freq} />}
              wrapperStyle={{ outline: 'none' }}
            />

            <Area
              type="monotone"
              yAxisId="left"
              dataKey="z"
              stroke="#939598"
              fill="#939598"
              // fill="url(#colorZ)"
              // fillOpacity={1}
            />
            <Area
              type="monotone"
              yAxisId="left"
              dataKey="p"
              stroke="#53C023"
              fill="#53C023"
              // fill="url(#colorP)"
              // fillOpacity={1}
            />
            <Area
              type="monotone"
              yAxisId="left"
              dataKey="q"
              stroke="#f09521"
              fill="#f09521"
              // fill="url(#colorQ"
              // fillOpacity={1}
            />
            {/* <Bar yAxisId="left" dataKey="p" fill="#53C023" />
            <Bar yAxisId="left" dataKey="z" fill="#939598" />
            <Line
              yAxisId="left"
              type="monotone"
              dataKey="q"
              stroke="#f09521"
              strokeWidth={2}
            /> */}
            <Line
              yAxisId="right"
              type="monotone"
              dataKey="cosPhi"
              stroke="#000000"
              strokeWidth={2}
            />
          </ComposedChart>
        </ResponsiveContainer>
        <CustomLegend />
      </div>
    </div>
  )
}

export default PowerFactorGraph

const CustomLegend = () => {
  const { t } = useTranslation()

  return (
    <div className="mt-4 flex flex-row justify-center space-x-8">
      <div className="flex flex-row items-center">
        <div className="mr-2 h-3 w-3 rounded-full bg-graphSeries" />
        <p>{t('powerFactor:activePowerLegend')}</p>
      </div>
      <div className="flex flex-row items-center">
        <div className="mr-2 h-3 w-3 rounded-full bg-graphSeries-three" />
        <p>{t('powerFactor:apparentPowerLegend')}</p>
      </div>
      <div className="flex flex-row items-center">
        <div className="mr-2 h-3 w-3 rounded-full bg-graphSeries-four" />
        <p>{t('powerFactor:reactivePowerLegend')}</p>
      </div>
      <div className="flex flex-row items-center">
        <div className="mr-2 h-3 w-3 rounded-full bg-graphSeries-two" />
        <p>{t(`powerFactor:cosPhiLegend`)}</p>
      </div>
    </div>
  )
}

const PowerFactorTooltip = ({
  active,
  payload,
  freq,
}: TooltipProps<ValueType, NameType> & { freq: FreqType }) => {
  const { t } = useTranslation()

  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip rounded-md border border-gray-400 bg-white p-4 text-sm text-gray-800">
        {freq === 'year' ? (
          <p>{dayjs(payload[0].payload.timestamp).format('YYYY')}</p>
        ) : freq === 'month' ? (
          <p>
            {`${t(
              `global:shortMonths.${dayjs(payload[0].payload.timestamp)
                .format('MMM')
                .toLowerCase()}`
            )} ${dayjs(payload[0].payload.timestamp).format('YYYY')}`}
          </p>
        ) : freq === 'day' ? (
          <p>{dayjs(payload[0].payload.timestamp).format('DD.MM.YYYY')}</p>
        ) : freq === 'hour' ? (
          <p>
            {dayjs(payload[0].payload.timestamp).format('HH:mm DD.MM.YYYY')}
          </p>
        ) : null}

        <hr className="mt-1" />
        <div className="mt-1">
          {payload.map((entry, index) => (
            <p key={index} className="">
              {entry.name === 'p'
                ? t('powerFactor:activePowerTooltip')
                : entry.name === 'z'
                ? t('powerFactor:apparentPowerTooltip')
                : entry.name === 'q'
                ? t('powerFactor:reactivePowerTooltip')
                : entry.name === 'cosPhi'
                ? 'cosPhi'
                : ''}
              : {round(entry.value, 2).toLocaleString('NO')}{' '}
              {entry.name === 'p'
                ? 'kW'
                : entry.name === 'z'
                ? 'kVA'
                : entry.name === 'q'
                ? 'kVar'
                : ''}
            </p>
          ))}
        </div>
      </div>
    )
  }
  return null
}
