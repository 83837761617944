import NumberFormat from 'react-number-format'

interface FormatNumberProps {
  number: any
  decimals?: number
}

const FormatNumber = ({ number, decimals }: FormatNumberProps) => {
  if (number === null || isNaN(number)) {
    return <span>-</span>
  }

  let float = number

  if (typeof number !== 'number') {
    let parsedFloat = parseFloat(number)

    if (isNaN(parsedFloat)) {
      float = 0
    } else {
      float = parsedFloat
    }
  }

  return (
    <NumberFormat
      className="whitespace-nowrap"
      value={float.toFixed(decimals ?? 2)}
      thousandSeparator=" "
      displayType="text"
      decimalSeparator={','}
      isNumericString={true}
    />
  )
}

export default FormatNumber
