import { useCallback, useEffect, useMemo, useState } from 'react'
import { Table } from 'src/components/Table'
import { useTranslation, withTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import APIClient from '../utils/apiClient'
import { Card } from 'src/components/Card'
import { TextPlaceholder } from 'src/components/TextPlaceholder'
import { useNavigate } from 'react-router-dom'
import FormatNumber from '../components/FormatNumber'
import { getFooterForColumn } from '../utils/helpers/helpers'
import dayjs from 'dayjs'
import { useAppState } from 'src/context/appstate'
import NoMeterPlaceholder from 'src/components/NoMeterPlaceholder'
import GridRentModal from 'src/components/Consumption/GridRentModal'
import { CustomerMeteringPoint } from 'src/generated/client'

const CustomersPage = () => {
  const { t } = useTranslation()
  const { showNett } = useAppState()
  const navigate = useNavigate()
  const [gridRentCustomerID, setGridRentCustomerID] = useState<string>('')
  const [showGridRentModal, setShowGridRentModal] = useState<boolean>(false)
  const [gridRentFromDate, setGridRentFromDate] = useState<string>('')
  const [gridRentToDate, setGridRentToDate] = useState<string>('')

  const { isLoading, data } = useQuery(['customers'], () =>
    APIClient.getCustomerOverview()
  )
  const {
    isLoading: isCustomerMeteringPointLoading,
    data: customerMeteringPoint,
  } = useQuery(['customerMeteringPoints'], () =>
    APIClient.getCustomerMeteringPoint()
  )

  useEffect(() => {
    if (customerMeteringPoint?.length === 1) {
      if (customerMeteringPoint[0].objectIds?.length === 1) {
        navigate(
          `/App/Meters/${customerMeteringPoint[0].objectIds[0]}/Consumption`
        )
      } else {
        navigate(
          `/App/Customers/${customerMeteringPoint[0].customerId}/SumCompany`
        )
      }
    }
  }, [customerMeteringPoint, navigate])

  const gridRentClickHandler = (customerId: string) => {
    setGridRentCustomerID(customerId)
    setShowGridRentModal(true)
    setGridRentFromDate(
      dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
    )
    setGridRentToDate(dayjs().startOf('month').format('YYYY-MM-DD'))
  }

  const rowClickHandler = useCallback(
    (
      customerMeteringpoint: CustomerMeteringPoint[] | undefined,
      customerId: number
    ) => {
      const result = customerMeteringpoint?.find((obj) => {
        return obj.customerId === customerId
      })
      if (result) {
        if (result.objectIds?.length === 1) {
          navigate(`/App/Meters/${result.objectIds[0]}`)
        } else {
          navigate(`/App/Customers/${customerId}/SumCompany`)
        }
      }
    },
    [navigate]
  )

  const columns = useMemo(
    () => [
      //Customer Name
      {
        Header: t('home:tableHeaders.customerName'),
        accessor: 'customerName',
        sortType: 'basic',
        Loader: (props: any) => <TextPlaceholder min={12} max={30} />,
        Cell: (props: any) => {
          const id = props.row.original.customerId

          return (
            <p
              id={`meter-${id}`}
              className="nav-meter cursor-pointer hover:underline"
              onClick={() => rowClickHandler(customerMeteringPoint, id)}
            >
              {props.cell.value}
            </p>
          )
        },
        Footer: ({ data }: any) => {
          return (
            <>
              <p className="mt-4 text-base font-bold">
                {`${data.length} ${t('home:customers')}`}
              </p>
            </>
          )
        },
      },

      //Plant Count
      {
        Header: t('home:tableHeaders.plantCount'),
        accessor: 'plantCount',
        Loader: (props: any) => <TextPlaceholder min={5} max={12} />,
        sortType: 'basic',
        Footer: ({ data }: any) => {
          var totalMeters = 0
          for (var i = 0; i < data.length; i++) {
            totalMeters += +data[i].plantCount
          }
          return (
            <>
              <p className="mt-4 text-base font-bold">
                {`${totalMeters} ${t('objects:meters')}`}
              </p>
            </>
          )
        },
      },

      //Consumption
      {
        Header: `${t('home:tableHeaders.consumption')} (kWh)`,
        accessor: 'consumption',
        sortType: 'basic',
        className: 'text-right',
        Loader: (props: any) => <TextPlaceholder min={5} max={12} />,
        Cell: (props: any) => {
          return (
            <p
              className="cursor-pointer tracking-wider hover:underline"
              onClick={() =>
                rowClickHandler(
                  customerMeteringPoint,
                  props.row.original.customerId
                )
              }
            >
              <FormatNumber number={props.cell.value} decimals={1} />
            </p>
          )
        },
        Footer: ({ data }: any) => {
          var num = getFooterForColumn('sum', data, 'consumption')
          return (
            <>
              <p className="mt-4 px-2 text-right text-base font-bold tracking-widest">
                <FormatNumber number={num} decimals={1} />
              </p>
            </>
          )
        },
      },

      //Spot Cost
      {
        Header: t('home:tableHeaders.spotCost'),
        accessor: showNett ? 'spotCost' : 'spotGrossCost',
        sortType: 'basic',
        className: 'text-right',
        Loader: (props: any) => <TextPlaceholder min={5} max={12} />,
        Cell: (props: any) => {
          return (
            <p
              className="cursor-pointer tracking-wider hover:underline"
              onClick={() =>
                rowClickHandler(
                  customerMeteringPoint,
                  props.row.original.customerId
                )
              }
            >
              <FormatNumber number={props.cell.value} decimals={1} />
            </p>
          )
        },
        Footer: ({ data }: any) => {
          const selector = showNett ? 'spotCost' : 'spotGrossCost'
          const num = getFooterForColumn('sum', data, selector)
          return (
            <>
              <p className="mt-4 px-2 text-right text-base font-bold tracking-widest">
                <FormatNumber number={num} decimals={2} />
              </p>
            </>
          )
        },
      },

      //Grid cost
      {
        Header: t('home:tableHeaders.gridCost'),
        accessor: showNett ? 'gridCost' : 'gridGrossCost',
        sortType: 'basic',
        className: 'text-right',
        Loader: (props: any) => <TextPlaceholder min={5} max={12} />,
        Cell: (props: any) => {
          return (
            <p
              onClick={() =>
                gridRentClickHandler(props.row.original.customerId)
              }
              className="cursor-pointer text-right tracking-wider hover:underline"
            >
              <FormatNumber number={props.cell.value} decimals={2} />
            </p>
          )
        },
        Footer: ({ data }: any) => {
          const selector = showNett ? 'gridCost' : 'gridGrossCost'
          const num = getFooterForColumn('sum', data, selector)
          return (
            <>
              <p className="mt-4 px-2 text-right text-base font-bold tracking-widest">
                <FormatNumber number={num} decimals={2} />
              </p>
            </>
          )
        },
      },
    ],
    [showNett, customerMeteringPoint, rowClickHandler, t]
  )

  return (
    <div className="container flex w-full flex-row justify-center">
      <div className="flex w-full flex-col">
        <div className="max-w-full">
          <div className="px-2 pb-3 text-3xl">{t('home:allCustomers')}</div>
          <Card className="pb-8">
            {data?.length === 0 ? (
              <NoMeterPlaceholder />
            ) : (
              <Table
                loading={isLoading || isCustomerMeteringPointLoading}
                loadingPlaceholderRows={5}
                columns={columns}
                data={data}
                enableSorting={true}
                onRowClick={(rowData: any) => null}
                tableStyleOverride="table-fixed md:table-auto block md:table absolute md:relative"
                tdStyleOverride="px-3 first:max-w-lg first:min-h-full border-gray-100 border-t-2 border-b-2 text-gray-600"
                thStyleOverride="text-base text-gray-500 font-medium"
              />
            )}
          </Card>
          <GridRentModal
            queryType="customer"
            customerId={gridRentCustomerID}
            isOpen={showGridRentModal}
            onClose={() => setShowGridRentModal(false)}
            start={gridRentFromDate}
            end={gridRentToDate}
            aggregationType="month"
          />
        </div>
      </div>
    </div>
  )
}

export default withTranslation('no')(CustomersPage)
