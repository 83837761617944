import { LatLngTuple } from 'leaflet'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import { LocationMarkerIcon, LightBulbIcon } from '@heroicons/react/outline'

import { Loading } from 'src/components/Loading'
import APIClient from 'src/utils/apiClient'
import { useMeter } from 'src/context/meter'
import {
  MeterPopup,
  Permatip,
  StationPopup,
} from 'src/components/WeatherStationsComponent/Markers'
import { MeteringPointWeatherStation } from 'src/generated/client'
import Legend from 'src/components/WeatherStationsComponent/Legend'

// import 'leaflet/dist/leaflet.css'

const WeatherStations = () => {
  const objectId = useParams<'objectId'>().objectId as string
  const {
    isLoading: isWeatherLoading,
    error: weatherError,
    data: weatherStations,
  } = useQuery(
    ['weatherStations', objectId],
    async () => {
      return APIClient.getWeatherStations(objectId)
    },
    { enabled: !!objectId }
  )

  const {
    isLoading: isPlantLoading,
    error: plantError,
    data: plant,
  } = useQuery(
    ['weatherMeter', objectId],
    async () => {
      return APIClient.getPlant(objectId)
    },
    { enabled: !!objectId, staleTime: Infinity }
  )

  const meter = useMeter()

  if (isWeatherLoading || isPlantLoading || !plant || !weatherStations)
    return <Loading />

  if (weatherError || plantError) return <div>Something went wrong</div>

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex w-full flex-col">
        <MapContainer
          bounds={[
            [plant.lat!, plant.lon!],
            ...weatherStations.map(
              (s: MeteringPointWeatherStation) =>
                [s.stationLat, s.stationLon] as LatLngTuple
            ),
          ]}
          boundsOptions={{
            padding: [50, 50],
          }}
          scrollWheelZoom={false}
          className="overflow-hidden rounded-lg"
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          <Marker position={[plant.lat!, plant.lon!]}>
            <MeterPopup>{plant.netOwnerName}</MeterPopup>
            <Permatip Icon={LightBulbIcon} lime={true}>
              {`${meter?.objectName} - ${meter?.meteringPointId}`}
            </Permatip>
          </Marker>

          {weatherStations.map(
            (station: MeteringPointWeatherStation, i: number) => (
              <Marker
                key={i}
                position={[station.stationLat!, station.stationLon!]}
              >
                <StationPopup>{station.stationName}</StationPopup>
                <Permatip Icon={LocationMarkerIcon}>
                  {station.stationName!}
                </Permatip>
              </Marker>
            )
          )}

          <Legend weatherStations={weatherStations} meter={plant} />
        </MapContainer>
      </div>
    </div>
  )
}

export default WeatherStations
