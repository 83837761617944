import { useCallback, useEffect, useRef, useState } from 'react'
import { createPopper } from '@popperjs/core'
import { useTranslation } from 'react-i18next'

type Props = {
  externalVisibiltyToggle: number
  dropdownButtonReference: any
}

export function UserDropdown(props: Props) {
  //  --- Variables ---
  const dropdownMenuReference = useRef<any>()
  const { t } = useTranslation()
  const [visible, setVisible] = useState<boolean>(false)

  //  --- Functionality ---
  const toggleVisibility = useCallback(
    (newState: boolean) => {
      setVisible(newState)
      createPopper(
        props.dropdownButtonReference.current,
        dropdownMenuReference.current,
        { placement: 'bottom-end' }
      )
    },
    [props.dropdownButtonReference]
  )

  //  --- Setup ---
  useEffect(() => {
    if (props.externalVisibiltyToggle !== 0) {
      toggleVisibility(!visible)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.externalVisibiltyToggle])

  //  --- Response
  return (
    <div className={visible ? '' : 'hidden'}>
      <div
        className="absolute top-0 left-0 h-screen w-screen"
        onClick={() => toggleVisibility(!visible)}
      />
      <div
        className="z-30 rounded-md border bg-white shadow-sm"
        ref={dropdownMenuReference}
      >
        <div className="px-4 py-3">
          <p
            className="cursor-pointer"
            onClick={() => (window.location.pathname = '/api/logout')}
          >
            {t('global:logout')}
          </p>
        </div>
      </div>
    </div>
  )
}

export default UserDropdown
