import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FreqType, GraphSeriesType } from '../../types'
import {
  Label,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
  ComposedChart,
  Line,
  ReferenceLine,
  TooltipProps,
} from 'recharts'
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'
import { pad } from './utils'
import { MeteringPointConsumptionCost } from 'src/generated/client'

export interface ConsumptionGraphData {
  name: string
  timestamp: number
  consumption: number
  spotPrice: number
  spotAmount: number
  totalCost?: number
}

export const round = (value: any, decimals: number) =>
  Math.round(value * 10 ** decimals) / 10 ** decimals

export const ConsumptionGraph = ({
  data,
  series,
  consumptionType,
  freq,
  showNett,
  onGraphClick,
}: {
  data: MeteringPointConsumptionCost[]
  series: GraphSeriesType
  consumptionType: string
  freq: FreqType
  showNett: boolean
  onGraphClick: (v: string) => void
}) => {
  const { t } = useTranslation()

  const graphData = useMemo<ConsumptionGraphData[]>(() => {
    return pad(data, freq)?.map((item: any, i: number) => ({
      name: '' + (i + 1),
      timestamp: item.timestamp,
      consumption: item.consumption,
      production: item.production,
      netConsumption: item.consumption + item.production,
      spotPrice: showNett ? item.spotCostPrice : item.spotCostGrossPrice,
      spotAmount: showNett ? item.spotCost : item.spotGrossCost,
      powerAgreement: showNett
        ? item.powerAgreementCost
        : item.powerAgreementGrossCost,
      totalCost: showNett ? item.totalCost : item.totalGrossCost,
    }))
  }, [data, freq, showNett])

  const yLabel1 = t(`objects:tableModalHeaders.${series}`)
  const yLabel2 = t('objects:tableModalHeaders.spotPrice')
  const xLabel = t(`consumption:graph.${freq}`)

  return (
    <div className="mx-auto p-8 md:w-3/4 md:max-w-6xl">
      <ResponsiveContainer width="100%" aspect={3}>
        <ComposedChart
          onClick={(data: any) => onGraphClick(data?.activeLabel)}
          data={graphData}
          margin={{ top: 0, right: 50, left: 50, bottom: 30 }}
          stackOffset="sign"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            height={graphData != null ? undefined : 0}
            dataKey="timestamp"
            tickFormatter={(value) => xAxisTickFormatter(value, freq, t)}
          >
            <Label value={xLabel} offset={10} position="bottom" />
          </XAxis>

          <YAxis
            yAxisId="left"
            tickFormatter={(value) => value.toLocaleString('NO')}
            orientation="left"
          >
            <Label
              value={yLabel1}
              offset={44}
              angle={270}
              position="left"
              style={{ textAnchor: 'middle' }}
            />
          </YAxis>
          <YAxis
            yAxisId="right"
            tickFormatter={(value) => value.toLocaleString('NO')}
            orientation="right"
          >
            <Label
              value={yLabel2}
              offset={29}
              angle={270}
              position="right"
              style={{ textAnchor: 'middle' }}
            />
          </YAxis>
          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            content={<ConsumptionTooltip freq={freq} />}
            wrapperStyle={{ outline: 'none' }}
          />
          <ReferenceLine y={0} yAxisId="left" stroke="#000" />
          <Bar
            dataKey={
              series !== 'consumption'
                ? series
                : consumptionType === 'netConsumption'
                ? 'netConsumption'
                : 'consumption'
            }
            yAxisId="left"
            stackId="stack"
            fill="#53C023"
          />
          {consumptionType === 'splitConsumption' &&
            series === 'consumption' && (
              <Bar
                dataKey="production"
                yAxisId="left"
                stackId="stack"
                fill="#939598"
              />
            )}
          <Line
            animateNewValues={false}
            dataKey="spotPrice"
            yAxisId="right"
            strokeWidth={2}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}

const ConsumptionTooltip = ({
  active,
  payload,
  freq,
  label,
}: TooltipProps<ValueType, NameType> & { freq: FreqType }) => {
  const { t } = useTranslation()

  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip rounded-md border border-gray-400 bg-white p-4 text-sm text-gray-800">
        {freq === 'year' ? (
          <p>{dayjs(payload[0].payload.timestamp).format('YYYY')}</p>
        ) : freq === 'month' ? (
          <p>
            {`${t(
              `global:shortMonths.${dayjs(payload[0].payload.timestamp)
                .format('MMM')
                .toLowerCase()}`
            )} ${dayjs(payload[0].payload.timestamp).format('YYYY')}`}
          </p>
        ) : freq === 'day' ? (
          <p>{dayjs(payload[0].payload.timestamp).format('DD.MM.YYYY')}</p>
        ) : freq === 'hour' ? (
          <p>
            {dayjs(payload[0].payload.timestamp).format('HH:mm DD.MM.YYYY')}
          </p>
        ) : null}

        <hr className="mt-1" />
        <div className="mt-1">
          {payload.map(
            (entry, index) =>
              entry.value !== 0 && (
                <p key={index} className="">
                  {t(`objects:${entry.name}`)}:{' '}
                  {round(entry.value, 2).toLocaleString('NO')}{' '}
                  {entry.name === 'consumption' ||
                  entry.name === 'production' ||
                  entry.name === 'netConsumption'
                    ? 'kWh'
                    : entry.name === 'spotPrice'
                    ? 'kr/kWh'
                    : 'NOK'}
                </p>
              )
          )}
        </div>
      </div>
    )
  }
  return null
}

export const SpotPriceGraph = ({
  data,
  series,
  freq,
  onGraphClick,
  showNett,
}: {
  data: any
  series: GraphSeriesType
  freq: FreqType
  showNett: boolean
  onGraphClick: (v: string) => void
}) => {
  const { t } = useTranslation()

  const graphData = useMemo(() => {
    const tempData: any[] = pad(
      data?.spotPrices[areaIndex(series)].values,
      freq
    )
    const tempData2: any[] = pad(data?.spotPrices[5]?.values, freq)

    if (tempData && tempData2) {
      return tempData?.map((item: any, i: number) => ({
        name: '' + (i + 1),
        timestamp: item.timestamp,
        spotPrice: showNett ? item.spotPrice : item.spotGrossPrice,
        sysPrice: showNett
          ? tempData2[i]?.spotPrice
          : tempData2[i]?.spotGrossPrice,
      }))
    }
    if (tempData && !tempData2) {
      return tempData?.map((item: any, i: number) => ({
        name: '' + (i + 1),
        timestamp: item.timestamp,
        spotPrice: showNett ? item.spotPrice : item.spotGrossPrice,
      }))
    }

    return []
  }, [data?.spotPrices, freq, series, showNett])

  const xLabel = t(`consumption:graph.${freq}`)

  return (
    <div className="mx-auto p-8 md:w-3/4 md:max-w-6xl">
      <ResponsiveContainer width="100%" aspect={3}>
        <ComposedChart
          onClick={(data: any) => onGraphClick(data?.activeLabel)}
          data={graphData}
          margin={{ top: 0, right: 50, left: 50, bottom: 30 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            height={data != null ? undefined : 0}
            dataKey="timestamp"
            tickFormatter={(value) => xAxisTickFormatter(value, freq, t)}
          >
            <Label value={xLabel} offset={10} position="bottom" />
          </XAxis>

          <YAxis
            yAxisId="left"
            tickFormatter={(value) => value.toLocaleString('NO')}
            orientation="left"
          >
            <Label
              offset={20}
              angle={270}
              position="left"
              style={{ textAnchor: 'middle' }}
            >
              {t(`objects:tableModalHeaders.spotPrice`)}
            </Label>
          </YAxis>
          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            content={<SpotPriceTooltip freq={freq} />}
            wrapperStyle={{ outline: 'none' }}
          />
          <Bar dataKey={'spotPrice'} yAxisId="left" fill="#53C023" />
          <Line
            animateNewValues={false}
            dataKey="sysPrice"
            yAxisId="left"
            strokeWidth={2}
            stroke="#3182BD"
          />
        </ComposedChart>
      </ResponsiveContainer>
      <CustomLegend />
    </div>
  )
}

const areaIndex = (value: string) => {
  if (value === 'NO1') {
    return 0
  }
  if (value === 'NO2') {
    return 1
  }
  if (value === 'NO3') {
    return 2
  }
  if (value === 'NO4') {
    return 3
  }
  if (value === 'NO5') {
    return 4
  } else return 0
}

export const xAxisTickFormatter = (value: string, freq: FreqType, t: any) => {
  if (freq === 'year') {
    return dayjs(value).format('YYYY')
  } else if (freq === 'month') {
    return t(`global:shortMonths.${dayjs(value).format('MMM').toLowerCase()}`)
  } else if (freq === 'week') {
    return `${t('global:week')} ${dayjs(value).isoWeek()} 
            ${dayjs(value).format('YYYY')}`
  } else if (freq === 'day') {
    return dayjs(value).format('D')
  } else if (freq === 'hour') {
    return dayjs(value).format('H')
  } else {
    return ''
  }
}

const CustomLegend = () => {
  const { t } = useTranslation()

  return (
    <div className="mt-4 flex flex-row justify-center space-x-8">
      <div className="flex flex-row items-center">
        <div className="mr-2 h-3 w-3 rounded-full bg-graphSeries" />
        <p>{t('global:areaPrice')}</p>
      </div>
      <div className="flex flex-row items-center">
        <div className="mr-2 h-3 w-3 rounded-full bg-graphBlue" />
        <p>{t('global:systemPrice')}</p>
      </div>
    </div>
  )
}

const SpotPriceTooltip = ({
  active,
  payload,
  freq,
  label,
}: TooltipProps<ValueType, NameType> & { freq: FreqType }) => {
  const { t } = useTranslation()

  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip rounded-md border border-gray-400 bg-white p-4 text-sm text-gray-800">
        {freq === 'year' ? (
          <p>{dayjs(payload[0].payload.timestamp).format('YYYY')}</p>
        ) : freq === 'month' ? (
          <p>
            {`${t(
              `global:shortMonths.${dayjs(payload[0].payload.timestamp)
                .format('MMM')
                .toLowerCase()}`
            )} ${dayjs(payload[0].payload.timestamp).format('YYYY')}`}
          </p>
        ) : freq === 'day' ? (
          <p>{dayjs(payload[0].payload.timestamp).format('DD.MM.YYYY')}</p>
        ) : freq === 'hour' ? (
          <p>
            {dayjs(payload[0].payload.timestamp).format('HH:mm DD.MM.YYYY')}
          </p>
        ) : null}

        <hr className="mt-1" />
        <div className="mt-1">
          {payload.map(
            (entry, index) =>
              entry.value !== 0 && (
                <p key={index} className="">
                  {entry.name === 'spotPrice'
                    ? t(`global:areaPrice`)
                    : t(`objects:${entry.name}`)}
                  : {round(entry.value, 2).toLocaleString('NO')}
                  {' kr/kWh'}
                </p>
              )
          )}
        </div>
      </div>
    )
  }
  return null
}
