import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import apiClient from 'src/utils/apiClient'
import useInvoiceData from 'src/components/InvoiceComponent/useInvoiceData'
import { Invoice } from 'src/components/InvoiceComponent/types'
import {
  FilesIndicicator,
  TableData,
  TableLabel,
} from 'src/components/InvoiceComponent/Table'
import { DeleteConfirmationDialog } from 'src/components/DeleteDialog'
import { Loading } from 'src/components/Loading'
import InvoiceModal from 'src/components/InvoiceComponent/InvoiceModal'
import { Table } from 'src/components/Table'
import { TextPlaceholder } from 'src/components/TextPlaceholder'
import { useParams } from 'react-router-dom'
import useInvoiceCalculator from 'src/components/InvoiceComponent/useInvoiceCalculator'
import { format } from 'src/components/InvoiceComponent/format'
import { Button } from 'src/components/action'

const InvoiceComponent = () => {
  const objectId = useParams<'objectId'>().objectId as string
  const queryClient = useQueryClient()
  const [showNewInvoiceModal, setShowNewInvoiceModal] = useState(false)

  // i18n
  const { t, i18n } = useTranslation()
  const { language } = i18n

  // Fetch from API
  const { invoices, isLoading, error } = useInvoiceData(objectId)

  const openEditor = () => setShowNewInvoiceModal(true)
  const closeEditor = () => setShowNewInvoiceModal(false)

  // Add new invoice
  const addInvoice = () => {
    openEditor()
  }

  const deleteInvoiceMutation = useMutation(
    async (userSharedInvoiceId: any) => {
      return await apiClient.deleteInvoice(objectId, userSharedInvoiceId)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([objectId, 'invoices'])
      },
    }
  )

  // Table
  const initialTableState = useMemo(
    () => ({
      sortBy: [
        {
          id: 'time',
          desc: false,
        },
      ],
    }),
    []
  )

  const columns = useMemo(() => {
    // Formatter shortcuts
    const twoDecimals = (v: number) => format(v, 2, language)
    const fourDecimals = (v: number) => format(v, 4, language)

    const cols = [
      // Time
      {
        Header: t('objects:uploadInvoiceView.time'),
        accessor: 'time',
        Loader: (props: any) => <TextPlaceholder min={12} max={22} />,
        Cell: (props: any) => {
          const row = props.row.original as Invoice

          return <TableLabel label={`${row.month}.${row.year}`} />
        },
      },
      // Electricity Difference
      {
        Header: t('objects:uploadInvoiceView.elecDiff'),
        accessor: 'elecDiff',
        Loader: (props: any) => <TextPlaceholder min={12} max={22} />,
        Cell: (props: any) => {
          const invoice = props.row.original as Invoice
          const calculator = useInvoiceCalculator(invoice)

          return (
            <TableData
              ng={calculator.elecDifference()}
              formatter={twoDecimals}
            />
          )
        },
      },
      // Grid Difference
      {
        Header: t('objects:uploadInvoiceView.gridDiff'),
        accessor: 'gridDiff',
        Loader: (props: any) => <TextPlaceholder min={12} max={22} />,
        Cell: (props: any) => {
          const invoice = props.row.original as Invoice
          const calculator = useInvoiceCalculator(invoice)

          return (
            <TableData
              ng={calculator.gridDifference()}
              formatter={twoDecimals}
            />
          )
        },
      },
      // Average Profit
      {
        Header: t('objects:uploadInvoiceView.averageProfit'),
        accessor: 'averageProfit',
        Loader: (props: any) => <TextPlaceholder min={12} max={22} />,
        Cell: (props: any) => {
          const invoice = props.row.original as Invoice
          const calculator = useInvoiceCalculator(invoice)

          return (
            <TableData
              ng={calculator.averageProfit()}
              formatter={fourDecimals}
            />
          )
        },
      },
      // Average
      {
        Header: t('objects:uploadInvoiceView.average'),
        accessor: 'average',
        Loader: (props: any) => <TextPlaceholder min={12} max={22} />,
        Cell: (props: any) => {
          const invoice = props.row.original as Invoice
          const calculator = useInvoiceCalculator(invoice)

          return (
            <TableData ng={calculator.average()} formatter={fourDecimals} />
          )
        },
      },
      // Average Grid
      {
        Header: t('objects:uploadInvoiceView.averageGrid'),
        accessor: 'averageGrid',
        Loader: (props: any) => <TextPlaceholder min={12} max={22} />,
        Cell: (props: any) => {
          const invoice = props.row.original as Invoice
          const calculator = useInvoiceCalculator(invoice)

          return (
            <TableData ng={calculator.averageGrid()} formatter={fourDecimals} />
          )
        },
      },
      // Average Total
      {
        Header: t('objects:uploadInvoiceView.averageTotal'),
        accessor: 'averageTotal',
        Loader: (props: any) => <TextPlaceholder min={12} max={22} />,
        Cell: (props: any) => {
          const invoice = props.row.original as Invoice
          const calculator = useInvoiceCalculator(invoice)

          return (
            <TableData
              ng={calculator.averageTotal()}
              formatter={fourDecimals}
            />
          )
        },
      },
      // Files
      {
        Header: t('objects:uploadInvoiceView.files'),
        accessor: 'files',
        Loader: (props: any) => <TextPlaceholder min={12} max={22} />,
        Cell: (props: any) => {
          const invoice = props.row.original as Invoice

          return <FilesIndicicator fileCount={invoice.file.length} />
        },
      },
      {
        Header: ' ',
        accessor: 'EDIT',
        Loader: (props: any) => <TextPlaceholder min={12} max={22} />,
        Cell: (props: any) => {
          const invoice = props.row.original as Invoice
          const [isOpen, setIsOpen] = useState(false)

          return (
            <>
              <span
                onClick={() => setIsOpen(true)}
                className="cursor-pointer whitespace-nowrap pl-6 text-right text-sm font-medium text-green-600 hover:text-green-900"
              >
                {t('objects:uploadInvoiceView.edit')}
              </span>

              <InvoiceModal
                title={t('objects:uploadInvoiceView.editRow')}
                invoice={invoice}
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
              />
            </>
          )
        },
      },
      {
        Header: '   ',
        accessor: 'DELETE',
        Loader: (props: any) => <TextPlaceholder min={12} max={22} />,
        Cell: (props: any) => {
          const invoice = props.row.original as Invoice
          const [isOpen, setIsOpen] = useState(false)

          return (
            <>
              <span
                onClick={() => setIsOpen(true)}
                className="cursor-pointer whitespace-nowrap pl-6 text-right text-sm font-medium text-red-600 hover:text-red-900"
              >
                {t('objects:uploadInvoiceView.delete')}
              </span>

              <DeleteConfirmationDialog
                title={t('objects:uploadInvoiceView.deleteInvoice')}
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                onSubmit={async () => {
                  await deleteInvoiceMutation.mutate(invoice.userInvoiceId)
                }}
              />
            </>
          )
        },
      },
    ]
    return cols
  }, [t, language, deleteInvoiceMutation])

  // Catch before render
  if (error) {
    console.error(error)
    return <p>Error. Check console.</p>
  }

  if (isLoading) return <Loading />

  return (
    <>
      <InvoiceModal
        title={t('objects:uploadInvoiceView.addRow')}
        invoice={buildDefaultInvoice()}
        isOpen={showNewInvoiceModal}
        onClose={closeEditor}
      />
      <div className="flex flex-col space-y-2">
        <div className="flex items-center justify-end">
          <div>
            <Button onClick={addInvoice}>
              {t('objects:uploadInvoiceView.addRow')}
            </Button>
          </div>
        </div>
        <Table
          loading={isLoading}
          loadingPlaceholderRows={3}
          columns={columns}
          initialState={initialTableState}
          data={invoices}
          enableSorting={true}
          onRowClick={(rowData: any) => null} //onRowClickHanler(rowData['timestamp'])}
          tableStyleOverride="ml-6 md:ml-0 table-fixed md:table-auto"
          thStyleOverride="text-base text-gray-500 font-medium whitespace-nowrap"
          tdStyleOverride="px-3 first:w-10 first:max-w-lg first:w-96"
        />
      </div>
    </>
  )
}

export default InvoiceComponent

const buildDefaultInvoice = (): Invoice => {
  const invoice = {
    year: new Date().getFullYear().toString(),
    month: new Date().getMonth().toString(),
    index: '',
    time: '',
    quantity: 0,
    price: 0,
    priceGross: 0,
    amount: 0,
    amountGross: 0,
    rent: 0,
    rentGross: 0,
    total: 0,
    totalGross: 0,
    elecInvoice: null,
    gridInvoice: null,
    elecInvoiceGross: null,
    gridInvoiceGross: null,
    file: [],
    comment: '',
  }

  return {
    ...invoice,
    gridDiff: 0,
    gridDiffGross: 0,
    elecDiff: 0,
    elecDiffGross: 0,
    average: 0,
    averageGross: 0,
  }
}
