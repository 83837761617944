import { DocumentDuplicateIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

type Props = {
  files: Array<any>
}

type FileLinkProps = {
  objectId: string
  file: File & {
    invoiceType?: string
  }
  number: number
}

const PreviousFilesList = ({ files }: Props) => {
  const objectId = useParams<'objectId'>().objectId as any

  if (files.length === 0) return null

  return (
    <>
      {files.map((file, i) => (
        <div key={i} className="sm:col-span-2">
          <FileLink file={file} number={i + 1} objectId={objectId} />
        </div>
      ))}
    </>
  )
}

const FileLink = ({ objectId, file, number }: FileLinkProps) => {
  const { t } = useTranslation()

  let typeLabel = ''

  switch (file.invoiceType) {
    case 'S':
      typeLabel = t('objects:uploadInvoiceView.electricity')
      break
    case 'N':
      typeLabel = t('objects:uploadInvoiceView.grid')
      break
    case 'T':
      typeLabel = t('objects:uploadInvoiceView.totalDropdown')
      break

    default:
      break
  }

  return (
    <a
      onClick={(e) => e.stopPropagation()}
      href={buildFileURL(objectId, file)}
      target="_blank"
      rel="noreferrer"
      className="flex h-24 flex-row items-center space-x-2 rounded-md border px-4 text-sm font-medium hover:bg-gray-50"
    >
      <div className="flex flex-row space-x-2">
        <DocumentDuplicateIcon className="mx-auto h-10 w-10 text-gray-500" />
        <div className="flex flex-col space-y-1">
          <span className="whitespace-nowrap text-xs">
            {(file as any)['fileName']}
          </span>

          <div className="block">
            <span className="whitespace-nowrap rounded-lg bg-gray-100 px-2 py-1 text-xs font-normal text-gray-700">
              {typeLabel}
            </span>
          </div>
        </div>
      </div>
    </a>
  )
}

function buildFileURL(objectId: string, file: File) {
  const fileId = (file as any)['userSharedInvoiceFileId']

  return `/api/object/${objectId}/invoice-file/${fileId}`
}

export default PreviousFilesList
