import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom'

// Components
import ErrorBoundary from './components/ErrorBoundry'

/**
 * Routes
 */
import AppRoot from './routes/root'

/**
 * Pages
 */
import Login from './pages/Login'
import Customers from './pages/Customers'
import MeterFrame from './components/MeterFrame'
import Meters from './pages/Meters'
import SumBudget from './pages/CustomerBudget'
import CustomerFrame from './components/CustomerFrame'
import Budget from './pages/Budget'
import Consumption from './pages/Consumption'
import Details from './pages/Details'
import Enroll from './pages/Enroll'
import Peak from './pages/Peak'
import SpotPrice from './pages/SpotPrice'
import SumCompany from './pages/SumCompany'
import ET from './pages/ET'
import ETFelt from './pages/ETFelt'
import UploadInvoice from './pages/UploadInvoice'
import WeatherStations from './pages/WeatherStations'
import PowerFactor from './pages/PowerFactor'
import Settings from './pages/Settings'

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="login/*" element={<Login />} />

      <Route
        path="App/*"
        element={<AppRoot />}
        errorElement={<ErrorBoundary />}
      >
        <Route index element={<Navigate replace to="Customers" />} />

        <Route path="Customers">
          <Route index element={<Customers />} />

          <Route path=":customerId" element={<CustomerFrame />}>
            <Route index element={<Navigate replace to="SumCompany" />} />

            <Route path="SumCompany/*" element={<SumCompany />} />
            <Route path="SumBudget/*" element={<SumBudget />} />
            <Route path="Meters/*" element={<Meters />} />
            <Route index element={<Navigate to="/App/Customers" replace />} />
          </Route>
        </Route>

        <Route path="Meters">
          <Route path=":objectId" element={<MeterFrame />}>
            <Route index element={<Navigate replace to="Consumption" />} />
            <Route path="Consumption/*" element={<Consumption />} />
            <Route path="ET/*" element={<ET />} />
            <Route path="ETFelt/*" element={<ETFelt />} />
            <Route path="Reactive/*" element={<PowerFactor />} />
            <Route path="Peak/*" element={<Peak />} />
            <Route path="Details/*" element={<Details />} />
            <Route path="WeatherStations/*" element={<WeatherStations />} />
            <Route path="UploadInvoice/*" element={<UploadInvoice />} />
            <Route path="SpotPrice/*" element={<SpotPrice />} />
            <Route path="Budget/*" element={<Budget />} />

            {/* /App/47 should navigate back to /App */}
            <Route index element={<Navigate to="/App" replace />} />

            {/* /App/47/Invalid should navigate back to /App */}
            <Route path="*" element={<Navigate to="/App" replace />} />
          </Route>

          {/* /App/Invalid should navigate back to /App */}
          <Route path="*" element={<Navigate to="/App" replace />} />
        </Route>

        <Route path="Enroll/:step" element={<Enroll />} />
        <Route path="Settings" element={<Settings />} />
      </Route>

      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route path="*" element={<Navigate to="/app" replace />} />
    </>
  )
)
