import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'

import type { MeteringPointBudgetOverview } from 'src/generated/client'
import type { FreqType } from 'src/types'

import { useAppState } from 'src/context/appstate'
import APIClient from 'src/utils/apiClient'

import { Table } from 'src/components/Table'
import { Loading } from 'src/components/Loading'
import FormatNumberBase from 'src/components/FormatNumber'
import BudgetGraph from 'src/components/BudgetComponent/BudgetGraph'
import DateChanger from 'src/components/DateChanger'

type BudgetComponentType = {
  objectId?: string
  customerId?: string
}

export default function BudgetComponent(props: BudgetComponentType) {
  const { t } = useTranslation()
  const { showNett } = useAppState()

  const [params, setParams] = useSearchParams()
  const { objectId } = props
  const { customerId } = props

  const from =
    params.get('from') || dayjs().startOf('year').format('YYYY-MM-DD')

  const to =
    params.get('to') ||
    dayjs().add(1, 'year').startOf('year').format('YYYY-MM-DD')

  const { isLoading, error, data } = useQuery(
    [objectId, customerId, 'budget', from, to],
    () => {
      if (customerId) {
        return APIClient.getCustomerBudget(customerId, from, to)
      }

      if (objectId) {
        return APIClient.getMeteringPointBudget(objectId, from, to)
      }
    }
  )

  const dateDisplay = useMemo(() => {
    return dayjs(from).format('YYYY')
  }, [from])

  const disablePrev = useMemo(() => {
    return dayjs(from).subtract(1, 'year').isBefore(dayjs().startOf('year'))
  }, [from])

  const changeDate = (value: string) => {
    let tmpFrom = from
    let tmpTo = to
    let changeByFreq: FreqType = 'year'

    if (value === 'previous') {
      tmpFrom = dayjs(tmpFrom).subtract(1, changeByFreq).format('YYYY-MM-DD')
      tmpTo = dayjs(tmpTo)
        .subtract(1, changeByFreq)
        .startOf(changeByFreq)
        .format('YYYY-MM-DD')
    }
    if (value === 'next') {
      tmpFrom = dayjs(tmpFrom).add(1, changeByFreq).format('YYYY-MM-DD')
      tmpTo = dayjs(tmpTo).add(1, changeByFreq).format('YYYY-MM-DD')
    }
    setParams({ from: tmpFrom, to: tmpTo })
  }

  const initialTableState = useMemo(() => ({}), [])

  const consumptionColumns = useMemo(() => {
    return [
      // Time
      {
        Header: '                    ',
        accessor: 'timestamp',
        Footer: () => (
          <p className="mt-2 px-2 text-center text-base font-medium">
            {t('objects:budgetView.total')}
          </p>
        ),
        columns: [
          {
            Header: t('objects:tableModalHeaders.time'),
            className: 'w-40 max-w-lg text-center border-r',
            accessor: 'timestamp',
            Footer: () => (
              <p className="mt-2 px-2 text-center text-base font-medium">
                {t('objects:budgetView.subTotal')}
              </p>
            ),
            Cell: (props: any) => {
              return (
                <span className="tracking-wider">
                  {dayjs(props.cell.value).format('MM.YYYY')}
                </span>
              )
            },
          },
        ],
        // Cell: (props: any) => {
        //   return (
        //     <span className="tracking-wider">
        //       {dayjs(props.cell.value).format('MM.YYYY')}
        //     </span>
        //   )
        // },
        // Footer: () => null,
      },
      /**
       * Consumption
       */

      // Budget   - budget.consumption
      {
        Header: '              ', // This needs to be a space
        Footer: ({ data }: { data: MeteringPointBudgetOverview[] }) => {
          let total = 0
          for (const item of data) {
            total += item?.budget?.consumption || 0
          }

          return (
            <>
              <p className="mt-2 px-2 text-right text-base font-bold tracking-widest">
                <FormatNumber number={total} />
              </p>
            </>
          )
        },
        columns: [
          {
            Header: t('objects:budgetView.budget'),
            accessor: 'budget.consumption',
            className: 'border-t text-right',
            Cell: (props: any) => {
              // const field = formatKwh(props.cell.value)
              const field = props.cell.value

              return (
                <p className={`text-right tracking-wider`}>
                  <FormatNumber number={field} />
                </p>
              )
            },
          },
        ],
      },

      // Forecast - forecast.consumption

      {
        Header: t('objects:budgetView.consumption'),
        className: 'whitespace-nowrap',
        Footer: () => null,
        columns: [
          {
            Header: t('objects:budgetView.forecast'),
            accessor: 'forecast.consumption',
            className: 'border-t text-right',
            Cell: (props: any) => {
              // const field = formatKwh(props.cell.value)
              const field = props.cell.value

              return (
                <p className={`text-right tracking-wider`}>
                  <FormatNumber number={field} />
                </p>
              )
            },
            Footer: ({ data }: { data: MeteringPointBudgetOverview[] }) => {
              let total = 0

              for (const item of data) {
                total += item?.forecast?.consumption ?? 0
              }

              return (
                <>
                  <p className="mt-2 px-2 text-right text-base font-bold tracking-widest">
                    <FormatNumber number={total} />
                  </p>
                </>
              )
            },
          },
        ],
      },

      // Real     - real.consumption
      {
        Header: '       ', // This needs to be a space
        Footer: ({ data }: { data: MeteringPointBudgetOverview[] }) => {
          let total = 0
          for (const item of data) {
            total += item?.forecast?.consumption || 0
            total += item?.real?.consumption || 0
          }

          return (
            <>
              <p className="mt-2 px-2 text-right text-base font-bold tracking-widest">
                <FormatNumber number={total} />
              </p>
            </>
          )
        },
        columns: [
          {
            Header: t('objects:budgetView.real'),
            accessor: 'real.consumption',
            className: 'border-t text-right',
            Cell: (props: any) => {
              // const field = formatKwh(props.cell.value)
              const field = props.cell.value

              return (
                <p className={`text-right tracking-wider`}>
                  <FormatNumber number={field} />
                </p>
              )
            },
            Footer: ({ data }: { data: MeteringPointBudgetOverview[] }) => {
              let total = 0
              for (const item of data) {
                total += item?.real?.consumption || 0
              }

              return (
                <>
                  <p className="mt-2 px-2 text-right text-base font-bold tracking-widest">
                    <FormatNumber number={total} />
                  </p>
                </>
              )
            },
          },
        ],
      },

      /**
       * Peak
       */

      // Budget   - budget.maxConsumption
      {
        Header: '  ', // This needs to be a space,
        Footer: () => null,
        columns: [
          {
            Header: t('objects:budgetView.budget'),
            className: 'border-l border-t text-right',
            accessor: 'budget.maxConsumption',
            Cell: (props: any) => {
              // const field = formatKw(props.cell.value)
              const field = props.cell.value

              return (
                <p className={`text-right tracking-wider`}>
                  <FormatNumber number={field} />
                </p>
              )
            },
          },
        ],
      },

      // Forecast - forecast.maxConsumption
      {
        Header: t('objects:budgetView.peak'),
        className: 'whitespace-nowrap',
        Footer: () => null,
        columns: [
          {
            Header: t('objects:budgetView.forecast'),
            className: 'border-t text-right',
            accessor: 'forecast.maxConsumption',
            Cell: (props: any) => {
              // const field = formatKw(props.cell.value)
              const field = props.cell.value

              return (
                <p className={`text-right tracking-wider`}>
                  <FormatNumber number={field} />
                </p>
              )
            },
          },
        ],
      },

      // Real     - real.maxConsumption
      {
        Header: '     ', // This needs to be a space
        Footer: () => null,
        columns: [
          {
            Header: t('objects:budgetView.real'),
            className: 'border-r border-t text-right',
            accessor: 'real.maxConsumption',
            Cell: (props: any) => {
              // const field = formatKw(props.cell.value)
              const field = props.cell.value

              return (
                <p className={`text-right tracking-wider`}>
                  <FormatNumber number={field} />
                </p>
              )
            },
          },
        ],
      },

      /**
       * Cost
       */

      // Budget   - nett ? budget.totalCost : budget.totalGrossCost
      {
        Header: ' ',
        Footer: ({ data }: { data: MeteringPointBudgetOverview[] }) => {
          let total = 0
          for (const item of data) {
            total += showNett
              ? item?.budget?.totalCost || 0
              : item?.budget?.totalGrossCost || 0
          }

          return (
            <>
              <p className="mt-2 px-2 text-right text-base font-bold tracking-widest">
                <Krone number={total} />
              </p>
            </>
          )
        },
        columns: [
          {
            Header: t('objects:budgetView.budget'),
            Footer: () => null,
            accessor: showNett ? 'budget.totalCost' : 'budget.totalGrossCost',
            className: 'border-t text-right',
            Cell: (props: any) => {
              // const field = formatKr(props.cell.value)
              const field = props.cell.value

              return (
                <p className={`text-right tracking-wider`}>
                  <Krone number={field} />
                </p>
              )
            },
          },
        ],
      },

      // Forecast - nett ? forecast.totalCost : forecast.totalGrossCost :
      {
        Header: t('objects:budgetView.cost'),
        className: 'whitespace-nowrap',
        Footer: () => null,
        columns: [
          {
            Header: t('objects:budgetView.forecast'),
            accessor: showNett
              ? 'forecast.totalCost'
              : 'forecast.totalGrossCost',
            className: 'border-t text-right',
            Cell: (props: any) => {
              // const field = formatKr(props.cell.value)
              const field = props.cell.value

              return (
                <p className={`text-right tracking-wider`}>
                  <Krone number={field} />
                </p>
              )
            },
            Footer: ({ data }: { data: MeteringPointBudgetOverview[] }) => {
              let total = 0
              for (const item of data) {
                total += showNett
                  ? item?.forecast?.totalCost || 0
                  : item?.forecast?.totalGrossCost || 0
              }
              return (
                <>
                  <p className="mt-2 px-2 text-right text-base font-bold tracking-widest">
                    <Krone number={total} />
                  </p>
                </>
              )
            },
          },
        ],
      },

      // Real     - nett ? real.totalCost : real.totalGrossCost
      {
        Header: '   ',
        Footer: ({ data }: { data: MeteringPointBudgetOverview[] }) => {
          let total = 0
          for (const item of data) {
            total += showNett
              ? item?.forecast?.totalCost || 0
              : item?.forecast?.totalGrossCost || 0

            total += showNett
              ? item?.real?.totalCost || 0
              : item?.real?.totalGrossCost || 0
          }

          return (
            <>
              <p className="mt-2 px-2 text-right text-base font-bold tracking-widest">
                <Krone number={total} />
              </p>
            </>
          )
        },
        columns: [
          {
            Header: t('objects:budgetView.real'),
            className: 'border-r border-t text-right',
            accessor: showNett ? 'real.totalCost' : 'real.totalGrossCost',
            Cell: (props: any) => {
              // const field = formatKr(props.cell.value)
              const field = props.cell.value

              return (
                <p className={`text-right tracking-wider`}>
                  <Krone number={field} />
                </p>
              )
            },
            Footer: ({ data }: { data: MeteringPointBudgetOverview[] }) => {
              let total = 0
              for (const item of data) {
                total += showNett
                  ? item?.real?.totalCost || 0
                  : item?.real?.totalGrossCost || 0
              }
              return (
                <>
                  <p className="mt-2 px-2 text-right text-base font-bold tracking-widest">
                    <Krone number={total} />
                  </p>
                </>
              )
            },
          },
        ],
      },
    ]
  }, [t, showNett])

  if (error) {
    console.error(error)
    return <p>Error. Check console.</p>
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <div>
      {data ? (
        <div className="pt-30 hidden sm:block">
          {<BudgetGraph data={data} />}
        </div>
      ) : null}

      <DateChanger
        dateChangeHandler={changeDate}
        dateDisplay={dateDisplay}
        disablePrev={disablePrev}
        disableNext={false}
      />

      <Table
        loading={isLoading}
        loadingPlaceholderRows={3}
        columns={consumptionColumns}
        initialState={initialTableState}
        data={data}
        onRowClick={(rowData: any) => null} //onRowClickHanler(rowData['timestamp'])}
        tableStyleOverride="ml-6 md:ml-0 table-fixed md:table-auto"
        thStyleOverride="text-base text-gray-500 font-medium"
        tdStyleOverride="px-3 first:w-10 first:max-w-lg first:w-96"
      />
    </div>
  )
}

const Krone = ({ number }: { number: number }) => {
  if (number === 0) return <span>-</span>

  return (
    <span className="whitespace-nowrap">
      <FormatNumber number={number} />
      <span className="text-sm font-semibold">{'  '}kr</span>
    </span>
  )
}

// Re define FormatNumber for this component to fix decimals to 0
const FormatNumber = ({ number }: { number: number }) => {
  return <FormatNumberBase number={number} decimals={0} />
}
