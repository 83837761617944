import { Card } from 'src/components/Card'

interface Step5Props {
  currentStep: string
  nextStep: (next: any) => void
  children: any
}

const Step5 = (props: Step5Props) => {
  const submitFormData = () => {
    props.nextStep(parseInt(props.currentStep) + 1)
  }

  return (
    <>
      <div className="">
        <div className="m-4 block">
          <p className="text-left text-sm">
            For at vi skal vite hvilke anlegg vi skal hente data på, så trenger
            vi målepunkt ID på de adressene du ønsker å ha Strømradar på, ved å
            trykke på knappen under kan du laste opp en .CSV fil, dersom du ikke
            har tilgang til CSV så kan du legge inn målepunkt ID (et eller
            flere) manuelt i feltene under. Du kan også legge til nye anlegg
            etter registrering.
          </p>
        </div>
        <Card>
          <form onSubmit={() => submitFormData()}>
            <div className="flex w-full justify-around">
              <div className="flex w-2/5">
                <input
                  type="file"
                  className="form-control-file"
                  id="formControlFile"
                  accept=".csv"
                />

                <input
                  type="submit"
                  value="Upload"
                  name="UploadButton"
                  id="UploadButton"
                />
                <button
                  type="submit"
                  value="updaload"
                  name="btnSubmit"
                  className="btn btn-primary mr-1"
                >
                  LastOpp
                </button>
              </div>
            </div>
            <div className="block w-full">
              <div className="">
                <div className="m-4">
                  <h1>Legg til målepunkt ID</h1>
                </div>
                <div className="form-group row">
                  <div className="col-md-3 mb-3 justify-center">
                    <div className="m-2 w-52 w-full">
                      <input
                        type="text"
                        className="rounded-lg border p-2 text-sm"
                        id="textInputMeteringPointPrefix"
                        value="7070575000"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-3 mb-3 justify-center">
                    <div className="m-2 w-52 w-full">
                      <input
                        type="text"
                        id="textInputMeteringPointID"
                        className="rounded-lg border p-2 text-sm"
                        placeholder="MålepunktID"
                      />
                    </div>
                  </div>
                  <div className="col-md-3 mb-3 justify-center">
                    <div className="m-2 w-52 w-full">
                      <input
                        type="text"
                        id="textInputMeteringPointDesc"
                        className="rounded-lg border p-2 text-sm"
                        placeholder="Valgfri beskrivelse (Adresse, navn, firma)"
                        required
                      />
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  value="add"
                  name="btnSubmit"
                  className="w-50 rounded-lg border bg-green-300 p-2 text-sm"
                  id="btnAddMeteringPoint"
                >
                  Legg til anlegg
                </button>
              </div>
            </div>
            {props.children}
          </form>
        </Card>
      </div>
    </>
  )
}

export default Step5
