import type { PropsWithChildren } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

interface AppState {
  showNett: boolean
  toggleShowNett: Function
}

const AppStateContext = createContext<AppState>({
  showNett: false,
  toggleShowNett: () => {},
})

const AppStateContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [showNett, setShowNett] = useState<boolean>(false)

  useEffect(() => {
    let nett = JSON.parse(localStorage.getItem('nett') as string)
    setShowNett(nett)
  }, [setShowNett])

  const toggleShowNett = useCallback(() => {
    setShowNett((s) => {
      let v = !s
      localStorage.setItem('nett', JSON.stringify(v))
      return v
    })
  }, [setShowNett])

  const data = useMemo<AppState>(
    () => ({
      showNett: showNett,
      toggleShowNett: toggleShowNett,
    }),
    [toggleShowNett, showNett]
  )

  return (
    <AppStateContext.Provider value={data}>{children}</AppStateContext.Provider>
  )
}

const useAppState = () => useContext(AppStateContext)

export { AppStateContextProvider, useAppState }
