import { useTranslation } from 'react-i18next'
import { Dropdown } from 'src/components/Dropdown'

const FileTypeSelect = ({
  filename,
  value,
  onChange,
  number,
  dataUrl,
}: {
  filename: string
  value: string
  onChange: Function
  number: number
  dataUrl: string
}) => {
  const { t } = useTranslation()
  return (
    <div className="flex w-full cursor-default flex-col justify-between space-y-2 rounded-md border border-lime px-4 py-3">
      <a
        className="flex flex-col justify-center text-sm font-medium hover:underline"
        rel="noreferrer"
        onClick={(e) => e.stopPropagation()}
        href={dataUrl}
        target="_blank"
      >
        {filename}
      </a>
      <div className="w-full">
        <Dropdown
          onChange={onChange}
          label="Type"
          value={value}
          options={[
            {
              value: '',
              label: t('objects:uploadInvoiceView.chooseType'),
            },
            {
              value: 'S',
              label: t('objects:uploadInvoiceView.electricity'),
            },
            {
              value: 'N',
              label: t('objects:uploadInvoiceView.grid'),
            },
            {
              value: 'T',
              label: t('objects:uploadInvoiceView.totalDropdown'),
            },
          ]}
        />
      </div>
    </div>
  )
}

export default FileTypeSelect
