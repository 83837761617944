import { Card } from 'src/components/Card'

interface Step4Props {
  currentStep: string
  nextStep: (next: any) => void
  children: any
}

// interface Step4State {}

const Step4 = (props: Step4Props) => {
  const submitInvoice = () => {
    props.nextStep(parseInt(props.currentStep) + 1)
  }

  // const onClickNextStep = () => {}
  // const onClickPrevStep = () => {}

  return (
    <>
      <div className="">
        <Card className="m-4">
          <form onSubmit={() => submitInvoice()}>
            <div className="form-group">
              <div className="m-4">
                <h1 className="text-lg font-semibold">
                  Kryss av hvilken type faktura du ønsker
                </h1>
              </div>
              <div className="flex w-full justify-center">
                <div className="flex w-2/5 justify-around">
                  <div className="col">
                    <input type="radio" name="invoiceType" required />
                    <label className="ml-2 text-sm" htmlFor="exampleRadios1">
                      EHF
                    </label>
                  </div>
                  <div className="col">
                    <input type="radio" name="invoiceType" required />
                    <label className="ml-2 text-sm" htmlFor="exampleRadios1">
                      Mail
                    </label>
                  </div>
                  <div className="col">
                    <input type="radio" name="invoiceType" required />
                    <label className="ml-2 text-sm" htmlFor="exampleRadios1">
                      Papir
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="m-4">
                <h1 className="text-lg font-semibold">
                  Kryss av for hvilken fakturahyppighet du ønsker
                </h1>
              </div>
              <div className="flex w-full justify-center">
                <div className="flex w-2/5 justify-around">
                  <div className="col">
                    <input type="radio" name="InvoiceFrequency" required />
                    <label className="ml-2 text-sm" htmlFor="exampleRadios1">
                      Månedlig
                    </label>
                  </div>
                  <div className="col">
                    <input type="radio" name="InvoiceFrequency" required />
                    <label className="ml-2 text-sm" htmlFor="exampleRadios1">
                      Halvårlig
                    </label>
                  </div>
                  <div className="col">
                    <input type="radio" name="InvoiceFrequency" required />
                    <label className="ml-2 text-sm" htmlFor="exampleRadios1">
                      Årlig
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="block w-full justify-center">
              <div className="m-4">
                <h1 className="text-lg font-semibold">Fakturainformasjon</h1>
              </div>
              <div className="flex w-full justify-center">
                <div className="m-2 w-52">
                  <input
                    type="text"
                    className="w-full rounded-lg border p-2 text-sm"
                    placeholder="E-postadresse for faktura"
                    required
                  />
                </div>
              </div>
              <div className="flex w-full justify-center">
                <div className="m-2 w-52">
                  <input
                    type="text"
                    className="w-full rounded-lg border p-2 text-sm"
                    placeholder="Fakturaadresse"
                    required
                  />
                </div>
              </div>
              <div className="flex w-full justify-center">
                <div className="m-2 w-52">
                  <input
                    type="number"
                    className="w-full rounded-lg border p-2 text-sm"
                    placeholder="Faktura postnummer"
                  />
                </div>
              </div>
              <div className="flex w-full justify-center">
                <div className="m-2 w-52">
                  <input
                    type="text"
                    className="w-full rounded-lg border p-2 text-sm"
                    placeholder="Faktura poststed"
                    required
                  />
                </div>
              </div>
              <div className="flex w-full justify-center">
                <div className="m-2 w-52">
                  <input
                    type="text"
                    className="w-full rounded-lg border p-2 text-sm"
                    placeholder="PO / Bestillingsnummer"
                    required
                  />
                </div>
              </div>
            </div>
            {props.children}
          </form>
        </Card>
      </div>
    </>
  )
}

export default Step4
