import { useLocation, NavLink, useNavigate } from 'react-router-dom'
import cn from 'classnames'

type Tab = {
  label: string
  url: string
  hiddenOnMobile?: boolean
}

export function Tabs({ tabs, prefix = '' }: { tabs: Tab[]; prefix?: string }) {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  function isActive(tab: Tab) {
    const fullUrl = `${prefix}${tab.url}`
    return pathname === fullUrl
  }

  return (
    <div className="flex max-w-full overflow-hidden">
      <div className="mt-3 w-full sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="focus:outline-none block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base sm:text-sm"
          defaultValue={tabs.find((tab) => isActive(tab))?.url}
          onChange={(e) => {
            navigate(`${prefix}${e.target.value}`)
          }}
        >
          {tabs.map((tab) => {
            return (
              <option
                className={cn(tab.hiddenOnMobile && 'hidden sm:block')}
                key={tab.label}
                value={tab.url}
              >
                {tab.label}
              </option>
            )
          })}
        </select>
      </div>
      <div className="mt-4 hidden sm:block">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {tabs.map((tab) => {
            const fullUrl = `${prefix}${tab.url}`
            const current = isActive(tab)

            return (
              <NavLink
                key={tab.label}
                to={fullUrl}
                className={cn(
                  current
                    ? 'border-lime'
                    : 'border-transparent text-gray-600 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-4 py-3 px-1'
                )}
                aria-current={current ? 'page' : undefined}
              >
                {tab.label}
              </NavLink>
            )
          })}
        </nav>
      </div>
    </div>
  )
}
