import dayjs from 'dayjs'
import apiClient from 'src/utils/apiClient'
import { Invoice } from './types'

export const getMonthData = (dateStr: any, objectId: string) => {
  const start = dayjs(dateStr).startOf('month').format('YYYY-MM-DD HH:mm:ss')
  const end = dayjs(dateStr)
    .add(1, 'month')
    .startOf('month')
    .format('YYYY-MM-DD HH:mm:ss')

  return apiClient.getConsumption(objectId, start, end, 'month')
}

export const isValidInvoice = (row: Invoice) => {
  const date = dayjs(row.year + '-' + row.month)
  if (!date.isValid()) return false

  return true
}

export const formatNumberInput = (input: any) => {
  if (!input) return NaN

  return parseFloat(`${input}`.replace(/,/g, '.').replace(/\s/g, ''))
}

export const checkNaN = (input: any) => isNaN(formatNumberInput(input))

export const makePostBody = (invoice: Invoice, showNett: boolean) => {
  const date = dayjs(invoice.year + '-' + invoice.month)
  let body: any = {
    userInvoiceId: invoice.userInvoiceId,
    fromDate: date.startOf('month').format('YYYY-MM-DD'),
    toDate: date.add(1, 'month').startOf('month').format('YYYY-MM-DD'),
    base64Files: invoice.file.filter((f) => f.base64 != null),
    comment: invoice.comment,
  }

  if (showNett) {
    body.spotAmount = formatNumberInput(invoice.elecInvoice)
    body.gridAmount = formatNumberInput(invoice.gridInvoice)
  } else {
    body.spotGrossAmount = formatNumberInput(invoice.elecInvoiceGross)
    body.gridGrossAmount = formatNumberInput(invoice.gridInvoiceGross)
  }
  return body
}

export const applyInvoiceFromServerToInvoice = (
  data: any,
  invoice: Invoice
) => {
  const date = dayjs(invoice.year + '-' + invoice.month)

  return {
    userInvoiceId: invoice.userInvoiceId,
    index: invoice.index,
    time: date.format('DD.MM.YYYY'),
    quantity: parseFloat(data.consumption),
    price: parseFloat(data.spotCostPrice),
    amount: parseFloat(data.spotCost),
    rent: data.gridCost ? parseFloat(data.gridCost) : data.gridCost,
    total: parseFloat(data.totalCost),
    priceGross: parseFloat(data.spotCostGrossPrice),
    amountGross: parseFloat(data.spotGrossCost),
    rentGross: data.gridGrossCost
      ? parseFloat(data.gridGrossCost)
      : data.gridGrossCost,
    totalGross: parseFloat(data.totalGrossCost),
    year: invoice.year,
    month: invoice.month,
    elecInvoice: invoice.elecInvoice,
    gridInvoice: invoice.gridInvoice,
    elecInvoiceGross: invoice.elecInvoiceGross,
    gridInvoiceGross: invoice.gridInvoiceGross,
    file: invoice.file,
    comment: invoice.comment || '',
  }
}
