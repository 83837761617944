import dayjs from 'dayjs'
import AdvancedFormat from 'dayjs/plugin/advancedFormat'
import isoWeek from 'dayjs/plugin/isoWeek'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Scatter,
  Label,
  TooltipProps,
  ComposedChart,
  Line,
} from 'recharts'

import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'
import type { ETChart, MeteringPointWeatherStation } from 'src/generated/client'
import type { FreqType } from 'src/types'
import { useSearchParams } from 'react-router-dom'

dayjs.extend(AdvancedFormat)
dayjs.extend(isoWeek)

// const dataPeriods = [
//   'month',
//   'threeMonths',
//   'sixMonths',
//   'year',
//   'all',
// ] as const

// type DataPeriod = typeof dataPeriods[number]

const trendLines = ['trendWeekday', 'trendWeekend', 'trend'] as const

type TrendLine = typeof trendLines[number]

const round = (value: any, _decimals: number) => Math.round(value * 10) / 10

const graphColours = {
  DEFAULT: '#53c023',
  two: '#000000',
  three: '#939598',
  four: '#f09521',
  five: '#31791b',
  six: '#d0cece',
  seven: '#2482e0',
  eight: '#904aad',
  nine: '#69c5fa',
  ten: '#e84430',
} as const

type PeriodDataDay = {
  weekdays: ETChart[] | undefined
  weekends: ETChart[] | undefined
}

type Props = {
  data: ETChart[]
  freq: FreqType
  weatherStations: MeteringPointWeatherStation[]
}

// type X = ETChart['wee']

const ScatterPlot = ({ data, freq, weatherStations }: Props) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const dayData = useMemo(() => {
    if (freq === 'day') {
      const weekdays = data?.filter(
        (item: any) => !item.isWeekend && !item.isHoliday
      )
      const weekends = data?.filter(
        (item: any) => item.isWeekend || item.isHoliday
      )

      const allData: PeriodDataDay = { weekdays: weekdays, weekends: weekends }

      return {
        allData,
        weekdays,
        weekends,
        lastSevenWeekdayData: weekdays?.slice(weekdays?.length - 7),
        lastSevenWeekendData: weekends?.slice(weekends?.length - 7),
      }
    }

    return null
  }, [data, freq])

  const weekData = useMemo(() => {
    if (freq === 'week') {
      if (data[data?.length - 1].isConsumptionComplete === false) {
        const allData = data?.slice(0, data?.length - 1)
        return {
          allData,
          thisWeekData: data?.slice(data?.length - 1, data?.length),
          lastTwoWeeksData: data?.slice(data?.length - 3, data?.length - 1),
        }
      } else {
        return {
          allData: data,
          lastTwoWeeksData: data?.slice(data?.length - 2),
        }
      }
    }

    return null
  }, [data, freq])

  const monthData = useMemo(() => {
    if (freq === 'month') {
      if (data[data?.length - 1].isConsumptionComplete === false) {
        const allData = data?.slice(0, data?.length - 1)
        return {
          allData,
          thisMonthData: data?.slice(data?.length - 1),
        }
      } else {
        return {
          allData: data,
          thisMonthData: data?.slice(data?.length - 1),
        }
      }
    }

    return null
  }, [data, freq])

  // const unparsedPeriodTerm = searchParams.get('period')

  // let periodTerm: DataPeriod = 'month'

  // if (dataPeriods.includes(unparsedPeriodTerm as DataPeriod)) {
  //   periodTerm = unparsedPeriodTerm as DataPeriod
  // }

  // const [period, setPeriod] = useState<PeriodDataDay>({
  //   weekdays: dayData?.allData.weekdays,
  //   weekends: dayData?.allData.weekends,
  // })

  // const [weeklyPeriod, setWeeklyPeriod] = useState<any[]>(
  //   weekData?.weeklyData || []
  // )

  // const [monthlyPeriod, setMonthlyPeriod] = useState<any[]>(
  //   monthData?.monthlyData || []
  // )

  // const periodDataSetter = (period: DataPeriod) => {
  //   searchParams.set('period', period)
  //   setSearchParams(searchParams)

  //   if (freq === 'day') {
  //     let dataStore: PeriodDataDay | undefined

  //     switch (period) {
  //       case 'month':
  //         dataStore = dayData?.lastMonthData
  //         break

  //       case 'threeMonths':
  //         dataStore = dayData?.last3MonthsData
  //         break

  //       case 'sixMonths':
  //         dataStore = dayData?.last6MonthsData
  //         break

  //       case 'year':
  //         dataStore = dayData?.lastYearData
  //         break

  //       default:
  //         dataStore = dayData?.allData
  //         break
  //     }

  //     return setPeriod({
  //       weekdays: dataStore?.weekdays,
  //       weekends: dataStore?.weekends,
  //     })
  //   } else if (freq === 'month') {
  //     let dataStore: any[]

  //     switch (period) {
  //       case 'month':
  //         dataStore = monthData?.lastMonth || []
  //         break
  //       case 'threeMonths':
  //         dataStore = monthData?.last3Months || []
  //         break
  //       case 'sixMonths':
  //         dataStore = monthData?.last6Months || []
  //         break
  //       case 'year':
  //         dataStore = monthData?.lastYear || []
  //         break

  //       default:
  //         dataStore = monthData?.monthlyData || []
  //         break
  //     }

  //     return setMonthlyPeriod(dataStore)
  //   } else {
  //     let dataStore: any[]

  //     switch (period) {
  //       case 'month':
  //         dataStore = weekData?.lastMonth || []
  //         break
  //       case 'threeMonths':
  //         dataStore = weekData?.last3Months || []
  //         break
  //       case 'sixMonths':
  //         dataStore = weekData?.last6Months || []
  //         break
  //       case 'year':
  //         dataStore = weekData?.lastYear || []
  //         break

  //       default:
  //         dataStore = weekData?.weeklyData || []
  //         break
  //     }

  //     return setWeeklyPeriod(dataStore)
  //   }
  // }

  const plotLookup: { [key in FreqType]: () => JSX.Element } = {
    day: () => (
      <>
        <Scatter data={dayData?.weekdays || []} fill={graphColours.DEFAULT} />
        <Scatter data={dayData?.weekends || []} fill={graphColours.two} />
        <Scatter
          data={dayData?.lastSevenWeekdayData || []}
          fill={graphColours.four}
        />
        <Scatter
          data={dayData?.lastSevenWeekendData || []}
          fill={graphColours.seven}
        />
      </>
    ),
    week: () => (
      <>
        <Scatter data={weekData?.allData || []} fill={graphColours.DEFAULT} />
        <Scatter data={weekData?.thisWeekData || []} fill={graphColours.two} />
        <Scatter
          data={weekData?.lastTwoWeeksData || []}
          fill={graphColours.four}
        />
      </>
    ),
    month: () => (
      <>
        <Scatter data={monthData?.allData || []} fill={graphColours.DEFAULT} />
        <Scatter
          data={monthData?.thisMonthData || []}
          fill={graphColours.four}
        />
      </>
    ),
    year: () => <></>,
    hour: () => <></>,
  }

  // const [trendLine, setTrendLine] = useState<TrendLine>('trend')

  const unparsedTrendLine = searchParams.get('trend')

  let trendLine: TrendLine = 'trend'

  if (trendLines.includes(unparsedTrendLine as TrendLine)) {
    trendLine = unparsedTrendLine as TrendLine
  }

  // const trendLineSetter = (trend: TrendLine = 'trend') => {
  //   searchParams.set('trend', trend)
  //   setSearchParams(searchParams)
  // }

  return (
    <div className="mx-auto flex flex-col  md:w-3/4 md:max-w-6xl ">
      {/* <div className="flex justify-end space-x-2">
        <Dropdown
          className="w-36"
          onChange={(value: string) => {}}
          value={periodTerm}
          options={
            [
              {
                value: 'month',
                label: 'Last Month',
              },
              {
                value: 'threeMonths',
                label: 'Last 3 Months',
              },
              {
                value: 'sixMonths',
                label: 'Last 6 Months',
              },
              {
                value: 'year',
                label: 'Last Year',
              },
              {
                value: 'all',
                label: 'All Data',
              },
            ] as { value: DataPeriod; label: string }[]
          }
        />
        <Dropdown
          className="w-44"
          onChange={(value: string) => {
            trendLineSetter(value as TrendLine)
          }}
          value={trendLine}
          options={
            [
              {
                value: 'trendWeekday',
                label: 'Trend Workday',
              },
              {
                value: 'trendWeekend',
                label: 'Trend Weekend',
              },
              {
                value: 'trend',
                label: 'Trend (all)',
              },
            ] as { value: TrendLine; label: string }[]
          }
        />
      </div> */}
      <div className="w-full">
        <ResponsiveContainer width="100%" aspect={2.5}>
          <ComposedChart
            data={data}
            margin={{ top: 20, right: 50, left: 50, bottom: 30 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="temperature"
              type="number"
              name={t('global:temperature')}
            >
              <Label offset={10} position="bottom">
                {`${t('global:temperature')} ${capitalize(
                  weatherStations[0].stationName
                )} (°C)`}
              </Label>
            </XAxis>

            <YAxis
              type="number"
              dataKey="consumption"
              name={t('global:consumption')}
            >
              <Label
                value={`${t('global:consumption')} (kWh)`}
                offset={44}
                angle={270}
                position="left"
                style={{ textAnchor: 'middle' }}
              />
            </YAxis>

            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              content={<CustomTooltip freq={freq} />}
              wrapperStyle={{ outline: 'none' }}
            />

            {plotLookup[freq]()}

            <Line
              dataKey={trendLine}
              stroke={graphColours.DEFAULT}
              dot={false}
              activeDot={false}
              legendType="none"
            />
          </ComposedChart>
        </ResponsiveContainer>

        <CustomLegend freq={freq} trendLine={trendLine} />
      </div>
    </div>
  )
}

export default ScatterPlot

const CustomLegend = ({
  freq,
  trendLine,
}: {
  freq: FreqType
  trendLine: TrendLine
}) => {
  const { t } = useTranslation()

  const legendIndex: { [key in FreqType]: () => JSX.Element } = {
    day: () => (
      <div className=" flex flex-row justify-center space-x-8">
        <div className="flex flex-row items-center">
          <div className="mr-2 h-3 w-3 rounded-full bg-graphSeries" />
          <p>{t('global:workday')}</p>
        </div>
        <div className="flex flex-row items-center">
          <div className="mr-2 h-3 w-3 rounded-full bg-graphSeries-two" />
          <p>{t('global:weekend')}</p>
        </div>
        <div className="flex flex-row items-center">
          <div className="mr-2 h-3 w-3 rounded-full bg-graphSeries-four" />
          <p>{t('consumption:lastSevenWeekday')}</p>
        </div>
        <div className="flex flex-row items-center">
          <div className="mr-2 h-3 w-3 rounded-full bg-graphSeries-seven" />
          <p>{t('consumption:lastSevenWeekend')}</p>
        </div>
        <div className="flex flex-row items-center">
          <div className="mr-2 h-px w-3 bg-graphSeries" />
          <p>{t(`global:${trendLine}`)}</p>
        </div>
      </div>
    ),
    week: () => (
      <div className="flex flex-row justify-center space-x-8">
        <div className="flex flex-row items-center">
          <div className="mr-2 h-3 w-3 rounded-full bg-graphSeries" />
          <p>{`${t(`consumption:breadcrumb.${freq}`)} ${t(
            'global:consumption'
          )}`}</p>
        </div>
        <div className="flex flex-row items-center">
          <div className="mr-2 h-3 w-3 rounded-full bg-graphSeries-two" />
          <p>{t(`consumption:thisWeek`)}</p>
        </div>
        <div className="flex flex-row items-center">
          <div className="mr-2 h-3 w-3 rounded-full bg-graphSeries-four" />
          <p>{t('consumption:lastTwoWeeks')}</p>
        </div>
        <div className="flex flex-row items-center">
          <div className="mr-2 h-px w-3 bg-graphSeries" />
          <p>{t(`global:${trendLine}`)}</p>
        </div>
      </div>
    ),
    month: () => (
      <div className=" flex flex-row justify-center space-x-8">
        <div className="flex flex-row items-center">
          <div className="mr-2 h-3 w-3 rounded-full bg-graphSeries" />
          <p>{`${t(`consumption:breadcrumb.${freq}`)} ${t(
            'global:consumption'
          )}`}</p>
        </div>
        <div className="flex flex-row items-center">
          <div className="mr-2 h-3 w-3 rounded-full bg-graphSeries-four" />
          <p>{t(`consumption:thisMonth`)}</p>
        </div>
        <div className="flex flex-row items-center">
          <div className="mr-2 h-px w-3 bg-graphSeries" />
          <p>{t(`global:${trendLine}`)}</p>
        </div>
      </div>
    ),
    hour: () => <></>,
    year: () => <></>,
  }

  return legendIndex[freq]()
}

const CustomTooltip = ({
  active,
  payload,
  label,
  freq,
}: TooltipProps<ValueType, NameType> & { freq: FreqType }) => {
  const { t } = useTranslation()

  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip rounded-md border border-gray-400 bg-white p-4 text-sm text-gray-800">
        {freq === 'day' ? (
          <p>{dayjs(payload[0]?.payload?.timestamp).format('DD.MM.YYYY')}</p>
        ) : freq === 'week' ? (
          <p>
            {t('global:week')} {dayjs(payload[0]?.payload?.timestamp).isoWeek()}{' '}
            {dayjs(payload[0]?.payload?.timestamp).format('YYYY')}
          </p>
        ) : (
          <p>
            {`${t(
              `global:shortMonths.${dayjs(payload[0]?.payload?.timestamp)
                .format('MMM')
                .toLowerCase()}`
            )} ${dayjs(payload[0]?.payload?.timestamp).format('YYYY')}`}
          </p>
        )}
        <hr className="mt-1" />
        <p className="label mt-1">
          {t(`global:trend`)}:{' '}
          {`${round(payload[0]?.value, 1).toLocaleString('NO')} kWh`}
        </p>
        <p className="label">
          {t('global:consumption')}
          {': '}
          {`${round(payload[0]?.payload?.consumption, 1).toLocaleString(
            'NO'
          )} kWh`}
        </p>
      </div>
    )
  }

  return null
}

const capitalize = (str: any, lower = true) => {
  return (lower ? str.toLowerCase() : str).replace(
    /(?:^|\s|["'([{])+\S/g,
    (match: any) => match.toUpperCase()
  )
}
