import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Label,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
  Line,
  ComposedChart,
  TooltipProps,
} from 'recharts'
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'
import { useAppState } from 'src/context/appstate'
import { MeteringPointBudgetOverview } from 'src/generated/client'
import dayjs from 'dayjs'

interface BudgetGraphData {
  time: string
  budget: number
  forecast: number
  real: number
}

export default function BudgetGraph({
  data,
}: {
  data: MeteringPointBudgetOverview[]
}) {
  const { t } = useTranslation()
  const { showNett } = useAppState()

  const graphData = useMemo<BudgetGraphData[]>(() => {
    return data.map(
      ({ budget, forecast, real, timestamp }): BudgetGraphData => {
        const baseBudget = {
          time: dayjs(timestamp).toString(),
          real: 0,
        }

        if (showNett) {
          return {
            ...baseBudget,
            budget: budget?.totalCost || 0,
            forecast: forecast?.totalCost || 0,
            real: real?.totalCost || 0,
          }
        } else {
          return {
            ...baseBudget,
            budget: budget?.totalGrossCost || 0,
            forecast: forecast?.totalGrossCost || 0,
            real: real?.totalGrossCost || 0,
          }
        }
      }
    )
  }, [data, showNett])

  const yLabel = t(`objects:budgetView.cost`)
  return (
    <div className="mx-auto p-8 md:w-3/4 md:max-w-6xl">
      {graphData.length > 0 && (
        <ResponsiveContainer width="100%" aspect={3}>
          <ComposedChart
            data={graphData}
            margin={{ bottom: 35, left: 60, right: 50 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="time"
              tickFormatter={(value) => xAxisTickFormatter(value, t)}
            >
              <Label value={'Month'} offset={20} position="bottom" />
            </XAxis>

            <YAxis tickFormatter={(value) => value.toLocaleString('NO')}>
              <Label
                value={yLabel}
                offset={50}
                position="left"
                angle={270}
                style={{ textAnchor: 'middle' }}
              />
            </YAxis>
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              content={<BudgetGraphTooltip />}
              wrapperStyle={{ outline: 'none' }}
            />

            <Bar
              dataKey="forecast"
              fill="#53c023"
              stackId="a"
              name={t('objects:budgetView.forecast')}
            />
            <Bar
              dataKey="real"
              fill="#939598"
              stackId="a"
              name={t('objects:budgetView.real')}
            />
            <Line
              type="monotone"
              dataKey="budget"
              stroke="#2482e0 "
              strokeWidth={2}
              name={t('objects:budgetView.budget')}
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
      <CustomLegend />
    </div>
  )
}

const CustomLegend = () => {
  const { t } = useTranslation()

  return (
    <div className="mt-4 flex flex-row justify-center space-x-8">
      <div className="flex flex-row items-center">
        <div className="mr-2 h-3 w-3 rounded-full bg-graphSeries" />
        <p>{t('objects:budgetView.forecast')}</p>
      </div>
      <div className="flex flex-row items-center">
        <div className="mr-2 h-3 w-3 rounded-full bg-graphSeries-three" />
        <p>{t('objects:budgetView.real')}</p>
      </div>
      <div className="flex flex-row items-center">
        <div className="mr-2 h-3 w-3 rounded-full bg-graphBlue" />
        <p>{t('objects:budgetView.budget')}</p>
      </div>
    </div>
  )
}

const xAxisTickFormatter = (value: string, t: any) => {
  return t(`global:shortMonths.${dayjs(value).format('MMM').toLowerCase()}`)
}

const round = (value: any, decimals: number) =>
  Math.round(value * 10 ** decimals) / 10 ** decimals

const BudgetGraphTooltip = ({
  active,
  payload,
}: TooltipProps<ValueType, NameType>) => {
  const { t } = useTranslation()

  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip rounded-md border border-gray-400 bg-white p-4 text-sm text-gray-800">
        <p>
          {`${t(
            `global:shortMonths.${dayjs(payload[0].payload.time)
              .format('MMM')
              .toLowerCase()}`
          )} ${dayjs(payload[0].payload.time).format('YYYY')}`}
        </p>

        <hr className="mt-1" />
        <div className="mt-1 text-gray-800">
          {payload.map((entry, index) => (
            <p key={index}>
              {entry.name}: {round(entry.value, 2).toLocaleString('NO')}
              {' kr'}
            </p>
          ))}
        </div>
      </div>
    )
  }
  return null
}
