import Spinner from '../Spinner'

export function FullScreenLoading() {
  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <Spinner />
    </div>
  )
}

export function Loading() {
  return (
    <div className="flex h-80 w-full items-center justify-center">
      <Spinner />
    </div>
  )
}
