import { useParams } from 'react-router-dom'
import BudgetComponent from 'src/components/BudgetComponent'

const CustomerBudgetPage = () => {
  const customerId = useParams<'customerId'>().customerId as string

  return <BudgetComponent customerId={customerId} />
}

export default CustomerBudgetPage
