export const toBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const parts = (reader.result as any).split(',')
      resolve(parts[parts.length - 1])
    }
    reader.onerror = (error) => reject(error)
  })
}
