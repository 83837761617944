import { type ComponentPropsWithRef, forwardRef } from 'react'
import { XIcon } from '@heroicons/react/outline'

export const CloseButton = forwardRef<
  HTMLButtonElement,
  ComponentPropsWithRef<'button'> & { className?: string }
>((props, ref) => {
  return (
    <button ref={ref} className="focus:outline-none" {...props}>
      <XIcon className="h-5 w-5 cursor-pointer hover:text-gray-500" />
    </button>
  )
})
