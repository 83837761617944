import { FC, HTMLProps, InputHTMLAttributes, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import cn from 'classnames'
import { useAppState } from 'src/context/appstate'
import apiClient from 'src/utils/apiClient'
import Spinner from 'src/components/Spinner'
import { PrimaryButton } from 'src/components/action'
import { formatForInput, format } from './format'
import InvoiceCard from './InvoiceCard'
import { FileTypes, Invoice } from './types'
import { isValidMonth, isValidYear } from './validations'
import {
  applyInvoiceFromServerToInvoice,
  getMonthData,
  isValidInvoice,
  makePostBody,
  checkNaN,
} from './utils'
import FileManager from './File/Manager'

const Form = ({
  invoice: original,
  handleClose,
}: {
  invoice: Invoice
  handleClose: () => void
}) => {
  const objectId = useParams<'objectId'>().objectId as any
  const queryClient = useQueryClient()
  const { t, i18n } = useTranslation()
  const { language } = i18n
  const { showNett } = useAppState()
  const [showCommentBox, setShowCommentBox] = useState(original.comment !== '')
  const [disableSave] = useState(false)
  const [loadingInvoice, setLoadingInvoice] = useState(false)

  // This is so the initial load of the data in the form locale format
  const modifiedOriginalInvoice = {
    ...original,
    elecInvoice: formatForInput(original.elecInvoice, language),
    elecInvoiceGross: formatForInput(original.elecInvoiceGross, language),
    gridInvoice: formatForInput(original.gridInvoice, language),
    gridInvoiceGross: formatForInput(original.gridInvoiceGross, language),
  }

  const [invoice, setInvoice] = useState(modifiedOriginalInvoice)
  const [unsavedFiles, setUnsavedFiles] = useState<FileTypes[]>([])

  const mutation = useMutation(
    async () => {
      // Add the unsaved files first
      const invoiceForSubmission: Invoice = {
        ...invoice,
        file: unsavedFiles,
      } as any

      if (isValidInvoice(invoiceForSubmission)) {
        const invoiceBody = makePostBody(invoiceForSubmission, showNett)
        return await apiClient.saveInvoice(objectId, invoiceBody)
      } else {
        alert('Invalid invoice')
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([objectId, 'invoices'])
        handleClose()
      },
    }
  )

  // Formatter shortcuts
  const oneDecimal = (v: number) => format(v, 1, language)
  const twoDecimals = (v: number) => format(v, 2, language)
  const fourDecimals = (v: number) => format(v, 4, language)

  // Utils
  const setYear = (e: React.ChangeEvent<HTMLInputElement>) =>
    setInvoice((d) => ({ ...d, year: e.target.value }))

  const setMonth = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMonth = parseInt(e.target.value)
    if (newMonth < 1) {
      setInvoice((d) => ({
        ...d,
        year: '' + (parseInt(invoice.year) - 1),
        month: '12',
      }))
    } else if (newMonth > 12) {
      setInvoice((d) => ({
        ...d,
        year: '' + (parseInt(invoice.year) + 1),
        month: '1',
      }))
    } else {
      setInvoice((d) => ({ ...d, month: e.target.value }))
    }
  }

  const setElecInvoice = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvoice((d: any) => ({ ...d, elecInvoice: e.target.value }))
  }

  const setElecInvoiceGross = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvoice((d: any) => ({ ...d, elecInvoiceGross: e.target.value }))
  }

  const setGridInvoice = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvoice((d: any) => ({ ...d, gridInvoice: e.target.value }))
  }

  const setGridInvoiceGross = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvoice((d: any) => ({ ...d, gridInvoiceGross: e.target.value }))
  }

  const setComment = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setInvoice((d) => ({ ...d, comment: e.target.value }))

  const clearComment = () => {
    setInvoice((d) => ({ ...d, comment: '' }))
    setShowCommentBox(false)
  }

  const findInvoice = async () => {
    const dateString = `${invoice.year}-${invoice.month}`
    setLoadingInvoice(true)

    try {
      const monthlyInvoiceFromServer = (
        await getMonthData(dateString, objectId)
      )[0]
      setLoadingInvoice(false)

      const applied = applyInvoiceFromServerToInvoice(
        monthlyInvoiceFromServer,
        invoice as any
      )

      setInvoice((d) => ({ ...d, ...applied } as any))
    } catch (e) {
      setLoadingInvoice(false)
    }
  }

  const onSubmitFiles = (f: any) => {
    setUnsavedFiles(f)
  }

  return (
    <div className="flex flex-col space-y-4">
      <div className="w-full items-center sm:flex sm:space-x-2">
        <Input
          label={t('objects:uploadInvoiceView.year')}
          htmlFor="year"
          value={invoice.year}
          type="number"
          onChange={setYear}
          errorMessage="Invalid year"
          showError={!isValidYear(invoice.year)}
        />

        <Input
          label={t('objects:uploadInvoiceView.month')}
          htmlFor="month"
          value={invoice.month}
          type="number"
          onChange={setMonth}
          errorMessage="Invalid month"
          showError={!isValidMonth(invoice.month)}
        />
        <FormButton onClick={findInvoice} isLoading={loadingInvoice}>
          {t('objects:uploadInvoiceView.findInvoice')}
        </FormButton>
      </div>

      <div className="items-center sm:flex sm:space-x-2">
        {showNett ? (
          <Input
            label={t('objects:uploadInvoiceView.elecInvoice')}
            value={invoice.elecInvoice || ''}
            onChange={setElecInvoice}
            errorMessage="Not a number"
            showError={checkNaN(invoice.elecInvoice)}
          />
        ) : (
          <Input
            label={t('objects:uploadInvoiceView.elecInvoice')}
            value={invoice.elecInvoiceGross || ''}
            onChange={setElecInvoiceGross}
            errorMessage="Not a number"
            showError={checkNaN(invoice.elecInvoiceGross)}
          />
        )}

        {showNett ? (
          <Input
            label={t('objects:uploadInvoiceView.gridInvoice')}
            value={invoice.gridInvoice || ''}
            onChange={setGridInvoice}
            errorMessage="Not a number"
            showError={checkNaN(invoice.gridInvoice)}
          />
        ) : (
          <Input
            label={t('objects:uploadInvoiceView.gridInvoice')}
            value={invoice.gridInvoiceGross || ''}
            onChange={setGridInvoiceGross}
            errorMessage="Not a number"
            showError={checkNaN(invoice.gridInvoiceGross)}
          />
        )}
      </div>

      {showCommentBox || (
        <div>
          <FormButton onClick={() => setShowCommentBox(true)}>
            {t('objects:uploadInvoiceView.addComment')}
          </FormButton>
        </div>
      )}
      {showCommentBox && (
        <div className="sm:col-span-4">
          <label
            htmlFor="about"
            className="block text-sm font-medium text-gray-700"
          >
            {t('objects:uploadInvoiceView.comment')}
          </label>
          <div className="mt-1">
            <textarea
              id="comment"
              name="comment"
              value={invoice.comment}
              onChange={setComment}
              rows={3}
              className="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <p className="mt-2 text-sm text-gray-500">
            {t('objects:uploadInvoiceView.commentHelp')}
          </p>
          <div className="pt-2">
            <FormButton onClick={clearComment}>
              {t('objects:uploadInvoiceView.clearComment')}
            </FormButton>
          </div>
        </div>
      )}

      <FormHeader>{t('objects:uploadInvoiceView.files')}</FormHeader>

      <div className="sm:col-span-6">
        <FileManager
          selectedFiles={invoice.file}
          enabled={true}
          onSubmit={onSubmitFiles}
        />
      </div>
      <FormHeader>{t('objects:uploadInvoiceView.calculated')}</FormHeader>

      <div className="items-center space-y-2 sm:flex sm:space-x-2 sm:space-y-0">
        <Calculated
          label={t('objects:uploadInvoiceView.quantity')}
          value={invoice.quantity}
          formatter={oneDecimal}
          htmlFor="quantity"
        />

        <Calculated
          label={t('objects:uploadInvoiceView.price')}
          value={showNett ? invoice.price : invoice.priceGross}
          formatter={fourDecimals}
          htmlFor="price"
        />

        <Calculated
          label={t('objects:uploadInvoiceView.nettAmount')}
          value={showNett ? invoice.amount : invoice.amountGross}
          formatter={twoDecimals}
          htmlFor="amount"
        />
      </div>

      <div className="items-center space-y-2 sm:flex sm:space-y-0 sm:space-x-2">
        <Calculated
          label={t('objects:uploadInvoiceView.rent')}
          value={showNett ? invoice.rent : invoice.rentGross}
          formatter={twoDecimals}
          htmlFor="amount"
        />

        <Calculated
          label={t('objects:uploadInvoiceView.total')}
          value={showNett ? invoice.total : invoice.totalGross}
          formatter={twoDecimals}
          htmlFor="amount"
        />
      </div>

      <InvoiceCard clickable={false} invoice={invoice as any} />

      <div className="mt-4 flex justify-end">
        <FormButton
          onClick={() => mutation.mutate()}
          disabled={disableSave}
          isLoading={mutation.isLoading}
        >
          {t('objects:uploadInvoiceView.save')}
        </FormButton>
      </div>
    </div>
  )
}

interface InputProps {
  label: string
  value: string | number
  htmlFor?: string
  type?: InputHTMLAttributes<HTMLInputElement>['type']
  showError?: boolean
  errorMessage?: string
  // onChange: (e: FormEvent<HTMLInputElement>) => void
  onChange: (e: any) => void
}

const Input = ({
  label,
  type,
  value,
  htmlFor,
  showError = false,
  errorMessage,
  onChange,
}: InputProps) => (
  <div className="w-full">
    <label
      htmlFor={htmlFor}
      className="block text-sm font-medium text-gray-700"
    >
      {label}
    </label>
    <div className="mt-1">
      <input
        onChange={onChange}
        type={type || 'text'}
        name={htmlFor}
        id={htmlFor}
        value={value}
        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
      />
    </div>
    <span className="text-sm text-red-700">
      {showError ? errorMessage : <>&nbsp;</>}
    </span>
    {/* {showError && errorMessage && (
      <span className="text-sm text-red-700">{errorMessage}</span>
    )} */}
  </div>
)

interface CalculatedProps {
  label: string
  value: number
  htmlFor?: string
  formatter?: (v: number) => string
}

const Calculated = ({
  label,
  value,
  htmlFor,
  formatter = (v) => '' + v,
}: CalculatedProps) => (
  <div className="w-full">
    <label
      htmlFor={htmlFor}
      className="block text-sm font-medium text-gray-700"
    >
      {label}
    </label>
    <div className="mt-1">
      <input
        name={htmlFor}
        type="text"
        disabled={true}
        id={htmlFor}
        value={formatter(value || 0)}
        className="block w-full cursor-not-allowed rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
      />
    </div>
  </div>
)

const FormHeader: FC = ({ children }) => (
  <span className="col-span-6 text-sm font-semibold uppercase tracking-wider text-gray-600">
    {children}
  </span>
)

const FormButton: FC<
  HTMLProps<HTMLButtonElement> & { isLoading?: boolean }
> = ({ onClick, children, isLoading = false, disabled = false }) => {
  if (isLoading) return <Spinner />

  return (
    <PrimaryButton
      type="button"
      className={cn(
        disabled
          ? 'cursor-not-allowed bg-green-50 text-green-900'
          : 'cursor-pointer'
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </PrimaryButton>
  )
}

export default Form
