import { useState } from 'react'
import { Card } from 'src/components/Card'

interface Step2Props {
  currentStep: string
  nextStep: any
  children: any
}

interface Step2State {
  otherContactAddress: boolean
}

const Step2 = ({ currentStep, nextStep, children }: Step2Props) => {
  const initialState = {
    otherContactAddress: false,
  }
  const [state, setState] = useState<Step2State>(initialState)

  const setCheckBox = () => {
    setState((prevState) => ({
      ...prevState,
      otherContactAddress: !state.otherContactAddress,
    }))
  }

  const submitData = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    nextStep(parseInt(currentStep) + 1)
  }

  return (
    <>
      <div className="">
        <br />
        <p className="py-4 pl-3 text-center text-sm">
          Basert på den informasjonen som er registrert på org. nummer du tastet
          inn i forrige sted, har vi fylt ut flere av feltene under. Fyll inn
          den informasjon som mangler eller er feil. (Juridisk navn og org.
          nummer kan ikke endres).
        </p>
        <Card className="m-2 mb-6 w-max">
          <form onSubmit={(e) => submitData(e)}>
            <div className="form-group pt-4 text-center ">
              <div className="pl-3 text-base font-semibold">
                Firmaopplysninger
              </div>

              <div className="form-group flex">
                <div className="m-2 w-52">
                  <input
                    type="text"
                    className="w-full rounded-lg border p-2 text-sm"
                    placeholder="Firmanavn"
                    required
                  />
                </div>
                <div className="m-2 w-52">
                  <input
                    type="number"
                    className="w-full rounded-lg border p-2 text-sm"
                    placeholder="Organisasjonsnummer"
                    required
                  />
                </div>
              </div>
              <div className="form-group flex">
                <div className="m-2 w-52">
                  <input
                    type="tel"
                    className="w-full rounded-lg border p-2 text-sm"
                    placeholder="Hoved telefonnummer"
                    required
                  />
                </div>
                <div className="m-2 w-52">
                  <input
                    type="text"
                    className="w-full rounded-lg border p-2 text-sm"
                    placeholder="Firmaets e-postadresse"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="form-group pt-4 text-center">
              <div className="pl-3 text-base font-semibold">
                Brukerinformasjon
              </div>
              <div className="form-group flex">
                <div className="m-2 w-52">
                  <input
                    type="text"
                    className="w-full rounded-lg border p-2 text-sm"
                    placeholder="Firmaets e-Fornavn"
                    required
                  />
                </div>
                <div className="m-2 w-52">
                  <input
                    type="text"
                    className="w-full rounded-lg border p-2 text-sm"
                    placeholder="Firmaets Etternavn"
                    required
                  />
                </div>
              </div>
              <div className="form-group flex">
                <div className="m-2 w-52">
                  <input
                    type="number"
                    className="w-full rounded-lg border p-2 text-sm"
                    placeholder="Mobiltelefonnummer"
                    required
                  />
                </div>
                <div className="m-2 w-52">
                  <input
                    type="text"
                    className="w-full rounded-lg border p-2 text-sm"
                    placeholder="E-postadresse"
                    required
                  />
                </div>
              </div>
            </div>
            <div
              id="contact-person-fieldset"
              className="form-group pt-4 text-center"
            >
              <div className="m-2-form-label pt-0">
                <div className="row">
                  <div className="pl-3 text-base font-semibold">
                    Annen kontaktperson
                  </div>
                  <div className="form-check m-2">
                    <input
                      type="checkbox"
                      onChange={() => setCheckBox()}
                      className="ml-1"
                    />
                    {/* @Html.CheckBoxFor(m => m.StepData.Step2.DefineAnotherContact, new { @id = "define-contact-checkbox", onclick = "onContactCheckboxClick();" }) */}
                    {/* For="Step2_DefineAnotherContact" */}
                    <label className="ml-2">
                      Kryss av her for å legge til en annen kontaktperson
                    </label>
                  </div>
                </div>
              </div>
              {state.otherContactAddress && (
                <>
                  <div className="form-group flex">
                    <div className="m-2 w-52">
                      <input
                        type="text"
                        className="w-full rounded-lg border p-2 text-sm"
                        placeholder="Fornavn"
                        required
                      />
                    </div>
                    <div className="m-2 w-52">
                      <input
                        type="text"
                        className="w-full rounded-lg border p-2 text-sm"
                        placeholder="Etternavn"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group flex">
                    <div className="m-2 w-52">
                      <input
                        type="number"
                        className="w-full rounded-lg border p-2 text-sm"
                        placeholder="Mobiltelefonnummer"
                        required
                      />
                    </div>
                    <div className="m-2 w-52">
                      <input
                        type="text"
                        className="w-full rounded-lg border p-2 text-sm"
                        placeholder="E-postadresse"
                        required
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="form-group pt-4 text-center">
              <div className="row">
                <div className="m-2 w-52 w-full">
                  <div>
                    <div className="pl-3 text-base font-semibold">
                      Postadresse
                    </div>
                    <div className="form-group flex ">
                      <div className="m-2 w-52">
                        <input
                          type="text"
                          className="w-full rounded-lg border p-2 text-sm"
                          placeholder="C/O"
                          required
                        />
                      </div>
                      <div className="m-2 w-52">
                        <input
                          type="text"
                          className="w-full rounded-lg border p-2 text-sm"
                          placeholder="Adresse"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group flex">
                      <div className="m-2 w-52">
                        <input
                          type="text"
                          className="w-full rounded-lg border p-2 text-sm"
                          placeholder="Postnummer"
                          required
                        />
                      </div>
                      <div className="m-2 w-52">
                        <input
                          type="text"
                          className="w-full rounded-lg border p-2 text-sm"
                          placeholder="Poststed"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="m-2 w-52 w-full">
                  <div className="form-group pt-4 text-center ">
                    <div className="pl-3 text-base font-semibold">
                      Besøkadresse
                    </div>
                    <div className="form-group flex">
                      <div className="m-2 w-52">
                        <input
                          type="text"
                          className="w-full rounded-lg border p-2 text-sm"
                          placeholder="C/O"
                          required
                        />
                      </div>
                      <div className="m-2 w-52">
                        <input
                          type="text"
                          className="w-full rounded-lg border p-2 text-sm"
                          placeholder="Adresse"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group flex">
                      <div className="m-2 w-52">
                        <input
                          type="text"
                          className="w-full rounded-lg border p-2 text-sm"
                          placeholder="Postnummer"
                          required
                        />
                      </div>
                      <div className="m-2 w-52">
                        <input
                          type="text"
                          className="w-full rounded-lg border p-2 text-sm"
                          placeholder="Poststed"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {children}
          </form>
        </Card>
      </div>
    </>
  )
}

export default Step2
