import type { ComponentPropsWithRef } from 'react'
import { forwardRef } from 'react'
import cn from 'classnames'
import { CircleBackslashIcon, UpdateIcon } from '@radix-ui/react-icons'

import type { HeroIcon, RadixIcon } from 'src/types'
import { actionStyles } from '../actionStyles'

export const Button = forwardRef<
  HTMLButtonElement,
  ComponentPropsWithRef<'button'> & { className?: string }
>((props, ref) => {
  return (
    <button
      ref={ref}
      {...props}
      className={cn(actionStyles, props.disabled || props.className, {
        'border border-gray-400 text-gray-500': props.disabled,
      })}
    >
      {props.children}
    </button>
  )
})

export type ActionButtonProps = ComponentPropsWithRef<'button'> & {
  isSubmitting?: boolean
  submittingText?: string
  iconClassName?: string
  Icon?: RadixIcon | HeroIcon
}

export const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  ({ isSubmitting, submittingText, Icon, iconClassName, ...props }, ref) => {
    return (
      <Button ref={ref} {...props}>
        <span className="mx-auto flex flex-row items-center space-x-1">
          {props.disabled && <CircleBackslashIcon />}
          {isSubmitting ? (
            <>
              <span className="flex flex-col justify-center">
                <UpdateIcon />
              </span>
              <span>{submittingText}</span>
            </>
          ) : (
            <>
              {!props.disabled && Icon && (
                <span className="flex flex-col justify-center">
                  <Icon width={18} height={18} className={iconClassName} />
                </span>
              )}

              <span className="w-full text-center">{props.children}</span>
            </>
          )}
        </span>
      </Button>
    )
  }
)

export default Button
