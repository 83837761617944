import { DocumentDuplicateIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'

import InvoiceAttribute from './Attribute'
import useInvoiceCalculator from './useInvoiceCalculator'
import { format } from './format'
import { Invoice } from './types'
import cn from 'classnames'

interface Props {
  invoice: Invoice
  clickable?: boolean
  onClick?: () => void
}

const InvoiceCard = ({
  invoice,
  clickable = true,
  onClick = () => null,
}: Props) => {
  // i18n
  const { t, i18n } = useTranslation()
  const { language } = i18n

  // Build a calculator from the invoice
  const calculator = useInvoiceCalculator(invoice)

  // Formatter shortcuts
  const twoDecimals = (v: number) => format(v, 2, language)
  const fourDecimals = (v: number) => format(v, 4, language)

  return (
    <div
      className={cn(
        'flex flex-col space-y-2 rounded-lg bg-gray-50 px-5 pt-3 pb-5 shadow-md',
        clickable && 'cursor-pointer'
      )}
      onClick={onClick}
    >
      <div className="flex items-center space-x-3 text-xs font-semibold uppercase text-gray-700">
        <TimeLabel invoice={invoice} />
        {invoice.file.length > 0 && <FileLabel invoice={invoice} />}
      </div>
      <div className="w-full items-center justify-between space-y-3 sm:flex sm:space-y-0">
        {/* Grid Difference */}
        <InvoiceAttribute
          label={t('objects:uploadInvoiceView.gridDiff')}
          unit="kr"
          ng={calculator.gridDifference()}
          formatter={twoDecimals}
        />

        {/* Electricity Difference */}
        <InvoiceAttribute
          label={t('objects:uploadInvoiceView.elecDiff')}
          unit="kr"
          ng={calculator.elecDifference()}
          formatter={twoDecimals}
        />

        {/* Average Profit */}
        <InvoiceAttribute
          label={t('objects:uploadInvoiceView.averageProfit')}
          unit="kr"
          ng={calculator.averageProfit()}
          formatter={fourDecimals}
        />

        {/* Average */}
        <InvoiceAttribute
          label={t('objects:uploadInvoiceView.average')}
          unit="kr/kWh"
          ng={calculator.average()}
          formatter={fourDecimals}
        />

        {/* Average Grid */}
        <InvoiceAttribute
          label={t('objects:uploadInvoiceView.averageGrid')}
          unit="kr/kWh"
          ng={calculator.averageGrid()}
          formatter={fourDecimals}
        />

        {/* Average Total */}
        <InvoiceAttribute
          label={t('objects:uploadInvoiceView.averageTotal')}
          unit="kr/kWh"
          ng={calculator.averageTotal()}
          formatter={fourDecimals}
        />
      </div>
    </div>
  )
}

const TimeLabel = ({ invoice }: { invoice: Invoice }) => (
  <span className="">
    {invoice.year} / {invoice.month}
  </span>
)

const FileLabel = ({ invoice }: { invoice: Invoice }) => (
  <>
    <span>-</span>
    <div className="flex items-center space-x-1">
      <DocumentDuplicateIcon className="h-3 w-3" />
      <span>
        {invoice.file.length} file{invoice.file.length === 1 || 's'}
      </span>
    </div>
  </>
)

export default InvoiceCard
