import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { Table } from 'src/components/Table'
import APIClient from '../utils/apiClient'
import dayjs from 'dayjs'
import { TextPlaceholder } from 'src/components/TextPlaceholder'
import FormatNumber from './FormatNumber'
import { getFooterForColumn } from '../utils/helpers/helpers'
import { useAppState } from '../context/appstate'

type GridCostProps = {
  queryType: 'customer' | 'meter'
  objectId?: string
  customerId?: string
  query?: any
  start: any
  end: any
  aggregationType?: string
}

// "plantId": 22,
// "fromDate": "2021-04-01T00:00:00",
// "toDate": "2021-05-01T00:00:00",
// "elementDescription": "Forbruksavgift",
// "price": 0.1669,
// "quantity": 358.835,
// "amount": 59.8895,
// "vat": 0.250

const GridCostComponent = (props: GridCostProps) => {
  const { t } = useTranslation()
  const { showNett } = useAppState()
  const { queryType, objectId, customerId, start, end, aggregationType } = props

  const { isLoading, data, error } = useQuery(
    [`gridCost`, queryType, objectId, customerId, start, end, aggregationType],
    async () => {
      const s = dayjs(props.start).format('YYYY-MM-DD')
      const e = dayjs(props.end).format('YYYY-MM-DD')
      if (props.queryType === 'customer' && customerId) {
        return APIClient.getCustomerGridCost(customerId, s, e, aggregationType)
      } else if (objectId) {
        return APIClient.getGridCost(objectId, s, e, aggregationType)
      }
    }
  )

  const consumptionColumns = useMemo(
    () => [
      {
        Header: t('grid:description'),
        accessor: 'elementDescription',
        Loader: (props: any) => <TextPlaceholder min={12} max={22} />,
      },
      {
        Header: t('grid:from'),
        accessor: 'fromDate',
        Loader: (props: any) => <TextPlaceholder min={6} max={12} />,
        Cell: (props: any) => {
          return (
            <p className="tracking-wider">
              {dayjs(props.cell.value).format('DD.MM.YYYY')}
            </p>
          )
        },
      },
      {
        Header: t('grid:to'),
        accessor: 'toDate',
        Loader: (props: any) => <TextPlaceholder min={6} max={12} />,
        Cell: (props: any) => {
          return (
            <p className="tracking-wider">
              {dayjs(props.cell.value).format('DD.MM.YYYY')}
            </p>
          )
        },
      },
      {
        Header: t('objects:tableModalHeaders.quantity'),
        accessor: 'quantity',
        Loader: (props: any) => <TextPlaceholder min={6} max={12} />,
        Cell: (props: any) => {
          return (
            <>
              <p className="text-right tracking-wider">
                <FormatNumber number={props.cell.value} decimals={1} />
              </p>
            </>
          )
        },
      },
      {
        Header: t('objects:tableModalHeaders.unit'),
        accessor: 'unit',
        Loader: (props: any) => <TextPlaceholder min={6} max={12} />,
      },
      {
        Header: t('objects:tableModalHeaders.price'),
        accessor: showNett ? 'price' : 'grossPrice',
        Loader: (props: any) => <TextPlaceholder min={6} max={12} />,
        Cell: (props: any) => {
          return (
            <>
              <p className="text-right tracking-wider">
                <FormatNumber
                  number={props.cell.value}
                  decimals={props.cell.value >= 1 ? 2 : 4}
                />
              </p>
            </>
          )
        },
      },
      {
        Header: t('objects:tableModalHeaders.amount'),
        accessor: showNett ? 'amount' : 'grossAmount',
        Loader: (props: any) => <TextPlaceholder min={12} max={22} />,
        Cell: (props: any) => {
          return (
            <>
              <p className="text-right tracking-wider">
                <FormatNumber number={props.cell.value} decimals={2} />
              </p>
            </>
          )
        },
        Footer: ({ data }: any) => {
          var num = getFooterForColumn(
            'sum',
            data,
            showNett ? 'amount' : 'grossAmount'
          )
          return (
            <p className="mt-4 whitespace-nowrap px-2 text-right text-base font-bold tracking-widest">
              <FormatNumber number={num} decimals={2} />
            </p>
          )
        },
      },
      // {
      //   Header: t('grid:vat'),
      //   accessor: 'vat',
      //   Loader: (props: any) => <TextPlaceholder min={12} max={22} />,
      //   Cell: (props: any) => {
      //     return `${props.cell.value * 100}`
      //   },
      // },
    ],
    [showNett, t]
  )

  if (error) {
    console.error(error)
    return <p>Error. Check console.</p>
  }

  return (
    <div className="mt-6 space-y-2">
      {/* <div className="md:pl-6 md:pr-6 ">
        <span className="text-sm text-gray-500">
          {t('consumption:forMonth')}:{' '}
        </span>
        <span>{dayjs(props.start).format('MMMM YYYY')}</span>
      </div> */}

      <Table
        loading={isLoading}
        loadingPlaceholderRows={3}
        columns={consumptionColumns}
        data={data}
        enableSorting={true}
        onRowClick={() => {}}
        tableStyleOverride="ml-6 md:ml-0 table-fixed md:table-auto pb-0"
        trStyleOverride="cursor-auto"
        thStyleOverride="first:text-left text-right text-xs text-gray-500 font-medium"
        tdStyleOverride="first:text-left text-right px-3 first:w-10 first:max-w-lg first:w-96"
      />
    </div>
  )
}

export default GridCostComponent
