/**
 * Calculate the Electricity Difference
 *
 * * elecDiff: row.elecInvoice ? row.elecInvoice - row.amount
 * * elecDiffGross: row.elecInvoiceGross ? row.elecInvoiceGross - row.amountGross
 */

import { Invoice, NettGross } from '../types'
import { formatNumberInput } from '../utils'

const calculateElecDifferenceNett = (invoice: Invoice) => {
  if (invoice.elecInvoice) {
    if (!invoice.amount) return formatNumberInput(invoice.elecInvoice)

    return (
      formatNumberInput(invoice.elecInvoice) - formatNumberInput(invoice.amount)
    )
  }

  return 0
}

const calculateElecDifferenceGross = (invoice: Invoice) => {
  if (invoice.elecInvoiceGross) {
    if (!invoice.amountGross) return formatNumberInput(invoice.elecInvoiceGross)

    return (
      formatNumberInput(invoice.elecInvoiceGross) -
      formatNumberInput(invoice.amountGross)
    )
  }

  return 0
}

export const elecDifference = (invoice: Invoice): NettGross => ({
  nett: calculateElecDifferenceNett(invoice),
  gross: calculateElecDifferenceGross(invoice),
})
