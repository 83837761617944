import { useParams } from 'react-router-dom'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import cn from 'classnames'

import APIClient from 'src/utils/apiClient'
import { useAppState } from 'src/context/appstate'

import type { MeteringPointConsumptionCost } from 'src/generated/client'
import type { FreqType } from 'src/types'

import useDateChanger from 'src/utils/hooks/useDateChanger'

import { Table } from 'src/components/Table'
import { TextPlaceholder } from 'src/components/TextPlaceholder'
import FrequencyChanger from 'src/components/FrequencyChanger'
import FormatNumber from 'src/components/FormatNumber'
import DateChanger from 'src/components/DateChanger'
import Spinner from 'src/components/Spinner'
import { PeakGraph } from 'src/components/Peak'
import useDateRange from 'src/utils/hooks/useDateRange'
import HelpModal from 'src/components/HelpModal/HelpModal'
import { QuestionMarkCircleIcon } from '@heroicons/react/outline'
import { ActionButton } from 'src/components/action'
import { GraphPlaceholder } from 'src/components/GraphPlaceholder'

require('dayjs/locale/nb')

const freqOptions = ['month', 'day', 'hour']

const Peak = () => {
  const { t } = useTranslation()
  const { showNett } = useAppState()

  const objectId = useParams<'objectId'>().objectId as string

  const [showHelpModal, setShowHelpModal] = useState(false)

  const defaultFreq: FreqType = 'month'
  const defaultFrom: string = dayjs().startOf('year').format('YYYY-MM-DD')
  const defaultTo: string = dayjs()
    .add(1, 'year')
    .startOf('year')
    .format('YYYY-MM-DD')

  const { freq, from, to, setDateRange } = useDateRange({
    defaultFreq: defaultFreq,
    defaultFrom: defaultFrom,
    defaultTo: defaultTo,
  })

  const { dateDisplay, disablePrev, disableNext } = useDateChanger()

  const { isLoading, data } = useQuery(
    ['Peak', objectId, freq, from, to],
    () => APIClient.getMeteringPointPeak(objectId, from, to, freq),
    {
      enabled: !!objectId,
    }
  )

  const freqChangeHandler = (value: FreqType) => {
    let tmpFrom = from
    let tmpTo = to
    let tmpFreq = value

    if (value === 'month') {
      tmpFrom = dayjs().startOf('year').format('YYYY-MM-DD')
      tmpTo = dayjs().add(1, 'year').startOf('year').format('YYYY-MM-DD')
    } else if (value === 'day') {
      if (dayjs().date() === 1) {
        tmpFrom = dayjs()
          .startOf('month')
          .subtract(1, 'month')
          .format('YYYY-MM-DD')
        tmpTo = dayjs(tmpFrom)
          .add(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD')
      } else {
        tmpFrom = dayjs().startOf('month').format('YYYY-MM-DD')
        tmpTo = dayjs(tmpFrom)
          .add(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD')
      }
    } else if (value === 'hour') {
      tmpFrom = dayjs().subtract(1, 'day').startOf('day').format('YYYY-MM-DD')
      tmpTo = dayjs(tmpFrom).add(1, 'day').startOf('day').format('YYYY-MM-DD')
    }

    setDateRange({
      freq: tmpFreq,
      from: tmpFrom,
      to: tmpTo,
    })
  }

  const dateChangeHandler = (value: string) => {
    let tmpFrom = from
    let tmpTo = to

    let changeByFreq: FreqType = 'month'
    if (freq === 'month') {
      changeByFreq = 'year'
    } else if (freq === 'day') {
      changeByFreq = 'month'
    } else if (freq === 'hour') {
      changeByFreq = 'day'
    }
    if (value === 'previous') {
      tmpFrom = dayjs(tmpFrom).subtract(1, changeByFreq).format('YYYY-MM-DD')
      tmpTo = dayjs(tmpTo)
        .subtract(1, changeByFreq)
        .startOf(changeByFreq)
        .format('YYYY-MM-DD')
    }
    if (value === 'next') {
      tmpFrom = dayjs(tmpFrom).add(1, changeByFreq).format('YYYY-MM-DD')
      tmpTo = dayjs(tmpTo).add(1, changeByFreq).format('YYYY-MM-DD')
    }
    setDateRange({
      freq,
      from: tmpFrom,
      to: tmpTo,
    })
  }

  const onRowClickHanler = useCallback(
    (v: string) => {
      let tmpFrom = from
      let tmpTo = to
      let tmpFreq = freq

      let value = dayjs(v)
      if (dayjs(value).isBefore(dayjs())) {
        if (freq === 'year') {
          tmpFreq = 'month'
          tmpFrom = dayjs(value).startOf('year').format('YYYY-MM-DD')
          tmpTo = dayjs(value)
            .add(1, 'year')
            .startOf('year')
            .format('YYYY-MM-DD')
        } else if (freq === 'month') {
          tmpFreq = 'day'
          tmpFrom = dayjs(value).startOf('month').format('YYYY-MM-DD')
          tmpTo = dayjs(value)
            .add(1, 'month')
            .startOf('month')
            .format('YYYY-MM-DD')
        } else if (freq === 'day') {
          tmpFreq = 'hour'
          tmpFrom = dayjs(value).startOf('day').format('YYYY-MM-DD')
          tmpTo = dayjs(value).add(1, 'day').startOf('day').format('YYYY-MM-DD')
        }

        setDateRange({ freq: tmpFreq, from: tmpFrom, to: tmpTo })
      }
    },
    [freq, to, from, setDateRange]
  )

  const initialTableState = useMemo(() => ({}), [])

  const meteringpointPeakColumns = useMemo(() => {
    const timestampCol = {
      Header: t('objects:peak.peakTableHeaders.timestamp'),
      accessor: 'timestamp',
      Loader: (props: any) => <TextPlaceholder min={12} max={22} />,
      Cell: (props: any) => {
        const row = props.row.original as MeteringPointConsumptionCost

        return (
          <div className="flex items-center">
            <span
              onClick={() => onRowClickHanler(String(row.timestamp))}
              className={cn(
                freq === 'hour'
                  ? 'tracking-wider'
                  : 'cursor-pointer tracking-wider hover:underline'
              )}
            >
              {freq === 'year'
                ? dayjs(props.cell.value).format('YYYY')
                : freq === 'month'
                ? dayjs(props.cell.value).format('MM.YYYY')
                : freq === 'day'
                ? dayjs(props.cell.value).format('DD.MM.YYYY')
                : dayjs(props.cell.value).format('DD.MM.YYYY HH:mm')}
            </span>
            <span className="relative w-4 pr-4">
              {row.isConsumptionComplete === false && (
                <span className="text-gray-500">*</span>
              )}
            </span>

            {row.consumptionQuality && (
              <ConsumptionQuality quality={row.consumptionQuality} />
            )}
          </div>
        )
      },
    }

    const measuredCol = {
      Header: `${t('objects:peak.peakTableHeaders.measured')}`,
      accessor: 'measured',
      className: 'text-right w-40 max-w-lg',
      Loader: (props: any) => <TextPlaceholder min={3} max={10} />,
      Cell: (props: any) => {
        let field = props.cell.value
        return (
          <p
            onClick={() => onRowClickHanler(props.row.original.timestamp)}
            className={cn(
              'text-right tracking-wider',
              freq !== 'hour' && 'cursor-pointer hover:underline',
              props.row.original.isPeak && 'font-bold text-gray-600',
              props.row.original.isPayable && 'font-bold text-black'
              // props.row
            )}
          >
            <FormatNumber number={field} decimals={field < 10 ? 3 : 1} />
          </p>
        )
      },
    }

    const payableAverageCol = {
      Header: `${t('objects:peak.peakTableHeaders.payableAverage')}`,
      accessor: 'payableAverage',
      className: 'text-right w-52 max-w-lg',
      Loader: (props: any) => <TextPlaceholder min={3} max={10} />,
      Cell: (props: any) => {
        let field = props.cell.value

        return (
          <p
            onClick={() => onRowClickHanler(props.row.original.timestamp)}
            className={cn(
              freq === 'hour'
                ? 'w- text-right tracking-wider'
                : 'cursor-pointer text-right tracking-wider hover:underline',
              props.row.original.limitUp &&
                props.row.original.payableAverage >
                  props.data[Math.max(0, props.cell.row.index - 1)].limitUp &&
                'font-bold text-red-600',
              props.row.original.payableAverage >=
                Math.max(
                  ...props.data.map((item: any) => item.payableAverage)
                ) &&
                props.row.original.payableAverage !==
                  props.data[Math.max(0, props.cell.row.index - 1)]
                    .payableAverage &&
                'font-bold text-yellow-500',
              props.row.original.payableAverage >=
                Math.max(
                  ...props.data.map((item: any) => item.payableAverage)
                ) &&
                props.cell.row.index === 0 &&
                'font-bold text-yellow-500'
            )}
          >
            <FormatNumber number={field} decimals={field < 10 ? 3 : 1} />
          </p>
        )
      },
    }

    const lastYearCol = {
      Header: `${t('objects:peak.peakTableHeaders.measuredLastYear')}`,
      accessor: 'measuredLastYear',
      className: 'text-right w-52 max-w-lg',
      Loader: (props: any) => <TextPlaceholder min={3} max={10} />,
      Cell: (props: any) => {
        let field = props.cell.value
        return field ? (
          <p
            onClick={() => onRowClickHanler(props.row.original.timestamp)}
            className={
              freq === 'hour'
                ? 'text-right tracking-wider'
                : 'cursor-pointer text-right tracking-wider hover:underline'
            }
          >
            <FormatNumber number={field} decimals={field < 10 ? 3 : 1} />
          </p>
        ) : null
      },
    }

    if (freq === 'month') {
      return [timestampCol, measuredCol, lastYearCol, payableAverageCol]
    } else if (freq === 'day') {
      return [timestampCol, measuredCol, payableAverageCol]
    } else if (freq === 'hour') {
      return [timestampCol, measuredCol, payableAverageCol]
    } else {
      return [timestampCol, measuredCol]
    }
  }, [freq, t, onRowClickHanler])

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-3">
        <div></div>

        <FrequencyChanger
          freqOptions={freqOptions}
          freqChangeHandler={freqChangeHandler}
          freq={freq}
        />

        <div className="hidden items-center justify-end sm:flex">
          <div className="block">
            <ActionButton
              type="button"
              onClick={() => setShowHelpModal(true)}
              Icon={QuestionMarkCircleIcon}
            >
              {t('global:help')}
            </ActionButton>
          </div>
        </div>
      </div>

      <div className="hidden sm:block">
        {isLoading ? (
          <div className="flex h-80 w-full items-center justify-center">
            <Spinner />
          </div>
        ) : data ? (
          data && (
            <PeakGraph
              data={data}
              freq={freq}
              showNett={showNett}
              onGraphClick={onRowClickHanler}
            />
          )
        ) : (
          <GraphPlaceholder dataType="Peak" />
        )}
      </div>

      <DateChanger
        dateChangeHandler={dateChangeHandler}
        dateDisplay={dateDisplay}
        disablePrev={disablePrev}
        disableNext={disableNext}
      />

      <Table
        loading={isLoading}
        loadingPlaceholderRows={3}
        columns={meteringpointPeakColumns}
        initialState={initialTableState}
        data={data}
        enableSorting={true}
        onRowClick={(rowData: any) => null} //onRowClickHanler(rowData['timestamp'])}
        tableStyleOverride="ml-6 md:ml-0 table-fixed md:table-auto"
        thStyleOverride="text-base text-gray-500 font-medium"
        tdStyleOverride="px-3 first:w-10 first:max-w-lg first:w-96"
      />

      <HelpModal
        isOpen={showHelpModal}
        onClose={() => setShowHelpModal(false)}
        helpVideoUrl={t('objects:peak.helpVideoUrl')}
        helpText={t('objects:peak.helpText')}
      />
    </>
  )
}

const ConsumptionQuality = ({ quality }: { quality: number }) => {
  const { t } = useTranslation()
  let color = 'text-green-600'
  let tooltip = t('objects:consumptionQuality.measured')

  let Icon = CheckCircleIcon

  switch (quality) {
    case 2:
      color = 'text-yellow-600'
      tooltip = t('objects:consumptionQuality.estimated')
      break
    case 3:
      color = 'text-red-600'
      tooltip = t('objects:consumptionQuality.temporary')
      Icon = ExclamationCircleIcon
      break
  }
  return (
    <div className="group">
      <span className="invisible absolute z-50 -mt-8 rounded bg-black p-1 px-2 text-xs font-medium text-white shadow-lg group-hover:visible">
        {tooltip}
      </span>
      <Icon className={cn('h-4 cursor-pointer', color)} />
    </div>
  )
}

export default Peak
