import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { Table } from 'src/components/Table'
import APIClient from '../utils/apiClient'
import dayjs from 'dayjs'
import { TextPlaceholder } from 'src/components/TextPlaceholder'
import FormatNumber from './FormatNumber'
import { getFooterForColumn } from '../utils/helpers/helpers'
import { useAppState } from '../context/appstate'
import { MeteringPointPowerAgreementCost } from 'src/generated/client'
import { FieldQuality } from './Consumption'

type PowerAgreementProps = {
  queryType: 'customer' | 'meter'
  objectId?: string
  customerId?: string
  query?: any
  start: any
  end: any
  aggregationType?: string
}

const PowerAgreementComponent = (props: PowerAgreementProps) => {
  const { t } = useTranslation()
  const { showNett } = useAppState()
  const { queryType, objectId, customerId, start, end, aggregationType } = props

  const { isLoading, data, error } = useQuery(
    [
      'powerAgreement',
      objectId,
      queryType,
      customerId,
      start,
      end,
      aggregationType,
    ],
    async () => {
      const s = dayjs(start).format('YYYY-MM-DD')
      const e = dayjs(end).format('YYYY-MM-DD')
      if (props.queryType === 'customer' && customerId) {
        return APIClient.getCustomerPowerAgreementCost(
          customerId,
          s,
          e,
          aggregationType
        )
      } else if (objectId) {
        return APIClient.getPowerAgreementCost(objectId, s, e, aggregationType)
      }
    }
  )

  const consumptionColumns = useMemo(
    () => [
      {
        Header: t('grid:description'),
        accessor: 'elementDescription',
        Loader: (props: any) => <TextPlaceholder min={12} max={22} />,
        Cell: (props: any) => {
          const row = props.row.original as MeteringPointPowerAgreementCost
          return (
            <div className="flex flex-row space-x-2">
              <span className="">{props.cell.value}</span>
              <span>
                {row.quality && (
                  <FieldQuality quality={row.quality} type="powerAgreement" />
                )}
              </span>
            </div>
          )
        },
      },
      {
        Header: t('grid:from'),
        accessor: 'fromDate',
        Loader: (props: any) => <TextPlaceholder min={6} max={12} />,
        Cell: (props: any) => {
          return (
            <p className="tracking-wider">
              {dayjs(props.cell.value).format('DD.MM.YYYY')}
            </p>
          )
        },
      },
      {
        Header: t('grid:to'),
        accessor: 'toDate',
        Loader: (props: any) => <TextPlaceholder min={6} max={12} />,
        Cell: (props: any) => {
          return (
            <p className="tracking-wider">
              {dayjs(props.cell.value).format('DD.MM.YYYY')}
            </p>
          )
        },
      },
      {
        Header: t('objects:tableModalHeaders.quantity'),
        accessor: 'quantity',
        Loader: (props: any) => <TextPlaceholder min={6} max={12} />,
        Cell: (props: any) => {
          return (
            <>
              <p className="text-right tracking-wider">
                <FormatNumber number={props.cell.value} decimals={1} />
              </p>
            </>
          )
        },
      },
      {
        Header: t('objects:tableModalHeaders.unit'),
        accessor: 'unit',
        Loader: (props: any) => <TextPlaceholder min={6} max={12} />,
      },
      {
        Header: t('objects:tableModalHeaders.price'),
        accessor: showNett ? 'price' : 'grossPrice',
        Loader: (props: any) => <TextPlaceholder min={6} max={12} />,
        Cell: (props: any) => {
          return (
            <>
              <p className="text-right tracking-wider">
                <FormatNumber
                  number={props.cell.value}
                  decimals={props.cell.value >= 1 ? 2 : 4}
                />
              </p>
            </>
          )
        },
      },
      {
        Header: t('objects:tableModalHeaders.amount'),
        accessor: showNett ? 'amount' : 'grossAmount',
        Loader: (props: any) => <TextPlaceholder min={6} max={12} />,
        Cell: (props: any) => {
          return (
            <>
              <p className="text-right tracking-wider">
                <FormatNumber number={props.cell.value} decimals={2} />
              </p>
            </>
          )
        },
        Footer: ({ data }: any) => {
          var num = getFooterForColumn(
            'sum',
            data,
            showNett ? 'amount' : 'grossAmount'
          )
          return (
            <p className="mt-4 whitespace-nowrap px-2 text-right text-base font-bold tracking-widest">
              <FormatNumber number={num} decimals={2} />
            </p>
          )
        },
      },
    ],
    [showNett, t]
  )

  if (error) {
    console.error(error)
    return <p>Error. Check console.</p>
  }

  return (
    <div className="mt-6 space-y-2">
      <Table
        loading={isLoading}
        loadingPlaceholderRows={3}
        columns={consumptionColumns}
        data={data}
        enableSorting={true}
        onRowClick={() => {}}
        tableStyleOverride="ml-6 md:ml-0 table-fixed md:table-auto pb-0"
        trStyleOverride="cursor-auto"
        thStyleOverride="first:text-left text-right text-xs text-gray-500 font-medium"
        tdStyleOverride="first:text-left text-right px-3 first:w-10 first:max-w-lg first:w-96"
      />
    </div>
  )
}

export default PowerAgreementComponent
