import { useEffect, useRef, useState, Fragment } from 'react'
import { Disclosure } from '@headlessui/react'
import {
  MenuAlt3Icon as Bars3Icon,
  XIcon as XMarkIcon,
  CogIcon,
} from '@heroicons/react/outline'
import { useLocation, useNavigate } from 'react-router-dom'

import LanguageToggleComponent from '../../components/LanguageToggleComponent'
import NettToggleComponent from '../../components/NettToggleComponent'
import { UserDropdown } from '../../components/UserDropdown'
import { PrimaryButton } from 'src/components/action'
import { useTranslation } from 'react-i18next'

export interface UserContainer {
  userName: string
  displayName: string
  eMail: string
  initials?: string | null
}

interface NavBarComponentProps {
  user?: UserContainer
  isLoading?: boolean
}

export function Navbar(props: NavBarComponentProps) {
  //  --- Variables ---
  const { t } = useTranslation()
  const dropdownButtonReference = useRef<any>()
  const [language, setLanguage] = useState<string>()
  // const [dropdownVisibility] = useState<number>(0)
  const dropdownVisibility = 0
  const navigate = useNavigate()
  const location = useLocation()

  //  --- Functionality ---
  // const updateVisibility = useCallback(() => {
  //   if (dropdownVisibility < 100) setDropdownVisibility(dropdownVisibility + 1)
  //   else setDropdownVisibility(1)
  // }, [dropdownVisibility])

  //  --- Setup ---
  useEffect(() => {
    let lang = localStorage.getItem('lang') as string
    setLanguage(lang)
  }, [setLanguage])

  const goHome = () => navigate(`/App`)

  const handleCogButtonClick = () => {
    if (location.pathname === '/App/Settings') {
      navigate(-1)
    } else {
      navigate('/App/Settings')
    }
  }

  //  --- Response ---

  return (
    <Disclosure as="nav" className="sm:py-3">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div
                  onClick={goHome}
                  className="flex flex-shrink-0 cursor-pointer items-center"
                >
                  <img
                    className="h-8 w-auto sm:h-12"
                    src="/images/StromRadar_logo_liggende@4x-8.png"
                    alt="Your Company"
                  />
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:flex-row sm:items-center sm:space-x-2">
                <UserDropdown
                  externalVisibiltyToggle={dropdownVisibility}
                  dropdownButtonReference={dropdownButtonReference}
                />

                <div
                  ref={dropdownButtonReference}
                  className="roboto-regular inline-block items-center rounded px-2 py-1 text-black"
                  // onClick={updateVisibility}
                >
                  <span className="account-info">
                    <p className="float-right flex h-0 text-xs font-semibold not-italic md:text-base">
                      {props.user && props.user.displayName}
                    </p>
                    <br />
                    <p className="h-5 text-xs font-semibold not-italic text-gray-400 md:text-base">
                      {props.user && props.user.userName}
                    </p>
                  </span>
                </div>
                <button
                  className="w-6 text-gray-600 hover:text-gray-800"
                  onClick={() => handleCogButtonClick()}
                >
                  <CogIcon />
                </button>

                <NettToggleComponent />

                {language != null ? (
                  <LanguageToggleComponent
                    language={language}
                    translate={(language) => {
                      localStorage.setItem('lang', language)
                      window.location.reload()
                    }}
                  />
                ) : null}
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                <Disclosure.Button className="focus:outline-none inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-lime">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="border-t border-gray-200 pt-4 pb-3">
              <div className="flex items-center border-b px-4 pb-4">
                <div className="">
                  <div className="text-base font-medium text-gray-800">
                    {props.user && props.user.displayName}
                  </div>
                  <div className="text-sm font-medium text-gray-500">
                    {props.user && props.user.userName}
                  </div>
                </div>
                <button
                  className="ml-4 w-6 text-gray-600 hover:text-gray-800"
                  onClick={() => handleCogButtonClick()}
                >
                  <CogIcon />
                </button>
              </div>
              <div className="mt-3 flex flex-row items-center space-x-1 border-b px-2 pb-3">
                <NettToggleComponent />

                {language != null ? (
                  <LanguageToggleComponent
                    language={language}
                    translate={(language) => {
                      localStorage.setItem('lang', language)
                      window.location.reload()
                    }}
                  />
                ) : null}

                <PrimaryButton
                  onClick={() => (window.location.pathname = '/api/logout')}
                >
                  {t('global:logout')}
                </PrimaryButton>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
