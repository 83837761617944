import { useTranslation } from 'react-i18next'

const NoMeterPlaceholder = () => {
  const { t } = useTranslation()
  return (
    <div className="mx-auto max-w-xl space-y-2 p-4 text-center text-gray-600">
      <p>{t('home:placeholderText.line1')}</p>
      <p>{t('home:placeholderText.line2')}</p>
      <p>
        {t('home:placeholderText.line3')}
        <a
          href="https://portal.zohm.no/Enroll"
          className="font-bold text-lime hover:underline"
        >
          {t('home:placeholderText.linkText')}
        </a>
        .
      </p>
    </div>
  )
}

export default NoMeterPlaceholder
