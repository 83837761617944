import { Invoice } from 'src/components/InvoiceComponent/types'

import {
  MeteringPointConsumptionCost,
  MeteringPointDetails,
  MeteringPointOverview,
  Object,
  MeteringPointBudgetOverview,
  MeteringPointCustomer,
  MeteringPointWeatherStation,
  Customer,
  CustomerOverview,
  CustomerMeteringPointOverview,
  CustomerMeteringPoint,
  MeteringPointGridCost,
  MeteringPointPeak,
  ETChart,
  MeteringPointPowerFactor,
  SolverUser,
  MeteringPointPowerAgreementCost,
} from 'src/generated/client'

enum HTTP_Method {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

class APIClient {
  _request = (
    url: string,
    method: HTTP_Method,
    body?: any,
    checkAuth: boolean = true
  ) => {
    let headers = undefined
    if (body != null) {
      headers = {
        'Content-Type': 'application/json; charset=utf-8',
      }
    }

    return fetch(url, {
      method: method,
      headers: headers,
      body: body != null ? JSON.stringify(body) : null,
    }).then((res) => {
      if (checkAuth && res.status === 401) {
        window.location.href = '/Login'
      } else {
        if (res.status !== 500) return res.json()

        throw new Error('Something went wrong.')
      }
    })
  }

  //  --- Objects ---
  getObjects = (): Promise<Object[]> => {
    return this._request('/objects', HTTP_Method.GET)
  }

  setObjectName = (objectId: any, name: any): Promise<any> => {
    const cleanName = encodeURIComponent(name)
    return this._request(
      `/api/object/rename/${objectId}/${cleanName}`,
      HTTP_Method.PUT
    )
  }

  //  --- Users ---
  getAccount = (): Promise<any> => {
    return this._request('/Account', HTTP_Method.GET)
  }

  getSessionAlive = (): Promise<any> => {
    return fetch('/api/session/alive', { method: HTTP_Method.GET }).then(
      (res) => {
        if (res.status !== 200) {
          throw new Error('')
        }
      }
    )
  }

  getMe = (): Promise<SolverUser> => {
    return this._request('/api/users/me', HTTP_Method.GET)
  }

  //  --- Meters
  getMeter = (meterId: any): Promise<MeteringPointOverview> => {
    return this._request(`/api/object/${meterId}`, HTTP_Method.GET)
  }
  getUserSettings = (): Promise<any> => {
    return this._request(`/home/UserSettings`, HTTP_Method.GET)
  }

  getMetersOverview = (): Promise<MeteringPointOverview[]> => {
    return this._request('/home/JSONObjectMetersOverview', HTTP_Method.GET)
  }

  getWindProduction = (areaId: any): Promise<any> => {
    return this._request(
      '/home/JSONObjectDailyWindProduction?balanceArea=' + areaId,
      HTTP_Method.GET
    )
  }

  getGridCost = (
    objectId: string,
    start: string,
    end: string,
    aggregationType?: string
  ): Promise<MeteringPointGridCost[]> => {
    return this._request(
      `/home/JSONObjectMeteringPointGridCost?objectId=${objectId}&from=${start}&to=${end}&aggregationType=${aggregationType}`,
      HTTP_Method.GET
    )
  }

  getPowerAgreementCost = (
    objectId: string,
    start: string,
    end: string,
    aggregationType?: string
  ): Promise<MeteringPointPowerAgreementCost[]> => {
    return this._request(
      `/home/JSONObjectMeteringPointPowerAgreementCost?objectId=${objectId}&from=${start}&to=${end}&aggregationType=${aggregationType}`,
      HTTP_Method.GET
    )
  }
  getCustomerPowerAgreementCost = (
    customerId: string,
    start: string,
    end: string,
    aggregationType?: string
  ): Promise<MeteringPointPowerAgreementCost[]> => {
    return this._request(
      `/home/JSONObjectCustomerPowerAgreementCost?customerId=${customerId}&from=${start}&to=${end}&aggregationType=${aggregationType}`,
      HTTP_Method.GET
    )
  }

  getConsumption = (
    objectId: string,
    start: string,
    end: string,
    freq: string
  ): Promise<MeteringPointConsumptionCost[]> => {
    return this._request(
      `/home/JSONObjectMonthlyCost?objectId=${objectId}&from=${start}&to=${end}&aggregationType=${freq}`,
      HTTP_Method.GET
    )
  }

  getMeteringPointSpotPrice = (
    objectId: string,
    start: string,
    end: string,
    freq: string
  ): Promise<any> => {
    return this._request(
      `/home/JSONObjectMeteringPointSpotPrice?objectId=${objectId}&from=${start}&to=${end}&aggregationType=${freq}`,
      HTTP_Method.GET
    )
  }

  getMeteringPointBudget(
    objectId: string,
    start: string,
    end: string
  ): Promise<MeteringPointBudgetOverview[]> {
    return this._request(
      `/home/JSONObjectMeteringPointBudget?objectId=${objectId}&from=${start}&to=${end}`,
      HTTP_Method.GET
    )
  }

  getMeteringPointETChart = (
    objectId: string,
    start: string,
    end: string,
    freq: string,
    felt: boolean
  ): Promise<ETChart[]> => {
    return this._request(
      `/home/JSONObjectMeteringPointETChart?objectId=${objectId}&from=${start}&to=${end}&aggregationType=${freq}&feltTemperature=${felt}`,
      HTTP_Method.GET
    )
  }

  getMeteringPointPowerFactor = (
    objectId: string,
    start: string,
    end: string,
    freq: string
  ): Promise<MeteringPointPowerFactor[]> => {
    return this._request(
      `/home/JSONObjectMeteringPointPowerFactor?objectId=${objectId}&from=${start}&to=${end}&aggregationType=${freq}`,
      HTTP_Method.GET
    )
  }

  getMeteringPointPeak = (
    objectId: string,
    start: string,
    end: string,
    aggregationType: string
  ): Promise<MeteringPointPeak[]> => {
    return this._request(
      `/home/JSONObjectMeteringPointPeak?objectId=${objectId}&from=${start}&to=${end}&aggregationType=${aggregationType}`,
      HTTP_Method.GET
    )
  }

  getPlant = (objectId: any): Promise<MeteringPointDetails> => {
    return this._request('/api/meter/' + objectId, HTTP_Method.GET)
  }

  getPlantSummary = (objectId: any): Promise<MeteringPointDetails> => {
    return this._request(`/api/meter/${objectId}/summary`, HTTP_Method.GET)
  }

  syncPlant = (objectId: any): Promise<{ actionStatus: string }> => {
    return this._request(`/api/meter/${objectId}/plant/sync`, HTTP_Method.GET)
  }

  saveInvoice = (objectId: any, invoice: any): Promise<any> => {
    return this._request(
      `/api/object/${objectId}/invoice`,
      HTTP_Method.POST,
      invoice
    )
  }

  deleteInvoice = (objectId: any, userSharedInvoiceId: any): Promise<any> => {
    return this._request(
      `/api/object/${objectId}/invoice?userSharedInvoiceId=${userSharedInvoiceId}`,
      HTTP_Method.DELETE
    )
  }

  getInvoices = (objectId: any): Promise<Invoice[]> => {
    return this._request(`/api/object/${objectId}/invoice`, HTTP_Method.GET)
  }

  //  --- Customers
  getCustomer = (customerId: any): Promise<Customer> => {
    return this._request(
      `/home/JSONObjectCustomer?customerId=${customerId}`,
      HTTP_Method.GET
    )
  }

  getCustomerMeteringPointOverview(
    customerId: any
  ): Promise<CustomerMeteringPointOverview[]> {
    return this._request(
      `/home/JSONObjectCustomerMeteringPointOverview?customerId=${customerId}`,
      HTTP_Method.GET
    )
  }

  getCustomerMeteringPoint(): Promise<CustomerMeteringPoint[]> {
    return this._request(
      `/home/JSONObjectCustomerMeteringPoint`,
      HTTP_Method.GET
    )
  }

  getCustomerOverview = (): Promise<CustomerOverview[]> => {
    return this._request(`/home/JSONObjectCustomerOverview`, HTTP_Method.GET)
  }

  getMeterCustomer = (objectId: any): Promise<MeteringPointCustomer> => {
    return this._request('/api/meter/customer/' + objectId, HTTP_Method.GET)
  }

  syncCustomer = (objectId: any): Promise<{ actionStatus: string }> => {
    return this._request(
      `/api/meter/${objectId}/customer/sync`,
      HTTP_Method.GET
    )
  }
  getCustomerBudget(
    customerId: string,
    start: string,
    end: string
  ): Promise<MeteringPointBudgetOverview[]> {
    return this._request(
      `/home/JSONObjectCustomerBudget?customerId=${customerId}&from=${start}&to=${end}`,
      HTTP_Method.GET
    )
  }

  getCustomerCost = (
    customerId: string,
    start: string,
    end: string,
    freq: string
  ): Promise<MeteringPointConsumptionCost[]> => {
    return this._request(
      `/home/JSONObjectCustomerCost?customerId=${customerId}&from=${start}&to=${end}&aggregationType=${freq}`,
      HTTP_Method.GET
    )
  }

  getCustomerGridCost = (
    customerId: string,
    start: string,
    end: string,
    aggregationType?: string
  ): Promise<MeteringPointGridCost[]> => {
    return this._request(
      `/home/JSONObjectCustomerGridCost?customerId=${customerId}&from=${start}&to=${end}&aggregationType=${aggregationType}`,
      HTTP_Method.GET
    )
  }

  //  --- Prices ---
  getSystemPrice = (): Promise<any> => {
    return this._request(
      '/home/JSONObjectDailyPxPrice?balanceArea=SYS',
      HTTP_Method.GET
    )
  }

  getPoolPrice = (areaId: any): Promise<any> => {
    return this._request(
      '/home/JSONObjectDailyPxPrice?balanceArea=' + areaId,
      HTTP_Method.GET
    )
  }

  getLatestSystemPrices = (): Promise<any> => {
    return this._request(
      '/home/JSONObjectPXPriceLastDays?balanceArea=SYS',
      HTTP_Method.GET
    )
  }

  getLatestPoolPrices = (areaId: any): Promise<any> => {
    return this._request(
      '/home/JSONObjectPXPriceLastDays?balanceArea=' + areaId,
      HTTP_Method.GET
    )
  }

  // -- Weather Stations
  getWeatherStations = (
    objectId: any
  ): Promise<MeteringPointWeatherStation[]> => {
    return this._request('/api/meter/weather/' + objectId, HTTP_Method.GET)
  }
}

export default new APIClient()
