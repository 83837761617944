import { useParams } from 'react-router-dom'
import { Consumption } from 'src/components/Consumption'

const ConsumptionPage = () => {
  const objectId = useParams<'objectId'>().objectId as string

  return <Consumption queryType="meter" objectId={objectId} />
}

export default ConsumptionPage
