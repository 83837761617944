import { FC } from 'react'
import { useAppState } from 'src/context/appstate'
import { NettGross } from './types'

const InvoiceAttribute = ({
  value,
  ng,
  label,
  unit,
  formatter = (v) => '' + v,
}: {
  value?: number
  ng?: NettGross
  label: string
  unit?: string
  formatter?: (v: number) => string
}) => {
  const { showNett } = useAppState()

  return (
    <div className="flex flex-col space-y-1">
      <AttributeLabel>{label}</AttributeLabel>
      <div className="flex items-baseline space-x-2">
        <span className="text-xl font-semibold text-gray-900">
          {formatter(ng ? (showNett ? ng.nett : ng.gross) : value || 0)}
        </span>
        {unit && (
          <span className="text-sm font-medium text-gray-700">{unit}</span>
        )}
      </div>
    </div>
  )
}

export const AttributeLabel: FC = ({ children }) => (
  <span className="whitespace-nowrap text-xs font-medium uppercase text-gray-600">
    {children}
  </span>
)

export const IndependentAttributeLabel: FC = ({ children }) => (
  <div className="w-36">
    <AttributeLabel>{children}</AttributeLabel>
  </div>
)

export default InvoiceAttribute
