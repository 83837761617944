import { useQuery } from '@tanstack/react-query'
import apiClient from 'src/utils/apiClient'
import { Invoice } from './types'

const useInvoiceData = (objectId: string) => {
  const {
    isFetching,
    data: invoices,
    refetch,
    isLoading,
    error,
  } = useQuery<Invoice[], Error>(
    [objectId, 'invoices'],
    async () => await apiClient.getInvoices(objectId)
  )

  return { isFetching, invoices, refetch, isLoading, error }
}

export default useInvoiceData
