import React, { FC } from 'react'
import { LocationMarkerIcon, LightBulbIcon } from '@heroicons/react/outline'
import { Popup, Tooltip } from 'react-leaflet'

export const MeterPopup: FC = ({ children }) => (
  <Popup>
    <div className="flex flex-col items-center space-y-2">
      <LightBulbIcon className="w-6 text-gray-700" />
      <span className="text-sm font-medium">{children}</span>
    </div>
  </Popup>
)

export const StationPopup: FC = ({ children }) => (
  <Popup>
    <div className="flex flex-col items-center space-y-2">
      <LocationMarkerIcon className="w-6 text-gray-700" />
      <span className="text-sm font-medium">{children}</span>
    </div>
  </Popup>
)

export const Permatip = ({
  children,
  Icon,
  lime,
}: {
  children: string
  Icon: any
  lime?: boolean
}) => (
  <Tooltip direction="right" offset={[0, 0]} opacity={1} permanent>
    <div className="flex items-center space-x-1">
      <Icon className="w-4 text-gray-700" />
      {lime ? (
        <span className="lime text-sm font-normal">{children}</span>
      ) : (
        <span className="text-sm font-normal">{children}</span>
      )}
    </div>
  </Tooltip>
)
