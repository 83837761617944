import { CashIcon } from '@heroicons/react/solid'
import { useTranslation } from 'react-i18next'
import { useAppState } from 'src/context/appstate'
import { PrimaryButton } from 'src/components/action'

const NettToggleComponent = () => {
  const { showNett, toggleShowNett } = useAppState()
  const { t } = useTranslation()

  return (
    <PrimaryButton onClick={() => toggleShowNett()} Icon={CashIcon}>
      <span className="font-semibold">
        {showNett ? t('home:nettPrice') : t('home:grossPrice')}
      </span>
    </PrimaryButton>
  )
}

export default NettToggleComponent
