import noDataSymbol from '../../images/search_data.png'

interface IGraphPlaceHolderComponent {
  dataType: string
}

export function GraphPlaceholder({ dataType }: IGraphPlaceHolderComponent) {
  return (
    <div className={`mx-auto bg-white p-4 md:py-16`}>
      <div className="flex w-full flex-col items-center">
        <img className="h-24" src={noDataSymbol} alt="NoData" />
        <p className="mt-2 text-lg font-bold">No Data</p>
        <p className="mt-2"> No {dataType} data for this period.</p>
      </div>
    </div>
  )
}
