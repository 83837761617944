import { useParams } from 'react-router-dom'
import BudgetComponent from 'src/components/BudgetComponent'

const BudgetPage = () => {
  const objectId = useParams<'objectId'>().objectId as string

  return <BudgetComponent objectId={objectId} />
}

export default BudgetPage
