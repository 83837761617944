import { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import {
  Bar,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  BarChart,
  Label,
  TooltipProps,
} from 'recharts'
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'
import { Dropdown } from 'src/components/Dropdown'
import { MeteringPointConsumptionCost } from 'src/generated/client'
import { FreqType } from 'src/types'
import { xAxisTickFormatter } from './Graphs'
import { DataPeriod, TrendLine } from '../ETComponent'

export const round = (value: any, decimals: number) =>
  Math.round(value * 10 ** decimals) / 10 ** decimals

export const DeviationsGraph = ({
  data,
  freq,
  periodParent,
  trendLine,
}: {
  data: MeteringPointConsumptionCost[]

  freq: FreqType
  periodParent?: DataPeriod
  trendLine?: TrendLine
  onGraphClick: (v: string) => void
}) => {
  const { t } = useTranslation()

  const yLabelDeviations: string = t(`deviations:deviationyaxis`)

  const scrappedData = data.filter(function (element) {
    return element !== undefined
  })

  const [deviationPeriod, setDeviationPeriod] =
    useState<DataPeriod>('lastMonth')

  const deviationData = useMemo(() => {
    if (freq === 'month') {
      return {
        lastMonth: scrappedData.slice(scrappedData?.length - 1),
        last3Months: scrappedData.slice(scrappedData?.length - 3),
        last6Months: scrappedData.slice(scrappedData?.length - 6),
        lastYear: scrappedData.slice(scrappedData?.length - 12),
        allData: scrappedData.slice(1),
      }
    } else if (freq === 'week') {
      return {
        lastMonth: scrappedData.slice(scrappedData?.length - 4),
        last3Months: scrappedData.slice(scrappedData?.length - 12),
        last6Months: scrappedData.slice(scrappedData?.length - 24),
        lastYear: scrappedData.slice(scrappedData?.length - 52),
        allData: scrappedData.slice(1),
      }
    } else if (freq === 'day') {
      return {
        lastMonth: scrappedData.slice(scrappedData?.length - 30),
        last3Months: scrappedData.slice(scrappedData?.length - 90),
        last6Months: scrappedData.slice(scrappedData?.length - 180),
        lastYear: scrappedData.slice(scrappedData?.length - 365),
        allData: scrappedData.slice(1),
      }
    } else {
      return null
    }
  }, [scrappedData, freq])

  const [deviationScrappedData, setDeviationScrappedData] = useState<
    MeteringPointConsumptionCost[]
  >(deviationData?.allData || [])

  useEffect(() => {
    let periodData: MeteringPointConsumptionCost[] | undefined

    switch (deviationPeriod) {
      case 'lastMonth':
        periodData = deviationData?.lastMonth
        break
      case 'lastThreeMonths':
        periodData = deviationData?.last3Months
        break
      case 'lastSixMonths':
        periodData = deviationData?.last6Months
        break
      case 'lastYear':
        periodData = deviationData?.last6Months
        break
      default:
        periodData = deviationData?.allData
        break
    }
    setDeviationScrappedData(periodData || [])
  }, [deviationPeriod, periodParent, freq]) //deviationData])

  const DeviationPeriodOptions = useMemo(() => {
    if (periodParent === 'allData') {
      return [
        {
          value: 'lastMonth',
          label: t(`global:lastMonth`),
        },
        {
          value: 'lastThreeMonths',
          label: t(`global:lastThreeMonths`),
        },
        {
          value: 'lastSixMonths',
          label: t(`global:lastSixMonths`),
        },
        {
          value: 'lastyear',
          label: t(`global:lastYear`),
        },
        {
          value: 'allData',
          label: t(`global:allData`),
        },
      ] as { value: DataPeriod; label: string }[]
    } else if (periodParent === 'lastYear') {
      return [
        {
          value: 'lastMonth',
          label: t(`global:lastMonth`),
        },
        {
          value: 'lastThreeMonths',
          label: t(`global:lastThreeMonths`),
        },
        {
          value: 'lastSixMonths',
          label: t(`global:lastSixMonths`),
        },
        {
          value: 'lastyear',
          label: t(`global:lastYear`),
        },
        {
          value: 'allData',
          label: t(`global:allData`),
        },
      ] as { value: DataPeriod; label: string }[]
    } else if (periodParent === 'lastSixMonths') {
      return [
        {
          value: 'lastMonth',
          label: t(`global:lastMonth`),
        },
        {
          value: 'lastThreeMonths',
          label: t(`global:lastThreeMonths`),
        },
        {
          value: 'lastSixMonths',
          label: t(`global:lastSixMonths`),
        },
        {
          value: 'allData',
          label: t(`global:allData`),
        },
      ] as { value: DataPeriod; label: string }[]
    } else if (periodParent === 'lastThreeMonths') {
      return [
        {
          value: 'lastMonth',
          label: t(`global:lastMonth`),
        },
        {
          value: 'lastThreeMonths',
          label: t(`global:lastThreeMonths`),
        },
        {
          value: 'allData',
          label: t(`global:allData`),
        },
      ] as { value: DataPeriod; label: string }[]
    } else if (periodParent === 'lastMonth') {
      return [
        {
          value: 'lastMonth',
          label: t(`global:lastMonth`),
        },
        {
          value: 'allData',
          label: t(`global:allData`),
        },
      ] as { value: DataPeriod; label: string }[]
    } else {
      return []
    }
  }, [t, periodParent])

  return (
    <div className="mx-auto flex flex-col  md:w-3/4 md:max-w-6xl ">
      <div className="w-full">
        <ResponsiveContainer width="100%" aspect={2.5}>
          <BarChart
            data={deviationScrappedData}
            margin={{
              top: 5,
              right: 50,
              left: 50,
              bottom: 30,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="timestamp"
              tickFormatter={(value) => xAxisTickFormatter(value, freq, t)}
            >
              {/* <Label value={'Date'} offset={10} position="bottom" /> */}
            </XAxis>
            <YAxis>
              <Label
                value={yLabelDeviations}
                offset={-104}
                angle={270}
                position="right"
                style={{ textAnchor: 'middle' }}
              />
            </YAxis>
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              content={<DeviationTooltip freq={freq} />}
              wrapperStyle={{ outline: 'none' }}
            />
            {freq === 'month' && <Bar dataKey="deviation" fill="#53c023" />}
            {freq === 'week' && <Bar dataKey="deviation" fill="#53c023" />}

            {freq === 'day' && trendLine === 'trend' && (
              <Bar dataKey="deviation" fill="#53c023" />
            )}
            {freq === 'day' && trendLine === 'trendWeekday' && (
              <Bar dataKey="deviationWeekday" fill="#53c023" />
            )}
            {freq === 'day' && trendLine === 'trendWeekend' && (
              <Bar dataKey="deviationWeekend" fill="#53c023" />
            )}
          </BarChart>
        </ResponsiveContainer>
        {/* <CustomLegend /> */}
      </div>
      <div className="my-4 flex justify-center space-x-2">
        <Dropdown
          className="w-40"
          onChange={(value: string) => {
            setDeviationPeriod(value as DataPeriod)
          }}
          value={deviationPeriod}
          options={DeviationPeriodOptions}
        />
      </div>
    </div>
  )
}

export default DeviationsGraph

// const CustomLegend = () => {
//   const { t } = useTranslation()

//   return (
//     <div className="mt-4 flex flex-row justify-center space-x-8">
//       <div className="flex flex-row items-center">
//         <div className="mr-2 h-3 w-3 rounded-full bg-graphSeries" />
//         <p> {t(`deviations:deviationWeekday`)}</p>
//       </div>
//       <div className="flex flex-row items-center">
//         <div className="mr-2 h-3 w-3 rounded-full bg-black" />
//         <p> {t(`deviations:deviationWeekend`)}</p>
//       </div>
//     </div>
//   )
// }

const DeviationTooltip = ({
  active,
  payload,
  freq,
}: TooltipProps<ValueType, NameType> & { freq: FreqType }) => {
  const { t } = useTranslation()

  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip rounded-md border border-gray-400 bg-white p-4 text-sm text-gray-800">
        {freq === 'year' ? (
          <p>{dayjs(payload[0].payload.timestamp).format('YYYY')}</p>
        ) : freq === 'month' ? (
          <p>
            {`${t(
              `global:shortMonths.${dayjs(payload[0].payload.timestamp)
                .format('MMM')
                .toLowerCase()}`
            )} ${dayjs(payload[0].payload.timestamp).format('YYYY')}`}
          </p>
        ) : freq === 'day' ? (
          <p>{dayjs(payload[0].payload.timestamp).format('DD.MM.YYYY')}</p>
        ) : freq === 'week' ? (
          <p>
            {t('global:week')} {dayjs(payload[0]?.payload?.timestamp).isoWeek()}{' '}
            {dayjs(payload[0]?.payload?.timestamp).format('YYYY')}
          </p>
        ) : null}

        <hr className="mt-1" />
        <div className="mt-1">
          {payload.map(
            (entry, index) =>
              entry.value !== 0 && (
                <p key={index} className="">
                  {t(`deviations:${entry.name}`)}:{' '}
                  {round(entry.value, 2).toLocaleString('NO')} kWh
                </p>
              )
          )}
        </div>
      </div>
    )
  }
  return null
}
