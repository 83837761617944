import { useSearchParams } from 'react-router-dom'
import { DataPeriod } from 'src/components/ETComponent'
import { FreqType } from 'src/types'

interface IUseDateRange {
  defaultFreq: string
  defaultFrom: string
  defaultTo: string
  defaultPeriod?: DataPeriod
}

export default function useDateRange({
  defaultFreq,
  defaultFrom,
  defaultTo,
  defaultPeriod,
}: IUseDateRange) {
  const [searchParams, setSearchParams] = useSearchParams()
  const freq = (searchParams.get('freq') as FreqType) || defaultFreq

  const from = searchParams.get('from') || defaultFrom

  const to = searchParams.get('to') || defaultTo

  const period: DataPeriod =
    (searchParams.get('period') as DataPeriod) || defaultPeriod || 'lastYear'

  const setDateRange = ({
    freq,
    from,
    to,
    period,
  }: {
    freq: FreqType
    from: string
    to: string
    period?: DataPeriod
  }) => {
    if (period) {
      setSearchParams({ freq, from, to, period })
    } else {
      setSearchParams({ freq, from, to })
    }
  }

  return {
    freq,
    from,
    to,
    period,
    setDateRange,
  }
}
