import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { ArrowLeftIcon } from '@heroicons/react/outline'
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom'
import { TextPlaceholder } from 'src/components/TextPlaceholder'
import { Tabs } from 'src/components/Tabs'
import APIClient from 'src/utils/apiClient'
import Spinner from 'src/components/Spinner'
import { CustomerContext } from 'src/context/customer'

const CustomerFrame = () => {
  const { t } = useTranslation()
  const customerId = useParams<'customerId'>().customerId
  const navigate = useNavigate()

  // TODO: Change this query to call customer
  const {
    data: customer,
    isLoading,
    error,
  } = useQuery(['customer', customerId], () =>
    APIClient.getCustomer(customerId)
  )

  const tabs = [
    { label: t('objects:sumCompany'), url: `/SumCompany` },
    { label: t('objects:sumBudget'), url: `/SumBudget` },
    { label: t('objects:meters'), url: `/Meters` },
  ]

  const goBack = () => navigate('/App/Customers')

  // Redirect back to /App if customerId is not a number
  if (customerId && isNaN(parseInt(customerId))) {
    return <Navigate to="/App" replace />
  }

  if (error) {
    console.error(error)
    return <p>Error. Check console.</p>
  }

  return (
    <CustomerContext.Provider value={customer}>
      <div className="w-full lg:mx-10 xl:mx-16 2xl:mx-20">
        <div className="flex flex-row items-center text-lg text-gray-800 ">
          <div className="flex flex-col justify-center">
            <div
              className="flex cursor-pointer flex-col justify-center px-4 py-2 hover:text-gray-400"
              onClick={goBack}
            >
              <ArrowLeftIcon className="h-6" />
            </div>
          </div>
          {/* <div className="text-base" onClick={() => navigate('/App/Customers')}>
            {t('home:backHome')}
            
            Back to customers
          </div> */}
          <div className="mt-4 ml-8 text-3xl text-gray-800">
            {customer ? (
              <>
                {customer.name}
                {customer.partyId && (
                  <span className="ml-2 text-xl text-gray-600">
                    ({customer.partyId})
                  </span>
                )}
                <p className="text-base text-gray-600">
                  {t('objects:customerView.customerId')}: {customer.customerId}
                </p>
              </>
            ) : (
              <TextPlaceholder
                className="rounded-full bg-gray-200"
                min={10}
                max={32}
              />
            )}
          </div>
        </div>

        {isLoading ? (
          <div className="flex h-96 w-full items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="flex w-full flex-wrap px-5 pb-8">
            <div className="w-full">
              <Tabs tabs={tabs} prefix={`/App/Customers/${customerId}`} />
              <div className="relative break-words rounded-xl rounded-tl-none bg-white text-left md:mt-0 md:shadow-lg">
                <div className="tab-content tab-space flex-auto px-4 py-4">
                  {isLoading && (
                    <div className="flex h-screen w-screen items-center justify-center">
                      <Spinner />
                    </div>
                  )}
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </CustomerContext.Provider>
  )
}

export default CustomerFrame
